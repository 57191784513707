import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from '@material-ui/core';
import * as Colors from '@material-ui/core/colors';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { t } from '../../i18n';
import HandleKinds from '../../constants/HandleKinds';

interface Props {
  onClose: () => void;
  handle_kind: HandleKinds;
  open: boolean;
  message: string;
}

const Message = styled(({ children, ...props }) => (
  <span {...props}>{children}</span>
))(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  fontSize: '16px',
  fontFamily: 'Roboto, sans-serif',
  color: theme.palette.text.secondary,
}));

class ErrorDialog extends React.Component<Props, {}> {
  static _theme = (outerTheme) =>
    createTheme(
      {
        overrides: {
          MuiButton: {
            textPrimary: { color: Colors.cyan['500'] },
            textSecondary: { color: Colors.pink.A200 },
          },
        },
      },
      outerTheme,
    );

  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  render() {
    const buttonStyle: React.CSSProperties = { minWidth: '88px' };
    const buttonLabelStyle: React.CSSProperties = {
      fontSize: '14px',
      position: 'relative',
      top: '1px',
    };
    const notifyActions = [
      <Button
        children={<span style={buttonLabelStyle}>{t('view:projects.ok')}</span>}
        color="primary"
        key="ok"
        onClick={this.props.onClose}
        style={buttonStyle}
      />,
    ];
    const reloadActions = [
      <Button
        children={
          <span style={buttonLabelStyle}>{t('view:projects.cancel')}</span>
        }
        color="secondary"
        key="cancel"
        onClick={this.props.onClose}
        tabIndex={-1}
        style={buttonStyle}
      />,
      <Button
        children={
          <span style={buttonLabelStyle}>{t('view:projects.reload')}</span>
        }
        color="primary"
        key="create"
        onClick={() => {
          location.reload();
        }}
        style={buttonStyle}
      />,
    ];
    const result = (
      <Dialog
        onClose={this.props.onClose}
        open={this.props.open}
        PaperProps={{ style: { width: '75%', maxWidth: '576px' } }}
        onKeyDown={(e) => this._onKeyDown(e)}
      >
        <DialogTitle
          key="title"
          disableTypography
          children={
            <h3
              style={{
                margin: 'unset',
                fontSize: '22px',
                fontWeight: 400,
                lineHeight: '32px',
              }}
            >
              {t('view:api.common.error')}
            </h3>
          }
        />
        <DialogContent key="content">
          <Message>{this.props.message}</Message>
        </DialogContent>
        <DialogActions key="actions" style={{ margin: '8px' }}>
          {this.props.handle_kind === HandleKinds.notify
            ? notifyActions
            : /* (this.props.handle_kind === HandleKinds.reloading) ? */ reloadActions}
        </DialogActions>
      </Dialog>
    );
    return (
      <MuiThemeProvider theme={ErrorDialog._theme}>{result}</MuiThemeProvider>
    );
  }

  _onKeyDown(e) {
    // when 'Enter' key is input, close Error Dialog.
    if (e.key === 'Enter' && e.keyCode === 13) {
      e.stopPropagation();
      this.props.onClose();
    }
  }
}

export default ErrorDialog;
