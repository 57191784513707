// Based on @lezer/html https://github.com/lezer-parser/html/blob/1.3.6/src/tokens.js
// and modified for stex language.

import { ContextTracker, ExternalTokenizer, InputStream } from '@lezer/lr';
import {
  blockOptionName,
  commentBlockOptionName,
  CommentContent as cmntContent,
  CommentTagEnd,
  Content,
  mismatchedBlockOptionName,
  MismatchedTagEnd,
  TagBegin,
  TagEnd,
} from './parser.terms';

function charMatch(code: number, pattern: string) {
  for (const ch of pattern) {
    if (code === ch.charCodeAt(0)) {
      return true;
    }
  }
  return false;
}

// Similar to Content { ![(){}\[\]$%\\]+ } in grammar but not including blank line
export const content = new ExternalTokenizer(
  (input, stack) => {
    const parent = stack.context ? stack.context.name : null;
    if (parent === 'comment') return;
    const newLine = '\n';
    const not = '(){}[]$%\\';
    const space = ' \t';
    for (let i = 0; ; i += 1) {
      if (input.next < 0 || charMatch(input.next, not)) {
        if (i) input.acceptToken(Content);
        return;
      }
      if (charMatch(input.next, newLine)) {
        if (!i) return; // skip newline at first
        let offset = 1;
        for (;;) {
          const next = input.peek(offset);
          if (charMatch(next, space)) {
            offset += 1;
          } else if (charMatch(next, newLine)) {
            if (i) input.acceptToken(Content);
            return;
          } else {
            break;
          }
        }
      }
      input.advance();
    }
  },
  { contextual: true },
);

export const CommentContent = new ExternalTokenizer(
  (input, stack) => {
    const parent = stack.context ? stack.context.name : null;
    if (parent !== 'comment') return;
    const endComment = '\\end{comment}';
    let matchedLen = 0;
    for (let i = 0; ; i += 1) {
      if (input.next < 0) {
        if (i) input.acceptToken(cmntContent);
        break;
      }
      if (input.next === endComment.charCodeAt(matchedLen)) {
        matchedLen += 1;
      } else {
        matchedLen = 0;
      }
      if (matchedLen >= endComment.length) {
        if (i > endComment.length) {
          input.acceptToken(cmntContent, -(matchedLen - 1));
        }
        break;
      }
      input.advance();
    }
  },
  { contextual: true },
);

class BlockContext {
  name: string | null;

  parent: BlockContext | null;

  hash: number;

  constructor(name: string | null, parent: BlockContext | null) {
    this.name = name;
    this.parent = parent;
    this.hash = parent ? parent.hash : 0;
    if (name) {
      for (let i = 0; i < name.length; i += 1)
        this.hash +=
          // eslint-disable-next-line no-bitwise
          (this.hash << 4) + name.charCodeAt(i) + (name.charCodeAt(i) << 8);
    }
  }
}

function nameChar(ch: number) {
  return (
    ch === 45 ||
    ch === 46 ||
    ch === 58 ||
    (ch >= 65 && ch <= 90) ||
    ch === 95 ||
    (ch >= 97 && ch <= 122) ||
    ch >= 161 ||
    ch === 42
  );
}

let cachedName: string | null = null;
let cachedInput: InputStream | null = null;
let cachedPos: number = 0;
function nameAfter(input: InputStream, offset: number) {
  const pos = input.pos + offset;
  if (cachedPos === pos && cachedInput === input) return cachedName;
  let next = input.peek(offset);
  let name = '';
  for (;;) {
    if (!nameChar(next)) break;
    name += String.fromCharCode(next);
    offset += 1;
    next = input.peek(offset);
  }
  cachedInput = input;
  cachedPos = pos;
  return (cachedName = name ? name.toLowerCase() : null);
}

export const blockContext = new ContextTracker<BlockContext | null>({
  start: null,
  shift(context: BlockContext | null, term, stack, input) {
    switch (term) {
      case blockOptionName:
      case mismatchedBlockOptionName:
      case commentBlockOptionName: {
        return new BlockContext(nameAfter(input, 0), context);
      }
      case TagBegin:
        return new BlockContext('', context);
      default:
        return context;
    }
  },
  reduce(context, term, stack, input) {
    switch (term) {
      case TagEnd:
      case CommentTagEnd:
      case MismatchedTagEnd:
        return context?.parent?.parent ?? null;
      default:
        return context;
    }
  },
  reuse(context, node, stack, input) {
    const type = node.type.id;
    return type === TagBegin ? new BlockContext('', context) : context;
  },
  hash(context) {
    return context ? context.hash : 0;
  },
  strict: false,
});

export const blockOption = new ExternalTokenizer(
  (input, stack) => {
    if (!nameChar(input.next)) {
      // End of file, close any open tags
      if (input.next < 0 && stack.context) input.acceptToken(MismatchedTagEnd);
      return;
    }
    const name = nameAfter(input, 0);
    if (!name) return;
    input.advance(name.length);

    const parent = stack.context ? stack.context.name : null;
    if (name === 'comment') return input.acceptToken(commentBlockOptionName);
    if (name === parent) return input.acceptToken(blockOptionName);
    input.acceptToken(mismatchedBlockOptionName);
  },
  { contextual: true },
);
