enum UserConstants {
  RECEIVE_USER_INFO = 'RECEIVE_USER_INFO',
  UNLINK_DROPBOX = 'UNLINK_DROPBOX',
  RECEIVE_USER_USAGE = 'RECEIVE_USER_USAGE',
  RECEIVE_EXTENSION_TOKEN = 'RECEIVE_EXTENSION_TOKEN',
  FAILED_GENERATE_EXTENSION_TOKEN = 'FAILED_GENERATE_EXTENSION_TOKEN',
  DELETED_EXTENSION_TOKEN = 'DELETED_EXTENSION_TOKEN',
  FAILED_DELETE_EXTENSION_TOKEN = 'FAILED_DELETE_EXTENSION_TOKEN',
}

export default UserConstants;
