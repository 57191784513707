import * as React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { t } from '../../../i18n';

interface Props {
  open: boolean;
  onClose: () => void;
}

class LatexDevModeDialog extends React.Component<Props, {}> {
  constructor(props) {
    super(props);
  }

  private onCloseDialog = () => {
    this.props.onClose();
  };

  render() {
    const titleStyle: React.CSSProperties = {
      padding: '10px 20px',
      textAlign: 'center',
    };
    const attentionStyle: React.CSSProperties = {
      margin: '10px 10px',
      whiteSpace: 'pre-wrap',
    };

    return (
      <Dialog open={this.props.open} onClose={this.onCloseDialog}>
        <DialogTitle key="title" disableTypography style={titleStyle}>
          <h3>{t('view:editor:latex_dev_mode.title')}</h3>
        </DialogTitle>
        <DialogContent>
          <div style={attentionStyle}>
            <div>{t('view:editor:latex_dev_mode.attention')}</div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default LatexDevModeDialog;
