import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  GridList,
  GridListTile,
} from '@material-ui/core';
import * as Colors from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { t } from '../../../i18n';
import * as CL2Types from '../../../CL2Types';

interface Props {
  conflictFileDialog: boolean;
  conflictFiles: Array<CL2Types.ConflictFile>;
  open: boolean;
  title: string;
  existS3: boolean;
  project: CL2Types.EditorProject;
  existDropbox: boolean;
  s3Handler: () => void;
  dropboxHandler: () => void;
}

const ButtonEx = withStyles({
  textPrimary: { color: Colors.cyan['500'] },
  textSecondary: { color: Colors.pink.A200 },
})(Button);

class ConflictFileDialog extends React.Component<Props, {}> {
  _recentDropboxFile(a) {
    return Math.max.apply(
      null,
      a.map((elem) => new Date(elem[6]).getTime()),
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.conflictFileDialog !== nextProps.conflictFileDialog ||
      this.props.conflictFiles !== nextProps.conflictFiles
    );
  }

  render() {
    const styleButtonParent: React.CSSProperties = { textAlign: 'right' };
    const iconStyle: React.CSSProperties = {
      fontSize: '80px',
      textAlign: 'center',
      width: '100%',
    };
    const labelStyle: React.CSSProperties = {
      fontSize: '14px',
      letterSpacing: 0,
    };
    /*
          contentStyle={{
            height: '100%',
            maxHeight: 'none',
          }}
          bodyStyle={{
            height: '100%',
            maxHeight: 'none',
          }}
*/
    return (
      <Dialog
        open={this.props.open}
        PaperProps={{ style: { width: '75%', maxWidth: '576px' } }}
      >
        <DialogTitle
          disableTypography
          children={
            <h3
              style={{
                margin: '0px',
                fontSize: '22px',
                fontWeight: 400,
                lineHeight: 'calc(16.0/11.0)',
              }}
            >
              {this.props.title}
            </h3>
          }
        />
        <DialogContent>
          <div>
            <Box
              style={{
                fontSize: '16px',
                fontFamily: 'Roboto, sans-serif',
                lineHeight: 'calc(10.0/7.0)',
              }}
              color="text.secondary"
            >
              {t('view:editor.file_conflicted_message')}
            </Box>
          </div>
          {
            //          <div style={styleFileName}>
            //            {t('view:editor.file_name')}: {this.props.conflictFiles.length >= 1 && this.props.conflictFiles[0][7]}
            //          </div>
          }
          <GridList cols={2} spacing={1} cellHeight={200}>
            <GridListTile
              style={{
                height: '100%',
                maxHeight: 'none',
              }}
            >
              <Box component="h4" color="text.secondary">
                Cloud LaTeX
              </Box>
              <div>
                <span
                  style={iconStyle}
                  className={`fa fa-${this.props.existS3 ? 'file-text-o' : 'close'}`}
                />
                {
                  //                {this.props.conflictFiles.length >= 1 && t('view:editor.conflict_dialog.file_size',{ size: this.props.conflictFiles[0][2]} )} <
                }
                {/* t('view:editor.conflict_dialog.updated_at')} {this.props.conflictFiles.length >= 1 && new Date(this.props.conflictFiles[0][3]).toLocaleString() */}
                {(this.props.project ||
                  (this.props.conflictFiles.length >= 1 &&
                    this.props.conflictFiles[0][3])) &&
                  t('view:editor.conflict_dialog.updated_at')}
                {this.props.project &&
                  new Date(this.props.project.updated_at).toLocaleString()}
                {!this.props.project &&
                  this.props.conflictFiles.length >= 1 &&
                  this.props.conflictFiles[0][3] &&
                  new Date(this.props.conflictFiles[0][3]).toLocaleString()}
              </div>
              <div style={styleButtonParent}>
                <ButtonEx
                  children={
                    <span style={labelStyle}>
                      {t('view:editor.conflict_dialog.choose_cl')}
                    </span>
                  }
                  color="primary"
                  onClick={this.props.s3Handler}
                  key="s3"
                />
              </div>
            </GridListTile>
            <GridListTile>
              <Box component="h4" color="text.secondary">
                Dropbox
              </Box>
              <div>
                <span
                  style={iconStyle}
                  className={`fa fa-${this.props.existDropbox ? 'dropbox' : 'close'}`}
                />
                {
                  //              {this.props.conflictFiles.length >= 1 && t('view:editor.conflict_dialog.file_size',{ size: this.props.conflictFiles[0][5]} )} <br />
                }
                {this.props.conflictFiles.length >= 1 &&
                  t('view:editor.conflict_dialog.updated_at')}{' '}
                {this.props.conflictFiles.length >= 1 &&
                  (this._recentDropboxFile(this.props.conflictFiles)
                    ? new Date(
                        this._recentDropboxFile(this.props.conflictFiles),
                      ).toLocaleString()
                    : t('view:editor.conflict_dialog.removed'))}
              </div>
              <div style={styleButtonParent}>
                <ButtonEx
                  children={
                    <span style={labelStyle}>
                      {t('view:editor.conflict_dialog.choose_dropbox')}
                    </span>
                  }
                  color="secondary"
                  onClick={this.props.dropboxHandler}
                  key="dropbox"
                />
              </div>
            </GridListTile>
          </GridList>
        </DialogContent>
      </Dialog>
    );
  }
}

export default ConflictFileDialog;
