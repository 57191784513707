import * as React from 'react';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Paper,
  Snackbar,
} from '@material-ui/core';
import * as Colors from '@material-ui/core/colors';
import {
  MuiThemeProvider,
  createTheme,
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';
import * as _ from 'lodash';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import ProjectsActions from '../../actions/ProjectsActions';
import ProjectsContainer from './ProjectsContainer';
import ProjectsDialog from './ProjectsDialog';
import ProjectImportDialog from './ProjectImportDialog';
import { TemplatesDialog } from './TemplatesDialog';
import ErrorDialog from './ErrorDialog';
import ContextMenu from '../ContextMenu';
import UsageContainer from './UsageContainer';
import CMSContainer from './CMSContainer';
import ProjectNameDialog from './ProjectNameDialog';
import Loading from '../Loading';
import { t } from '../../i18n';
import * as CL2Types from '../../CL2Types';
import AppDispatcher from '../../dispatcher/AppDispatcher';
import ProjectsConstants from '../../constants/ProjectsConstants';
import { PromotionApp, Props as PromotionAppProps } from '../PromotionApp';
import { withRootTheme } from '../withRootTheme';

interface MenuItemType {
  key: number;
  primaryText: string;
  disabled: boolean;
  style: React.CSSProperties;
}

interface Props {
  classes: ClassNameMap;
  error: CL2Types.ProjectsError;
  user: CL2Types.ProjectUser;
  dropbox_linked: boolean;
  templatesDialog: {
    loaded: boolean;
    tags: { label: string; path: string }[];
    query: string;
  };
  importDialog: {
    open: boolean;
    loading: boolean;
    projects: CL2Types.ImportProjects;
  };
  snackbar: {
    open: boolean;
    message: string;
  };
  dropboxDisabled: boolean;
  query: string;
  elems: CL2Types.Projects;
  totalPages: number;
  currentPage: number;
  sortKey: string;
  sortOrder: CL2Types.SortOrder;
  usage: CL2Types.UsageProps;
  loading: boolean;
  zip: {
    file?: CL2Types.UploadFile;
  };
  promotionAppProps: PromotionAppProps;
  selected: number[];
}
interface State {
  newDialog: {
    open: boolean;
  };
  renameDialog: {
    open: boolean;
  };
  deleteDialog: {
    open: boolean;
    isDeletingSingleSyncedProject: boolean;
  };
  templatesDialog: {
    open: boolean;
  };
  errorDialog: {
    open: boolean;
  };
  importDialog?: {};
  contextMenuDisabled: {
    'view:projects.rename': boolean;
    'view:projects.delete': boolean;
    'view:projects.download': boolean;
    'view:projects.download_all_output': boolean;
    'view:projects.copy': boolean;
  };
  contextMenuProps: {
    pos: { x: number; y: number };
    anchorEl: HTMLElement;
  };
  cmsInfo: {
    content: string;
  };
}

const styles = {
  stateLabelChip: {
    marginLeft: 20,
    borderColor: '#339DD1',
    color: '#339DD1',
    fontSize: 'inherit',
  },
};

class ProjectsApp extends React.Component<Props, State> {
  static _theme = createTheme({
    overrides: {
      MuiButton: {
        textPrimary: { color: Colors.cyan['500'] },
        textSecondary: { color: Colors.pink.A200 },
      },
    },
  });

  // private newDialogRef:ProjectsDialog = null;

  private renameDialogRef: ProjectNameDialog = null;

  private searchTextRef?: HTMLInputElement = null;

  constructor(props) {
    super(props);
    this.state = {
      newDialog: {
        open: false,
      },
      renameDialog: {
        open: false,
      },
      deleteDialog: {
        open: false,
        isDeletingSingleSyncedProject: false,
      },
      templatesDialog: {
        open: false,
      },
      errorDialog: {
        open: false,
      },
      contextMenuDisabled: {
        'view:projects.rename': false,
        'view:projects.delete': false,
        'view:projects.download': false,
        'view:projects.download_all_output': false,
        'view:projects.copy': false,
      },
      contextMenuProps: {
        pos: null,
        anchorEl: null,
      },
      cmsInfo: {
        content: '',
      },
    };
    this._handleQuerySubmit = this._handleQuerySubmit.bind(this);
    this._handleQueryChange = this._handleQueryChange.bind(this);
    this._handleQueryClear = this._handleQueryClear.bind(this);
  }

  componentDidMount() {
    ProjectsActions.loadProjects();
    const cms_container = document.getElementById('cms_container');
    if (cms_container) {
      this.state.cmsInfo.content = cms_container.innerHTML;
      cms_container.parentNode.removeChild(cms_container);
    }
  }

  private _contextMenuHandler = (e, item) => {
    switch (item.key) {
      case 0:
        this.setState({
          renameDialog: { open: true },
        });
        break;
      case 1:
        ProjectsActions.copyProject(this.props.selected[0]).then(() => {
          ProjectsActions.loadProjects();
          ProjectsActions.clearSelected();
        });
        break;
      case 2:
        if (this.props.selected.length === 1) {
          const id = this.props.selected[0];
          window.location.assign(`projects/${id}/zip`);
        } else if (this.props.selected.length >= 2) {
          const url = new URL('projects/zip_projects', window.location.origin);
          this.props.selected.forEach((id) =>
            url.searchParams.append('project_ids[]', id.toString()),
          );
          window.location.assign(url.toString());
        }
        ProjectsActions.clearSelected();
        break;
      case 3:
        if (this.props.selected.length === 1) {
          const id = this.props.selected[0];
          window.location.assign(`projects/${id}/zip_all_output`);
        }
        ProjectsActions.clearSelected();
        break;
      case 4:
        this.setState({
          deleteDialog: { open: true, isDeletingSingleSyncedProject: false },
        });
        break;
    }
  };

  _handleQuerySubmit(e) {
    e.preventDefault();
    ProjectsActions.clearSelected();
    ProjectsActions.loadProjectsWithQuery(this.searchTextRef.value);
  }

  _handleQueryChange(e) {
    AppDispatcher.dispatch({
      actionType: ProjectsConstants.NARROW_PROJECTS,
      query: e.target.value,
    });
  }

  _handleQueryClear() {
    ProjectsActions.clearSelected();
    AppDispatcher.dispatch({
      actionType: ProjectsConstants.CLEAR_PROJECT_QUERIES,
    });
  }

  render() {
    const actionButtonStyle = { margin: 'unset', minWidth: '88px' };
    const actionButtonLabelStyle = { fontSize: '14px' };
    const deleteActions = [
      <Button
        color="secondary"
        onClick={this._onCloseDeleteDialog}
        key="cancel"
        style={actionButtonStyle}
      >
        <span style={actionButtonLabelStyle}>{t('view:projects.cancel')}</span>
      </Button>,
      <Button
        color="primary"
        onClick={this._handleDeleteUnsyncedProject}
        key="delete"
        style={actionButtonStyle}
      >
        <span style={actionButtonLabelStyle}>{t('view:projects.delete')}</span>
      </Button>,
    ];
    const deleteWithDropboxActions = [
      <Button
        color="secondary"
        onClick={this._onCloseDeleteWithDropboxDialog}
        key="cancel"
        style={actionButtonStyle}
      >
        <span style={actionButtonLabelStyle}>{t('view:projects.cancel')}</span>
      </Button>,
      <Button
        color="primary"
        onClick={this._handleUnsyncDropboxDeleteProject}
        key="deleteWithoutDropbox"
        style={actionButtonStyle}
      >
        <span style={actionButtonLabelStyle}>
          {t('view:projects.deleteOnly')}
        </span>
      </Button>,
      <Button
        color="primary"
        onClick={this._handleDeleteSyncedProject}
        key="delete"
        style={actionButtonStyle}
      >
        <span style={actionButtonLabelStyle}>
          {t('view:projects.deleteAll')}
        </span>
      </Button>,
    ];
    const buttonStyle: React.CSSProperties = {
      backgroundColor: 'transparent',
      color: 'gray',
    };
    buttonStyle.height = '36px';
    buttonStyle.fontSize = '14px';
    buttonStyle.paddingLeft = '0px';
    buttonStyle.paddingRight = '0px';

    const menuItemStyle = {
      fontSize: '15px',
      fontWeight: 300,
      padding: '0px 24px',
      minHeight: '32px',
    };
    const menuItems = [
      {
        key: 0,
        primaryText: t('view:projects.rename'),
        disabled: this.state.contextMenuDisabled['view:projects.rename'],
        style: menuItemStyle,
      },
      {
        key: 1,
        primaryText: t('view:projects.copy'),
        disabled: this.state.contextMenuDisabled['view:projects.copy'],
        style: menuItemStyle,
      },
      {
        key: 2,
        primaryText: t('view:projects.download'),
        disabled: this.state.contextMenuDisabled['view:projects.download'],
        style: menuItemStyle,
      },
      {
        key: 3,
        primaryText: t('view:projects.download_all_output'),
        disabled:
          this.state.contextMenuDisabled['view:projects.download_all_output'],
        style: menuItemStyle,
      },
      {
        key: 4,
        primaryText: t('view:projects.delete'),
        disabled: this.state.contextMenuDisabled['view:projects.delete'],
        style: _.defaults({ color: 'red' }, menuItemStyle),
      },
    ];

    const specialShadow =
      '0px 1px 6px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.12)';
    const defaultTextColor = 'rgba(0, 0, 0, 0.87)';
    const defaultFontFamily = 'Roboto, sans-serif';
    const paperStyle = {
      boxShadow: specialShadow,
      color: defaultTextColor,
      fontFamily: defaultFontFamily,
    };

    // styles for header
    const headerContainerStyle: React.CSSProperties = {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    };
    const headerLeftStyle: React.CSSProperties = { flexBasis: 'auto' };
    const headerCMSStyle: React.CSSProperties = {
      flexBasis: 'auto',
      flexGrow: 1,
    };

    const buttonLabelStyle: React.CSSProperties = {
      paddingLeft: '8px',
      paddingRight: '16px',
      position: 'relative',
      bottom: '1px',
    };

    const iconColor = 'rgba(0, 0, 0, 0.87)';
    const iconStyle: React.CSSProperties = {
      color: iconColor,
      position: 'relative',
      bottom: '3px',
      fontSize: '24px',
    };

    // styles for dropbox import button
    const dropboxButtonStyle: React.CSSProperties = {};
    Object.assign(dropboxButtonStyle, buttonStyle);
    const dropboxIconStyle: React.CSSProperties = {};
    Object.assign(dropboxIconStyle, iconStyle);
    delete dropboxIconStyle.color;
    let dropboxButtonDisabled = true;
    if (!this.props.user.features.dropbox) {
      _.assign(dropboxButtonStyle, { textDecoration: 'line-through' });
      _.assign(dropboxIconStyle, { color: 'gray' });
    } else if (!this.props.dropbox_linked) {
      _.assign(dropboxIconStyle, { color: 'gray' });
    } else {
      _.assign(dropboxIconStyle, { color: 'rgba(0, 0, 0, 0.87)' });
      dropboxButtonDisabled = false;
    }

    return (
      <div>
        <Paper style={paperStyle}>
          <div
            className="projects-header-container"
            style={headerContainerStyle}
          >
            <div className="projects-header-left" style={headerLeftStyle}>
              <h2 style={{ margin: 0, padding: 20 }}>
                {t('view:projects.my_page')}
              </h2>
              {this.props.user.state > 1 ? (
                <div className={this.props.classes.div}>
                  <Chip
                    className={this.props.classes.stateLabelChip}
                    size="small"
                    color="primary"
                    variant="outlined"
                    label={
                      this.props.user.state_label.charAt(0).toUpperCase() +
                      this.props.user.state_label.slice(1)
                    }
                  />
                </div>
              ) : null}
              {this.props.usage ? (
                <UsageContainer {...this.props.usage} user={this.props.user} />
              ) : (
                <Loading />
              )}
            </div>
            {this.props.promotionAppProps ? (
              <div className="projects-header-right" style={headerCMSStyle}>
                {this.state.cmsInfo ? (
                  <CMSContainer
                    className=""
                    style={{}}
                    {...this.state.cmsInfo}
                  />
                ) : (
                  <Loading />
                )}
                <div className="text-center">
                  <PromotionApp {...this.props.promotionAppProps} size="sm" />
                </div>
              </div>
            ) : this.state.cmsInfo ? (
              <CMSContainer
                className="projects-header-right"
                style={headerCMSStyle}
                {...this.state.cmsInfo}
              />
            ) : (
              <Loading />
            )}
          </div>
          <div>
            <Button
              onClick={this._handleNewProject}
              style={$.extend({ marginLeft: 20 }, buttonStyle)}
              className="icon_flat_button"
            >
              <Icon style={iconStyle}>add</Icon>
              <span style={buttonLabelStyle}>
                {t('view:projects.new_project')}
              </span>
            </Button>
            <Button
              onClick={this._handleLoadTemplateTags}
              style={buttonStyle}
              className="icon_flat_button"
            >
              <Icon style={iconStyle}>cloud</Icon>
              <span style={buttonLabelStyle}>
                {t('view:projects.load_template')}
              </span>
            </Button>
            <Button
              onClick={this._handleImportProject}
              disabled={dropboxButtonDisabled}
              style={dropboxButtonStyle}
              className="icon_flat_button"
            >
              <span className="fa fa-dropbox" style={dropboxIconStyle} />
              <span style={buttonLabelStyle}>{t('view:projects.import')}</span>
            </Button>
            <form
              id="search_text_form"
              className="form-inline"
              onSubmit={this._handleQuerySubmit}
            >
              <div className="form-group">
                <input
                  className="form-control"
                  ref={(searchTextRef) => {
                    this.searchTextRef = searchTextRef;
                  }}
                  value={this.props.query}
                  onChange={this._handleQueryChange}
                />
                <button className="btn btn-primary btn-sm" type="submit">
                  {t('view:projects.search')}
                </button>
                <button className="btn btn-sm" onClick={this._handleQueryClear}>
                  {t('view:projects.clear')}
                </button>
              </div>
            </form>
          </div>
          <ProjectsContainer
            ref="container"
            elems={this.props.elems}
            totalPages={this.props.totalPages}
            currentPage={this.props.currentPage}
            sortKey={this.props.sortKey}
            sortOrder={this.props.sortOrder}
            loading={this.props.loading}
            renderContextMenu={this._renderContextMenu}
            selected={this.props.selected}
          />
          <ContextMenu<MenuItemType>
            {...this.state.contextMenuProps}
            menuItems={menuItems}
            onItemSelected={this._contextMenuHandler}
            onClose={(ev, reason, callback) => {
              this.setState({
                contextMenuProps: {
                  pos: null,
                  anchorEl: null,
                },
              });
              callback && callback();
            }}
          />
        </Paper>
        <ProjectsDialog
          dialogRef={(dialog: ProjectsDialog) => {
            /* this.newDialogRef = dialog; */
          }}
          open={this.state.newDialog.open}
          onClose={this._onCloseNewDialog}
          title={t('view:projects.create_new_project')}
          zip={this.props.zip}
        />
        <MuiThemeProvider theme={ProjectsApp._theme}>
          <Dialog
            onClose={this._onCloseDeleteDialog}
            open={this.state.deleteDialog.open}
            PaperProps={{ style: { width: '75%', maxWidth: '576px' } }}
          >
            <DialogTitle
              key="title"
              disableTypography
              children={
                <h3
                  style={{
                    margin: 'unset',
                    color: 'rgba(0, 0, 0, 0.87)',
                    fontSize: '22px',
                    fontWeight: 400,
                    lineHeight: '32px',
                  }}
                >
                  {t('view:projects.delete_project')}
                </h3>
              }
            />
            <DialogContent
              style={{
                fontSize: '16px',
                fontFamily: 'Roboto, sans-serif',
                color: 'rgba(0, 0, 0, 0.6)',
              }}
            >
              {this.state.deleteDialog.isDeletingSingleSyncedProject &&
                t('view:projects.synced_with_dropbox')}
              <br />
              {t('view:projects.delete_project_confirmation')}
              <br />
              <b>{t('view:projects.delete_project_confirmation_bold')}</b>
              <br />
              <br />
              <ul>
                {this.props.selected.length > 0 &&
                this.state.deleteDialog.isDeletingSingleSyncedProject ? (
                  <li key={this.props.selected[0]}>
                    {this._findProjectsBySelected()[0].title}
                  </li>
                ) : (
                  <>
                    {this._findProjectsBySelected().map((project) => (
                      <li key={project.id}>{project.title}</li>
                    ))}
                  </>
                )}
              </ul>
            </DialogContent>
            <DialogActions style={{ marginLeft: '8px', marginRight: '8px' }}>
              {this.state.deleteDialog.isDeletingSingleSyncedProject
                ? deleteWithDropboxActions
                : deleteActions}
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>

        <ProjectNameDialog
          dialogRef={(dialog: ProjectNameDialog) => {
            this.renameDialogRef = dialog;
          }}
          open={this.state.renameDialog.open}
          nameLabel={t('view:projects.project_title')}
          waiting={false}
          onRequestExec={this._handleRenameProject}
          onClose={this._onCloseRenameDialog}
          execLabel={t('view:projects.rename')}
          title={t('view:projects.rename_project')}
          defaultValue={
            this.props.selected.length > 0
              ? this._findProjectsBySelected()[0].title
              : ''
          }
        />
        <ProjectImportDialog
          {...this.props.importDialog}
          {...this.state.importDialog}
          onClose={this._onCloseImportDialog}
        />
        <TemplatesDialog
          {...this.props.templatesDialog}
          {...this.state.templatesDialog}
          onClose={this._onCloseTemplatesDialog}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          message={
            <span style={{ fontSize: '14px' }}>
              {this.props.snackbar.message}
            </span>
          }
          autoHideDuration={3000}
          open={this.props.snackbar.open}
          onClose={ProjectsActions.closeSnackbar}
        />
        <ErrorDialog
          open={this.props.error.open}
          handle_kind={this.props.error.handle_kind}
          message={this.props.error.message}
          onClose={this._onCloseErrorDialog}
        />
      </div>
    );
  }

  private _handleNewProject = (e) => {
    e.preventDefault();
    this.setState({ newDialog: { open: true } });
  };

  private _handleLoadTemplateTags = (e) => {
    this.setState({ templatesDialog: { open: true } });
    if (!this.props.templatesDialog.loaded) {
      ProjectsActions.loadTemplateTags();
    }
  };

  private _handleRenameProject = () => {
    const id = this.props.selected[0];
    ProjectsActions.updateProject(id, {
      title: this.renameDialogRef.getProjectName(),
    }).then(() => {
      this.setState({ renameDialog: { open: false } });
      ProjectsActions.clearSelected();
      ProjectsActions.loadProjects();
    });
  };

  private _handleImportProject = () => {
    if (!this.props.dropboxDisabled) ProjectsActions.loadDropboxImportList();
  };

  private _handleDeleteSyncedProject = () => {
    if (this.props.loading) return;

    ProjectsActions.deleteProject(this.props.selected[0]).then(() => {
      ProjectsActions.deselectProject(this.props.selected[0]);
      if (this.props.selected.length === 0) {
        this.setState({
          deleteDialog: { open: false, isDeletingSingleSyncedProject: false },
        });
      } else {
        this.setState({
          deleteDialog: { open: true, isDeletingSingleSyncedProject: true },
        });
      }
      ProjectsActions.loadProjects();
    });
  };

  private _handleDeleteUnsyncedProject = () => {
    if (this.props.loading) return;

    const unsyncedProjects = this._findProjectsBySelected().filter(
      (project) => project.sync_target === '',
    );

    if (unsyncedProjects.length === 0 && this.props.selected.length > 0) {
      this.setState({
        deleteDialog: { open: true, isDeletingSingleSyncedProject: true },
      });
    } else if (unsyncedProjects.length > 0 && this.props.selected.length > 0) {
      unsyncedProjects.forEach((project) => {
        ProjectsActions.deselectProject(project.id);
      });

      const ids = unsyncedProjects.map((project) => project.id);
      ProjectsActions.deleteProjects(ids).then(() => {
        if (this.props.selected.length === 0) {
          this.setState({
            deleteDialog: { open: false, isDeletingSingleSyncedProject: false },
          });
        } else {
          this.setState({
            deleteDialog: { open: true, isDeletingSingleSyncedProject: true },
          });
        }
        ProjectsActions.loadProjects();
      });
    }
  };

  /*
    _handleDeleteAllProject() {
      if(this.refs.container.getTableState().selectedRows.length){
        const project = this.props.elems[this.refs.container.getTableState().selectedRows[0]];
        this.setState({ target: project, deleteDialog: { open: true } });
      }
    }
  */

  private _handleUnsyncDropboxDeleteProject = () => {
    if (this.props.loading) return;

    if (this.props.selected.length > 0) {
      const id = this.props.selected[0];

      ProjectsActions.unsyncProject(id).then(() => {
        ProjectsActions.deleteProject(id).then(() => {
          ProjectsActions.deselectProject(id);
          if (this.props.selected.length === 0) {
            this.setState({
              deleteDialog: {
                open: false,
                isDeletingSingleSyncedProject: false,
              },
            });
          } else {
            this.setState({
              deleteDialog: { open: true, isDeletingSingleSyncedProject: true },
            });
          }
          ProjectsActions.loadProjects();
        });
      });
    }
  };

  private _onCloseDeleteDialog = () => {
    const state = this.state.deleteDialog;
    state.open = false;
    this.setState({ deleteDialog: state });
  };

  private _onCloseDeleteWithDropboxDialog = () => {
    ProjectsActions.deselectProject(this.props.selected[0]);
    if (this.props.selected.length === 1) {
      const state = this.state.deleteDialog;
      state.open = false;
      this.setState({ deleteDialog: state });
    }
  };

  private _onCloseRenameDialog = (buttonClicked) => {
    if (
      buttonClicked ||
      (this.renameDialogRef &&
        this.renameDialogRef.getProjectName() ===
          this._findProjectsBySelected()[0].title)
    ) {
      const tmp = { ...this.state.renameDialog };
      tmp.open = false;
      this.setState({ renameDialog: tmp });
    }
  };

  private _onCloseNewDialog = (buttonClicked) => {
    // 現状、ここは buttonClicked===true の状態でしか呼ばれてないはず
    // if (buttonClicked ||
    // (this.newDialogRef.getTitleInput() === '' &&
    //  this.newDialogRef.getDescriptionInput() === '')) {

    this.setState({ newDialog: { open: false } });

    // }
  };

  private _onCloseImportDialog = () => {
    if (this.props.importDialog.loading) return;
    ProjectsActions.cancelImport();
  };

  private _onCloseTemplatesDialog = () => {
    this.setState({ templatesDialog: { open: false } });
  };

  private _onCloseErrorDialog = () => {
    ProjectsActions.closeError();
  };

  private _renderContextMenu = (project, pos) => (e) => {
    e.preventDefault();

    if (!this.props.selected.includes(project.id))
      ProjectsActions.selectProject(project.id);
    const isMultiSelected = this.props.selected.length >= 2;

    this.setState({
      contextMenuDisabled: {
        'view:projects.rename': project.has_import_error || isMultiSelected,
        'view:projects.delete': false,
        'view:projects.download': project.has_import_error,
        'view:projects.download_all_output':
          project.has_import_error || isMultiSelected,
        'view:projects.copy': isMultiSelected,
      },
    });

    this.setState({
      contextMenuProps: {
        pos: pos || { x: e.clientX, y: e.clientY },
        anchorEl: e.currentTarget,
      },
    });
  };

  private _findProjectsBySelected() {
    return this.props.selected
      .map((id) => this.props.elems.find((project) => project.id === id))
      .filter((project) => project !== undefined);
  }
}

export default withStyles(styles)(withRootTheme(ProjectsApp));
