import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { t } from 'react/i18n';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
  },
  container: {
    paddingInline: 20,
  },
});

export function EmptyPlaceholder() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {t('view:editor.empty_placeholder')}
      </div>
    </div>
  );
}
