import { Command, EditorView } from '@codemirror/view';
import { syntaxTree } from '@codemirror/language';
import { SyntaxNode } from '@lezer/common';
import {
  ChangeSpec,
  EditorSelection,
  EditorState,
  SelectionRange,
  StateCommand,
  TransactionSpec,
} from '@codemirror/state';

function findParentTag(node: SyntaxNode) {
  while (node.parent && node.name !== 'Tag') node = node.parent;
  return node;
}

function tagNameIs(name: string, node: SyntaxNode, state: EditorState) {
  const tagName = node?.getChild('TagName');
  return tagName && state.sliceDoc(tagName.from, tagName.to) === name;
}

function nodeEquals(a: SyntaxNode, b: SyntaxNode) {
  return (
    a === b ||
    (a && b && a.type === b.type && a.from === b.from && a.to === b.to)
  );
}

function optionLastPos(option: SyntaxNode) {
  if (option.lastChild.name === '⚠') {
    // incomplete option
    return option.to;
  }
  return option.lastChild.from;
}

function decorateCharacters(
  tagName: string,
  { state, dispatch }: { state: EditorState; dispatch: any },
) {
  if (state.readOnly) {
    return false;
  }
  if (
    !state.languageDataAt('name', state.selection.main.head).includes('stex')
  ) {
    return false;
  }

  const tree = syntaxTree(state);
  let changed = null;

  const tr = state.changeByRange((range) => {
    let rangeStart = range.from;
    let rangeEnd = range.to;
    let node: SyntaxNode;
    let rangeStartNode: SyntaxNode;
    let rangeEndNode: SyntaxNode;
    let rangeStartParent: SyntaxNode;
    let rangeEndParent: SyntaxNode;
    let rangeStartInsideTag = false;
    let rangeEndInsideTag = false;
    if (range.empty) {
      rangeStartNode = rangeEndNode = tree.resolveInner(rangeStart);
    } else {
      rangeStartNode = tree.resolveInner(rangeStart, 1);
      rangeEndNode = tree.resolveInner(rangeEnd, -1);
    }
    rangeStartParent = findParentTag(rangeStartNode);
    rangeEndParent = findParentTag(rangeEndNode);
    if (tagNameIs(tagName, rangeStartParent, state)) {
      const optionNode = rangeStartParent.getChild('Option');
      const optionStartPos = optionNode.from + 1;
      if (rangeStart < optionStartPos) {
        rangeStart = optionStartPos;
      }
      if (!nodeEquals(rangeStartParent, rangeEndParent)) {
        rangeStartParent = rangeStartParent.parent;
        rangeStartInsideTag = true;
      }
    } else {
      rangeStartParent = rangeStartNode.parent;
    }
    if (tagNameIs(tagName, rangeEndParent, state)) {
      const optionNode = rangeEndParent.getChild('Option');
      if (rangeEnd > optionLastPos(optionNode)) {
        rangeEnd = optionLastPos(optionNode);
      } else if (rangeEnd < rangeStart) {
        rangeEnd = rangeStart;
      }
      if (!nodeEquals(rangeStartParent, rangeEndParent)) {
        rangeEndParent = rangeEndParent.parent;
        rangeEndInsideTag = true;
      }
    } else {
      rangeEndParent = rangeEndNode.parent;
    }
    if (
      nodeEquals(rangeStartParent, rangeEndParent) ||
      nodeEquals(rangeStartParent, rangeEndNode)
    ) {
      node = rangeStartParent ?? rangeStartNode;
    } else if (nodeEquals(rangeStartNode, rangeEndParent)) {
      node = rangeStartNode;
    } else {
      return { range };
    }

    let changeBefore: ChangeSpec;
    let changeAfter: ChangeSpec;
    const changeInside: { insert: string; from: number; to: number }[] = [];

    const startTag = `${tagName}{`;

    if (tagNameIs(tagName, node, state)) {
      // bold to normal
      const optionNode = node.getChild('Option');
      const optionStartPos = optionNode.from + 1;
      let optionLastPos: number;
      if (optionNode.lastChild.name === '}') {
        optionLastPos = optionNode.lastChild.from;
      } else {
        optionLastPos = optionNode.to;
      }
      if (rangeStart === optionStartPos) {
        changeBefore = {
          insert: '',
          from: node.from,
          to: rangeStart,
        };
      } else {
        changeBefore = {
          insert: '}',
          from: rangeStart,
        };
      }
      if (rangeEnd === optionLastPos) {
        changeAfter = {
          insert: '',
          from: rangeEnd,
          to: node.to,
        };
      } else {
        changeAfter = {
          insert: startTag,
          from: rangeEnd,
        };
      }
    } else {
      // normal to bold
      if (rangeStartInsideTag) {
        changeBefore = {
          insert: '',
          from: rangeStart,
        };
      } else {
        changeBefore = {
          insert: startTag,
          from: rangeStart,
        };
      }
      if (rangeEndInsideTag) {
        changeAfter = {
          insert: '',
          from: rangeEnd,
        };
      } else {
        changeAfter = {
          insert: '}',
          from: rangeEnd,
        };
      }
      let nodeInside = node.childAfter(rangeStart);
      while (nodeInside && nodeInside.from < rangeEnd) {
        if (tagNameIs(tagName, nodeInside, state)) {
          const optionNode = nodeInside.getChild('Option');
          if (optionNode.firstChild.to > rangeStart) {
            changeInside.push({
              insert: '',
              from: nodeInside.from,
              to: optionNode.firstChild.to,
            });
          }
          if (optionLastPos(optionNode) < rangeEnd) {
            changeInside.push({
              insert: '',
              from: optionLastPos(optionNode),
              to: optionNode.to,
            });
          }
        }
        nodeInside = nodeInside.nextSibling;
      }
    }
    const adjustFrom =
      -((changeBefore.to ?? changeBefore.from) - changeBefore.from) +
      changeBefore.insert.length;
    const from = rangeStart + adjustFrom;
    const to =
      rangeEnd +
      adjustFrom -
      changeInside.reduce((sum, change) => sum + change.to - change.from, 0);
    return (changed = {
      changes: [changeBefore, changeAfter, changeInside],
      range: EditorSelection.range(
        range.anchor < range.head ? from : to,
        range.anchor < range.head ? to : from,
      ),
    });
  });
  if (changed) {
    dispatch(state.update(tr));
    return true;
  }
  return false;
}

export const insertTextbf: StateCommand = (target) =>
  decorateCharacters('\\textbf', target);
