import { EventEmitter } from 'events';
import AppDispatcher from '../dispatcher/AppDispatcher';
import TopTypes from '../TopTypes';

const CHANGE_EVENT = 'change';

const states = { signinOpen: false, signupOpen: false };

class TopStore extends EventEmitter {
  dispatchToken: string;

  constructor() {
    super();
    this.dispatchToken = AppDispatcher.register((action) => {
      switch (action.actionType) {
        case TopTypes.OPEN_SIGNIN_DIALOG:
          states.signinOpen = true;
          this.emitChange();
          break;
        case TopTypes.OPEN_SIGNUP_DIALOG:
          states.signupOpen = true;
          this.emitChange();
          break;
        case TopTypes.CLOSE_SIGNIN_DIALOG:
          states.signinOpen = false;
          this.emitChange();
          break;
        case TopTypes.CLOSE_SIGNUP_DIALOG:
          states.signupOpen = false;
          this.emitChange();
          break;
      }
    });
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  getStates() {
    return states;
  }
}

export default new TopStore();
