import {
  crosshairCursor,
  drawSelection,
  dropCursor,
  EditorView,
  highlightActiveLine,
  highlightActiveLineGutter,
  highlightSpecialChars,
  keymap,
  rectangularSelection,
  tooltips,
} from '@codemirror/view';
import {
  defaultKeymap,
  deleteLine,
  history,
  historyKeymap,
  indentLess,
  redo,
} from '@codemirror/commands';
import {
  acceptCompletion,
  clearSnippet,
  closeBrackets,
  closeBracketsKeymap,
  completionKeymap,
  completionStatus,
  moveCompletionSelection,
  nextSnippetField,
  prevSnippetField,
  snippetKeymap,
} from '@codemirror/autocomplete';
import {
  bracketMatching,
  codeFolding,
  foldKeymap,
  indentOnInput,
  indentUnit,
} from '@codemirror/language';
import { lintKeymap } from '@codemirror/lint';
import { EditorState, StateCommand } from '@codemirror/state';
import { stex } from 'codemirror/language/stex';
import {
  highlightSelectionMatches,
  openSearchPanel,
  search,
  searchKeymap,
} from '@codemirror/search';
import { triggerOptionCompletion } from '../../../../utils/LatexHint';
import { editorGutters } from './editorGutters';
import { insertTextbf } from '../../../../../codemirror/language/insertTextbf';
import { SearchPanel } from './searchPanel';

const insertIndentUnit: StateCommand = ({ state, dispatch }) => {
  if (state.readOnly) return false;
  dispatch(
    state.update(state.replaceSelection(state.facet(indentUnit)), {
      scrollIntoView: true,
      userEvent: 'input',
    }),
  );
  return true;
};

// Keep backward compatibility with codemirror 5
const compatibleKeymap = [
  { key: 'Shift-Mod-r', run: openSearchPanel },
  { key: 'Shift-Mod-f', run: openSearchPanel },
  { key: 'Mod-d', run: deleteLine },
  { win: 'Ctrl-Shift-z', run: redo, preventDefault: true },
  { key: 'Shift-Tab', run: indentLess },
  { key: 'Tab', run: acceptCompletion },
  { mac: 'Ctrl-n', run: moveCompletionSelection(true) },
  { mac: 'Ctrl-p', run: moveCompletionSelection(false) },
];

export const basicSetup = [
  editorGutters(),
  highlightSpecialChars(),
  history(),
  codeFolding(),
  drawSelection(),
  dropCursor(),
  EditorState.allowMultipleSelections.of(true),
  stex(),
  indentOnInput(),
  bracketMatching(),
  closeBrackets(),
  rectangularSelection(),
  crosshairCursor(),
  highlightSelectionMatches(),
  EditorView.lineWrapping,
  EditorView.baseTheme({
    '.cm-tooltip': {
      '&.cm-tooltip-autocomplete': {
        '& > ul': {
          fontFamily: 'Inconsolata !important',
          '& .cm-completionPackageName': {
            marginLeft: '0.5em',
            float: 'right',
          },
        },
        '& [aria-selected]': {
          '& .cm-completionMatchedText': {
            color: '#fff',
          },
        },
        '& .cm-completionMatchedText': {
          color: 'dodgerblue',
        },
        '& .cm-completionInfo': {
          boxShadow: '2px 3px 5px rgba(0, 0, 0, 0.2)',
          '& .detail': {
            padding: 0,
            maxWidth: '100%',
            maxHeight: '100%',
          },
        },
        '& .cm-completionPackageName': {
          fontStyle: 'italic',
        },
      },
    },
    '.cm-panel.cm-panel-lint': {
      '& ul': {
        '& [aria-selected]': {
          color: '#333',
        },
      },
    },
    '&dark': {
      '& .cm-tooltip': {
        color: 'inherit !important',
        '&.cm-tooltip-autocomplete': {
          '& [aria-selected]': {
            '& .cm-completionMatchedText': {
              color: 'inherit',
            },
          },
          '& .cm-completionMatchedText': {
            color: 'inherit',
          },
        },
      },
      '& .cm-panels': {
        color: '#fff !important',
        '& input::placeholder': {
          color: '#ccc',
        },
      },
    },
  }),
  indentUnit.of('  '),
  search({
    literal: true,
    createPanel(view) {
      return new SearchPanel(view);
    },
  }),
  snippetKeymap.of([
    {
      key: 'Tab',
      run: (view) => {
        if (completionStatus(view.state) === null) {
          const ret = nextSnippetField(view);
          if (ret) {
            triggerOptionCompletion(view);
          }
          return ret;
        }
        return false;
      },
      shift: (view) => {
        const ret = prevSnippetField(view);
        if (ret) {
          triggerOptionCompletion(view);
        }
        return ret;
      },
    },
    { key: 'Escape', run: clearSnippet },
  ]),
  tooltips({ parent: document.body }),
  keymap.of([
    ...completionKeymap,
    ...compatibleKeymap,
    ...closeBracketsKeymap,
    ...defaultKeymap,
    ...foldKeymap,
    { key: 'Tab', run: insertIndentUnit },
    { key: 'Mod-b', run: insertTextbf },
    ...searchKeymap,
    ...historyKeymap,
    ...lintKeymap,
  ]),
];
