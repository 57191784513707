import * as React from 'react';
import { Box, Button, Icon } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import * as _ from 'lodash';

interface Props {
  onPrevious: (e) => void;
  onNext: (e) => void;
  selected: number;
  totalPages: number;
}

class Pagination extends React.Component<Props, {}> {
  static _theme = (outerTheme) =>
    createTheme(
      {
        overrides: {
          //
        },
      },
      outerTheme,
    );

  constructor(props) {
    super(props);
  }

  render() {
    const buttonBaseStyle: React.CSSProperties = {
      padding: 'unset',
      height: '36px',
      minWidth: '88px',
    };
    const iconSpacerStyle: React.CSSProperties = {
      display: 'inline-block',
      height: '36px',
    };
    const iconStyle: React.CSSProperties = {
      verticalAlign: 'top',
      position: 'relative',
      top: '2px',
    };
    return (
      <ThemeProvider theme={Pagination._theme}>
        <div
          style={{ display: 'flex', justifyContent: 'center', padding: '5px' }}
        >
          <Button
            onClick={this.props.onPrevious}
            disabled={this.props.selected === 1}
            style={_.defaults(
              { opacity: this.props.selected === 1 ? 0.3 : 1 },
              buttonBaseStyle,
            )}
          >
            <span>
              <span style={iconSpacerStyle} />
              <Icon style={iconStyle}>chevron_left</Icon>
            </span>
          </Button>
          <div>
            <Box
              component="span"
              color="text.secondary"
              style={{ fontSize: '16px', fontFamily: 'Roboto, sans-serif' }}
            >
              {`Page ${this.props.selected} of ${this.props.totalPages}`}
            </Box>
          </div>
          <Button
            onClick={this.props.onNext}
            disabled={this.props.selected === this.props.totalPages}
            style={_.defaults(
              {
                opacity:
                  this.props.selected === this.props.totalPages ? 0.3 : 1,
              },
              buttonBaseStyle,
            )}
          >
            <span>
              <span style={iconSpacerStyle} />
              <Icon style={iconStyle}>chevron_right</Icon>
            </span>
          </Button>
        </div>
      </ThemeProvider>
    );
  }
}

export default Pagination;
