import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import * as Colors from '@material-ui/core/colors';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import * as _ from 'lodash';
import ProjectsActions from '../../actions/ProjectsActions';
import Loading from '../Loading';
import { t } from '../../i18n';
import * as CL2Types from '../../CL2Types';

const TableRowColumn = TableCell;
const TableHeaderColumn = TableCell;

interface Props {
  open: boolean;
  onClose: () => void;
  loading: boolean;
  projects: CL2Types.ImportProjects;
}
interface State {
  selectedProject: CL2Types.ImportProjectKey;
}
class ProjectImportDialog extends React.Component<Props, State> {
  static _theme = createTheme({
    palette: {
      primary: { main: Colors.cyan['500'] },
      secondary: { main: Colors.pink.A200 },
    },
    overrides: {
      MuiCheckbox: {
        root: { color: 'rgba(0, 0, 0, 0.87)' },
      },
    },
  });

  constructor(props) {
    super(props);
    this.state = {
      selectedProject: null,
    };
  }

  render() {
    const buttonStyle: React.CSSProperties = {
      margin: '0px',
      minWidth: '88px',
    };
    const labelStyle: React.CSSProperties = {
      fontSize: '14px',
      letterSpacing: '0px',
      position: 'relative',
      left: '1px',
      top: '1px',
    };
    const actions = [
      <Button
        key="cancel"
        children={<span style={labelStyle}>{t('view:projects.cancel')}</span>}
        color="secondary"
        onClick={this.props.onClose}
        style={buttonStyle}
      />,
      <Button
        key="import"
        children={<span style={labelStyle}>{t('view:projects.import')}</span>}
        color="primary"
        onClick={this._onImport}
        style={buttonStyle}
      />,
    ];
    const columnStyle = { padding: '0px 24px' };
    const checkBoxPosition_base: React.CSSProperties = {
      position: 'relative',
      left: '-11.5px',
    };
    const checkBoxPosition1: React.CSSProperties = _.defaults(
      { top: '-0.5px' },
      checkBoxPosition_base,
    );
    const checkBoxPosition2: React.CSSProperties = _.defaults(
      { top: '-1px' },
      checkBoxPosition_base,
    );
    const result = (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        PaperProps={{ style: { width: '75%', maxWidth: '576px' } }}
      >
        <DialogTitle
          disableTypography
          children={
            <h3
              style={{
                margin: '0px',
                color: 'rgba(0, 0, 0, 0.87)',
                fontSize: '22px',
                fontWeight: 400,
                lineHeight: '32px',
              }}
            >
              {t('view:projects.import_project')}
            </h3>
          }
        />
        {this.props.loading ? (
          <DialogContent>
            <Loading />
          </DialogContent>
        ) : (
          <DialogContent>
            <div
              style={{
                marginBottom: '12px',
              }}
            >
              <span
                style={{
                  fontSize: '16px',
                  color: 'rgba(0, 0, 0, 0.6)',
                  fontFamily: 'Roboto, sans-serif',
                }}
              >
                {t('view:projects.dropbox_text1')}
              </span>
              <br />
              <code>{t('view:projects.dropbox_text2')}</code>
            </div>
            <Table style={{ tableLayout: 'fixed' }} id="import_project_table">
              <TableHead>
                <TableRow>
                  <TableHeaderColumn
                    style={_.defaults({ width: '48px' }, columnStyle)}
                  >
                    <span
                      style={_.defaults(
                        { display: 'inline-block', cursor: 'not-allowed' },
                        checkBoxPosition1,
                      )}
                    />
                  </TableHeaderColumn>
                  <TableHeaderColumn style={columnStyle}>
                    <span
                      style={{
                        fontSize: '12px',
                        color: Colors.grey['500'],
                        fontWeight: 'normal',
                        position: 'relative',
                        top: '-1px',
                      }}
                    >
                      {t('view:projects.title')}
                    </span>
                  </TableHeaderColumn>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.projects.map((project_key) => {
                  const isSelected = project_key === this.state.selectedProject;
                  return (
                    <TableRow
                      key={project_key}
                      hover
                      selected={isSelected}
                      onClick={() =>
                        this.setState({
                          selectedProject: isSelected ? null : project_key,
                        })
                      }
                    >
                      <TableRowColumn style={columnStyle}>
                        <Radio
                          checked={isSelected}
                          color="primary"
                          style={_.defaults(
                            { padding: '12px 12px 11px 12px' },
                            checkBoxPosition2,
                          )}
                        />
                      </TableRowColumn>
                      <TableRowColumn style={columnStyle}>
                        <span style={{ fontSize: '13px', fontWeight: 300 }}>
                          {project_key}
                        </span>
                      </TableRowColumn>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </DialogContent>
        )}
        <DialogActions
          style={{ marginLeft: '8px', marginRight: '8px' }}
          children={actions}
        />
      </Dialog>
    );
    return (
      <MuiThemeProvider theme={ProjectImportDialog._theme}>
        {result}
      </MuiThemeProvider>
    );
  }

  private _onImport = () => {
    if (!this.state.selectedProject) {
      return;
    }
    ProjectsActions.startDropboxImport(this.state.selectedProject)
      .then(() => {
        ProjectsActions.clearSelected();
        _.delay(() => {
          ProjectsActions.loadProjects();
        }, 3000);
      })
      .catch((message) => {
        ProjectsActions.openSnackbar(t(message));
      });
  };
}

export default ProjectImportDialog;
