import { Component } from 'react';
import ProjectsApp from './ProjectsApp';
import ProjectsStore from '../../stores/ProjectsStore';
import UserStore from '../../stores/UserStore';
import * as CL2Types from '../../CL2Types';
import { SortOrder } from '../../CL2Types';
import { Props as PromotionAppProps } from '../PromotionApp';
import { withErrorBoundaryFallback } from '../error/withErrorBoundaryFallback';

interface State {
  user: CL2Types.ProjectUser;
  dropbox_linked: boolean;
  query: string;
  elems: CL2Types.Projects;
  totalPages: number;
  currentPage: number;
  sortKey: string;
  sortOrder: SortOrder;
  usage: CL2Types.UsageProps;
  loading: boolean;
  importDialog: {
    open: boolean;
    loading: boolean;
    projects: CL2Types.ImportProjects;
    error: string;
  };
  templatesDialog: {
    tags: { label: string; path: string }[];
    loaded: boolean;
    query: string;
  };
  snackbar: {
    open: boolean;
    message: string;
  };
  error: CL2Types.ProjectsError;
  dropboxDisabled: boolean;
  selectTargetImportedProject: {
    open: boolean;
    id: number;
    files: Array<object>;
  };
  zip: {
    file?: CL2Types.UploadFile;
  };
  selected: CL2Types.Projects;
}

interface Props {
  promotionAppProps: PromotionAppProps;
}

class ProjectsAppCtrl extends Component<Props, State> {
  constructor(props) {
    super(props);
    const storeStates = UserStore.getStates();
    this.state = {
      user: window['user'],
      dropbox_linked: storeStates.dropbox_linked,
      query: '',
      elems: [],
      totalPages: 1,
      currentPage: 1,
      sortKey: 'updated_at',
      sortOrder: SortOrder.DESC,
      usage: null,
      loading: true,
      importDialog: {
        open: false,
        loading: false,
        projects: [],
        error: '',
      },
      templatesDialog: {
        tags: [],
        loaded: false,
        query: '',
      },
      snackbar: {
        open: false,
        message: '',
      },
      error: {
        open: false,
        message: null,
        handle_kind: null,
      },
      dropboxDisabled: false,
      selectTargetImportedProject: {
        open: false,
        id: 0,
        files: [],
      },
      zip: {
        file: null,
      },
      selected: [],
    };
  }

  componentDidMount() {
    ProjectsStore.addChangeListener(this._onProjectsChange);
    UserStore.addChangeListener(this._onUserChange);
  }

  componentWillUnMount() {
    ProjectsStore.removeChangeListener(this._onProjectsChange);
    UserStore.removeChangeListener(this._onUserChange);
  }

  private _onProjectsChange = () => {
    const newState = ProjectsStore.getStates();
    this.setState({
      query: newState.query,
      elems: newState.projects,
      totalPages: newState.totalPages,
      currentPage: newState.currentPage,
      sortKey: newState.sortKey,
      sortOrder: newState.sortOrder,
      loading: newState.loading,
      importDialog: newState.importDialog,
      templatesDialog: newState.templatesDialog,
      snackbar: newState.snackbar,
      error: newState.error,
      selectTargetImportedProject: newState.selectTargetImportedProject,
      zip: newState.zip,
      selected: newState.selected,
    });
  };

  private _onUserChange = () => {
    const newState = UserStore.getStates();
    this.setState({
      user: newState,
      dropbox_linked: newState.dropbox_linked,
      usage: newState.usage,
    });
  };

  /*
    onRequestCloseSnackbar() {
      this.setState({ snackbar: { open: false }});
    }
  */

  render() {
    return <ProjectsApp {...this.props} {...this.state} />;
  }
}

export default withErrorBoundaryFallback(ProjectsAppCtrl);
