import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import * as Colors from '@material-ui/core/colors';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { t } from '../../i18n';
import EditorActions from '../../actions/EditorActions';

interface Props {
  open: boolean;
  onClose?: (e: React.SyntheticEvent) => void;
}
class OfflineDialog extends React.Component<Props, {}> {
  static _theme = (outerTheme) =>
    createTheme(
      {
        overrides: {
          MuiButton: {
            textPrimary: { color: Colors.cyan['500'] },
            textSecondary: { color: Colors.pink.A200 },
          },
        },
      },
      outerTheme,
    );

  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  private reconnect = () => {
    EditorActions.reconnect();
  };

  render() {
    const buttonStyle: React.CSSProperties = { minWidth: '88px' };
    const buttonLabelStyle: React.CSSProperties = {
      fontSize: '14px',
      position: 'relative',
      top: '1px',
    };

    return (
      <MuiThemeProvider theme={OfflineDialog._theme}>
        <Dialog
          onClose={this.props.onClose}
          open={this.props.open}
          PaperProps={{ style: { width: '75%', maxWidth: '590px' } }}
        >
          <DialogTitle
            key="title"
            disableTypography
            children={
              <Box
                component="h3"
                style={{
                  margin: 'unset',
                  fontSize: '22px',
                  fontWeight: 400,
                  lineHeight: '32px',
                }}
                color="text.primary"
              >
                {t('view:editor.offline.title')}
              </Box>
            }
          />
          <DialogContent key="content">
            <Box
              component="span"
              style={{
                fontSize: '16px',
                fontFamily: 'Roboto, sans-serif',
                whiteSpace: 'pre-wrap',
              }}
              color="text.secondary"
            >
              {t('view:editor.offline.message')}
            </Box>
          </DialogContent>
          <DialogActions key="actions" style={{ margin: '8px' }}>
            <Button
              children={
                <span style={buttonLabelStyle}>
                  {t('view:editor.offline.reconnect')}
                </span>
              }
              color="primary"
              key="create"
              onClick={this.reconnect}
              style={buttonStyle}
            />
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

export default OfflineDialog;
