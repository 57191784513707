import * as React from 'react';
import {
  Menu,
  MenuItem,
  MenuProps,
  styled,
  ThemeProvider,
} from '@material-ui/core';
import { createTheme, Theme, withStyles } from '@material-ui/core/styles';

interface TransformOrigin {
  vertical: 'bottom' | 'top';
  horizontal: 'right' | 'left';
}

const MenuEx = withStyles((theme) => ({
  paper: {
    boxShadow:
      '0px 1px 6px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.12)',
  },
}))((props: MenuProps) => (
  <ThemeProvider
    theme={(outerTheme: Theme) =>
      createTheme(
        {
          overrides: {
            MuiList: {
              padding: {
                paddingTop: '16px',
                paddingBottom: '16px',
              },
            },
            MuiListItem: {
              root: {
                '&$focusVisible': {
                  backgroundColor: outerTheme.palette.action.hover,
                },
              },
              button: {
                '&:hover': {
                  backgroundColor: outerTheme.palette.action.hover,
                },
              },
            },
            MuiTouchRipple: {
              rippleVisible: {
                // this is the default opacity value of Material-UI v0's FocusRipple
                opacity: 0.1,
              },
            },
          },
        },
        outerTheme,
      )
    }
  >
    <Menu {...props} />
  </ThemeProvider>
));

const MenuItemLabel = styled('span')(
  ({ theme, color }: { theme: Theme; color?: string }) => ({
    color: theme.palette[color],
  }),
);

interface Props<Item> {
  pos: { x: number; y: number };
  anchorEl: HTMLElement;
  className?: string;
  menuItems: Array<Item>;
  onItemSelected: (e, item: Item) => void;
  onClose: (e, reason, callback: () => void) => void;
  onCancel?: () => void;
}
class ContextMenu<
  Item extends { primaryText: string; color?: string },
> extends React.Component<Props<Item>, {}> {
  constructor(props) {
    super(props);
  }

  private _onMenuItemClick = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onClose(e, null, () => this.props.onItemSelected(e, item));
  };

  render() {
    const pos =
      this.props.pos &&
      (function (this_props_pos) {
        const { x: left, y: top } = this_props_pos;
        return { left, top };
      })(this.props.pos);

    const transformOrigin: TransformOrigin = pos
      ? {
          vertical: pos.top > $(window).height() / 2 ? 'bottom' : 'top',
          horizontal: pos.left > $(window).width() / 2 ? 'right' : 'left',
        }
      : {
          vertical: 'top',
          horizontal: 'left',
        };

    return (
      <MenuEx
        open={Boolean(this.props.anchorEl)}
        anchorEl={this.props.anchorEl}
        anchorReference="anchorPosition"
        anchorPosition={pos}
        transformOrigin={transformOrigin}
        onClose={(e) => {
          this.props.onCancel && this.props.onCancel();
          this.props.onClose(e, null, null);
        }}
        transitionDuration={1}
        onContextMenu={(e) => e.preventDefault()}
        disableAutoFocusItem
      >
        {this.props.menuItems.map((item) => {
          const { primaryText, color, ...rest } = item;
          return (
            <MenuItem
              onClick={(e) => this._onMenuItemClick(e, item)}
              {...rest}
              focusRipple
              TouchRippleProps={{ style: { opacity: 0.16 } }}
              disableTouchRipple
            >
              <MenuItemLabel color={color}>{primaryText}</MenuItemLabel>
            </MenuItem>
          );
        })}
      </MenuEx>
    );
  }
}

export default ContextMenu;
