import { EventEmitter } from 'events';
import AppDispatcher from '../dispatcher/AppDispatcher';

import UserConstants from '../constants/UserConstants';

const CHANGE_EVENT = 'change';

const INITIAL_EXTENSION_STATUS = {
  token: null,
  client: null,
  failed_to_generate: false,
  failed_to_delete: false,
  successed_to_generate: false,
  successed_to_delete: false,
  errors: [],
};

const states = {
  usage: null,
  dropbox_linked: false,
  state: window['user']?.state,
  state_label: window['user']?.state_label,
  sync_target: '',
  unlinking: false,
  dropbox_disabled: true,
  password_set: false,
  extension: INITIAL_EXTENSION_STATUS,
  features: window['user']?.features,
};

class UserStore extends EventEmitter {
  dispatchToken: string;

  constructor() {
    super();
    this.dispatchToken = AppDispatcher.register((action) => {
      switch (action.actionType) {
        case UserConstants.RECEIVE_USER_USAGE:
          states.usage = action.json;
          this.emitChange();
          break;
        case UserConstants.RECEIVE_USER_INFO:
          states.dropbox_linked = action.json.user.dropbox_linked;
          states.unlinking = false;
          states.dropbox_disabled = !action.json.user.features.dropbox;
          states.password_set = action.json.user.password_set;
          states.state = action.json.user.state;
          states.features = action.json.user.features;
          this.emitChange();
          break;
        case UserConstants.UNLINK_DROPBOX:
          states.unlinking = true;
          this.emitChange();
          break;
        case UserConstants.RECEIVE_EXTENSION_TOKEN:
          states.extension = {
            ...INITIAL_EXTENSION_STATUS,
            successed_to_generate: true,
            token: action.params.token,
            client: action.params.client,
          };
          this.emitChange();
          break;
        case UserConstants.FAILED_GENERATE_EXTENSION_TOKEN:
          states.extension = {
            ...INITIAL_EXTENSION_STATUS,
            failed_to_generate: true,
          };
          if (
            action.json &&
            action.json.errors &&
            Array.isArray(action.json.errors)
          ) {
            states.extension.errors = action.json.errors;
          }
          this.emitChange();
          break;
        case UserConstants.DELETED_EXTENSION_TOKEN:
          states.extension = {
            ...INITIAL_EXTENSION_STATUS,
            successed_to_delete: true,
          };
          this.emitChange();
          break;
        case UserConstants.FAILED_DELETE_EXTENSION_TOKEN:
          states.extension = {
            ...INITIAL_EXTENSION_STATUS,
            failed_to_delete: true,
          };
          this.emitChange();
          break;
      }
    });
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  getStates() {
    return states;
  }
}

export default new UserStore();
