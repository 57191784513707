import { Component } from 'react';
import * as _ from 'lodash';
import { Theme } from '@material-ui/core/styles';
import { Box, withTheme } from '@material-ui/core';

interface Props {
  linkToTop: boolean;
  style?: object;
  theme: Theme;
}

class CLLogo extends Component<Props, {}> {
  constructor(props) {
    super(props);
  }

  render() {
    const style = _.assign(
      {
        lineHeight: '48px',
        height: '48px',
        color: this.props.theme.palette.text.header,
      },
      this.props.style,
    );
    let cloudlatexLogo;
    if (this.props.linkToTop) {
      cloudlatexLogo = (
        <a href="/" aria-label="CloudLaTeX logo">
          <img
            src={`/images/cloudlatex_logo${this.props.theme.palette.type === 'dark' ? '_dark' : ''}.svg`}
            id="small_logo"
          />
        </a>
      );
    } else {
      cloudlatexLogo = (
        <img
          src={`/images/cloudlatex_logo${this.props.theme.palette.type === 'dark' ? '_dark' : ''}.svg`}
          id="small_logo"
        />
      );
    }

    return (
      <span style={style}>
        {cloudlatexLogo}
        <div className="acaric_logo">
          <Box component="span" sx={{ ml: 1 }}>
            by
          </Box>
          <a
            href="https://acaric.jp/?utm_source=cloudlatex_header&utm_medium=banner&utm_campaign=acweb_top"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={`/images/acaric_logo${this.props.theme.palette.type === 'dark' ? '_dark' : ''}.png`}
              alt="株式会社アカリク"
              width={144}
              style={{ verticalAlign: 'bottom' }}
            />
          </a>
        </div>
      </span>
    );
  }
}

export default withTheme(CLLogo);
