import * as React from 'react';
import { CircularProgress } from '@material-ui/core';
import AbstractComponent from '../../AbstractComponent';
import { t } from '../../../i18n';
import EditorActions from '../../../actions/EditorActions';
import { PDFFile } from '../../../CL2Types';

interface Props {
  visible: boolean;
  pdfFiles: Array<PDFFile>;
  texDocSearching: boolean;
  exitCode?: number;
}

interface State {
  query: string;
}

class TeXDocViewer extends AbstractComponent<Props, State> {
  private searchTextRef?: HTMLInputElement = null;

  constructor(props) {
    super(props);
    this.state = {
      query: '',
    };
  }

  _handleQuerySubmit(e) {
    e.preventDefault();
    EditorActions.searchTeXDocument(this.searchTextRef.value);
  }

  _handleQueryChange(e) {
    this.setState({ query: e.target.value });
  }

  _handleQueryClear(e) {
    e.preventDefault();
    this.setState({ query: '' });
  }

  render() {
    let rootStyle: React.CSSProperties;

    if (this.props.visible) {
      rootStyle = { flex: '1 1', overflowY: 'auto' };
    } else {
      rootStyle = { display: 'none' };
    }

    const baseStyle: React.CSSProperties = {
      border: 'unset',
      backgroundColor: 'unset',
      fontFamily: 'Menlo, Monaco, Consolas, "Courier New"',
    };
    const descriptionStyle: React.CSSProperties = {
      padding: '9.5px',
      whiteSpace: 'break-spaces',
      ...baseStyle,
    };
    let information;

    if (this.props.exitCode !== null) {
      if (this.props.exitCode === 0 && this.props.pdfFiles.length > 0) {
        information = this.props.pdfFiles.map((pdfFile) => (
          <li key={pdfFile.pdfFileName}>
            <a target="_blank" rel="noreferrer" href={pdfFile.uri}>
              {pdfFile.pdfFileName}
            </a>
          </li>
        ));
        information = <ol>{information}</ol>;
      } else {
        information = t('view:projects.document_not_found');
      }
    }

    return (
      <div style={rootStyle}>
        <div style={descriptionStyle}>
          {t('view:projects.texdoc_description')}
        </div>
        <pre style={baseStyle}>
          <form
            id="search_text_form"
            className="form-inline"
            onSubmit={this._handleQuerySubmit}
          >
            <div className="form-group">
              <input
                className="form-control"
                ref={(searchTextRef) => {
                  this.searchTextRef = searchTextRef;
                }}
                value={this.state.query}
                onChange={this._handleQueryChange}
              />
              <button className="btn btn-primary btn-sm" type="submit">
                {t('view:projects.search')}
              </button>
              <button className="btn btn-sm" onClick={this._handleQueryClear}>
                {t('view:projects.clear')}
              </button>
              {this.props.texDocSearching && (
                <CircularProgress
                  size={23}
                  style={{ verticalAlign: 'middle' }}
                />
              )}
            </div>
          </form>
        </pre>
        {information}
      </div>
    );
  }
}

export default TeXDocViewer;
