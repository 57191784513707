import { bibtexParser } from 'latex-utensils';
import { editorStore as store } from '../store';
import EditorWebAPIUtils from '../utils/EditorWebAPIUtils';

import EditorConstants from '../constants/EditorConstants';
import { getFileByFullPath, getFileById } from '../reducers/editor';
import CLSynctexManager from '../utils/synctex/CLSynctexManager';
import * as CL2Types from '../CL2Types';
import {
  PermissionControlled,
  SkipPermissionCheck,
  RequireRead,
  RequireEdit,
} from '../utils/permission';

const synctexManager = new CLSynctexManager();

export function loadSynctex(uri) {
  synctexManager.loadSynctexObject(uri);
}

@PermissionControlled
class EditorActions {
  @SkipPermissionCheck
  static loadProjectInfo() {
    store.dispatch({
      type: EditorConstants.LOAD_PROJECT_INFO,
    });
    return EditorWebAPIUtils.loadProjectInfo()
      .then((res: any) => {
        const json = JSON.parse(res.text);
        EditorActions.receiveProjectInfo(json);
      })
      .catch(() => {
        EditorActions.failedLoad();
      });
  }

  @SkipPermissionCheck
  static receiveProjectInfo(json: any) {
    store.dispatch({
      type: EditorConstants.RECEIVE_PROJECT_INFO,
      json,
    });
  }

  @RequireRead
  static failedLoad() {
    store.dispatch({
      type: EditorConstants.FAILED_LOAD,
    });
  }

  @RequireEdit
  static exportProject(storage_type: number, interval: number) {
    store.dispatch({
      type: EditorConstants.EXPORT_PROJECT,
    });
    return EditorWebAPIUtils.exportProject(storage_type)
      .then((res) => {
        const json = JSON.parse(res.text);
        EditorActions.receiveExportProject(json);
        EditorActions.showSnackbar(json);
        EditorActions.startPollingExportStatus(interval);
        return res;
      })
      .catch((res) => {
        const json = JSON.parse(res.text);
        EditorActions.receiveExportProject(json);
        EditorActions.showSnackbar(json);
        throw res;
      });
  }

  @RequireEdit
  static receiveExportProject(json: any) {
    store.dispatch({
      type: EditorConstants.RECEIVE_EXPORT_PROJECT,
      json,
    });
  }

  @RequireEdit
  static startPollingExportStatus(interval: number) {
    EditorWebAPIUtils.startPollingExportStatus(interval)
      .then((res: any) => {
        const json = JSON.parse(res.text);
        EditorActions.showSnackbar(json);
        return res;
      })
      .catch((res) => {
        const json = JSON.parse(res.text);
        if (res.status === 200) {
          EditorActions.showSnackbar(json);
        } else {
          EditorActions.apiError(json);
        }
        throw res;
      });
  }

  @RequireEdit
  static autoPollingExportStatus(interval: number) {
    return EditorWebAPIUtils.getExportStatus()
      .then((res) => {
        if (res['export_status'] === 'export_incomplete') {
          EditorActions.startPollingExportStatus(interval);
        }
        return res;
      })
      .catch((e) => {});
  }

  @RequireEdit
  static unsyncProject() {
    store.dispatch({
      type: EditorConstants.EXPORT_PROJECT,
    });
    return EditorWebAPIUtils.unsyncProject().then((res) => {
      const json = JSON.parse(res.text);
      EditorActions.receiveExportProject(json);
      return res;
    });
  }

  @RequireRead
  static loadFiles() {
    store.dispatch({
      type: EditorConstants.LOAD_FILES,
    });
    return EditorWebAPIUtils.loadFiles()
      .then((res) => {
        const json = JSON.parse(res.text);
        EditorActions.receiveLoadFiles(json);
        return res;
      })
      .catch((res) => {
        EditorActions.failedLoad();
        throw res;
      });
  }

  @RequireRead
  static receiveLoadFiles(json: any) {
    store.dispatch({
      type: EditorConstants.RECEIVE_LOAD_FILES,
      json,
    });
    EditorActions.loadBibtexItems();
  }

  @SkipPermissionCheck
  static loadUser() {
    return EditorWebAPIUtils.loadUser().then((res) => {
      const json = JSON.parse(res.text);
      EditorActions.receiveUserInfo(json);
      return res;
    });
  }

  @SkipPermissionCheck
  static receiveUserInfo(json: any) {
    store.dispatch({
      type: EditorConstants.RECEIVE_USER_INFO,
      json,
    });
  }

  @RequireEdit
  static openFileDialog(is_folder: boolean, folder: any = null) {
    store.dispatch({
      type: EditorConstants.OPEN_FILE_DIALOG,
      params: { is_folder, folder },
    });
  }

  @RequireEdit
  static closeFileDialog() {
    store.dispatch({
      type: EditorConstants.CLOSE_FILE_DIALOG,
    });
  }

  @RequireEdit
  static createFile(name: string, belongs: number) {
    store.dispatch({
      type: EditorConstants.REQUEST_CREATE,
    });
    return EditorWebAPIUtils.createFile(name, belongs, false)
      .then((res) => {
        EditorActions.doneCreate();
        return res;
      })
      .catch(() => {
        store.dispatch({
          type: EditorConstants.FAILED_CREATE,
        });
      });
  }

  @RequireEdit
  static createFolder(name: string, belongs: number) {
    store.dispatch({
      type: EditorConstants.REQUEST_CREATE,
    });
    return EditorWebAPIUtils.createFile(name, belongs, true)
      .then((res) => {
        EditorActions.doneCreate();
        return res;
      })
      .catch(() => {
        store.dispatch({
          type: EditorConstants.FAILED_CREATE,
        });
      });
  }

  @RequireEdit
  static doneCreate() {
    store.dispatch({
      type: EditorConstants.DONE_CREATE,
    });
  }

  @RequireEdit
  static loadFileVersions(file_id: number) {
    store.dispatch({
      type: EditorConstants.LOAD_FILE_VERSIONS,
    });
    return EditorWebAPIUtils.loadFileVersions(file_id).then((res) => {
      const json = JSON.parse(res.text);
      EditorActions.receiveLoadFileVersions(json);
      return res;
    });
  }

  @RequireEdit
  static receiveLoadFileVersions(json: any) {
    store.dispatch({
      type: EditorConstants.RECEIVE_LOAD_FILE_VERSIONS,
      json,
    });
  }

  @RequireEdit
  static restoreFile(file_id: number, version_id: string) {
    store.dispatch({
      type: EditorConstants.RESTORE_FILE,
    });
    return EditorWebAPIUtils.restoreFile(file_id, version_id)
      .then((res: any) => {
        const json = JSON.parse(res.text);
        EditorActions.receiveRestoreFile(json);
        EditorActions.showSnackbar(json);
        return res;
      })
      .catch((res) => {
        const json = JSON.parse(res.text);
        EditorActions.receiveRestoreFile(json);
        EditorActions.showSnackbar(json);
        throw res;
      });
  }

  @RequireEdit
  static receiveRestoreFile(json: any) {
    store.dispatch({
      type: EditorConstants.RECEIVE_RESTORE_FILE,
      json,
    });
  }

  @RequireRead
  static async openFile(id: number | null | undefined, background = false) {
    const state = store.getState().editor;
    if (
      !id ||
      !state.project ||
      !state.files ||
      state.files.filter((file) => file.id === id).length === 0
    ) {
      return;
    }
    if (!background) {
      store.dispatch({
        type: EditorConstants.SEND_OPEN_FILE,
        fileId: id,
      });
    }
    const res = await EditorWebAPIUtils.openFile(id);
    const json = JSON.parse(res.text);
    if (background) {
      store.dispatch({
        type: EditorConstants.FETCH_FILE,
        json,
      });
    } else {
      store.dispatch({
        type: EditorConstants.OPEN_FILE,
        json,
      });
      if (store.getState().editor.project.permission.edit) {
        if (id !== store.getState().editor.project.last_opened_file_id) {
          EditorActions.updateProject({ last_opened_file_id: id });
        }
      }
    }
    return res;
  }

  @RequireRead
  static openBinaryFile(id: number) {
    store.dispatch({
      type: EditorConstants.OPEN_BINARY_FILE,
      id,
    });
  }

  @RequireRead
  static closeBinaryFile() {
    store.dispatch({
      type: EditorConstants.CLOSE_BINARY_FILE,
    });
  }

  @RequireEdit
  static compile() {
    const state = store.getState().editor;
    if (state.compiling) {
      store.dispatch({
        type: EditorConstants.COMPILE_WAITING,
      });
      return new Promise<void>((resolve, reject) => {
        resolve();
      });
    }
    store.dispatch({
      type: EditorConstants.COMPILE,
    });
    return EditorWebAPIUtils.compileProject()
      .then((res) => {
        const json = JSON.parse(res.text);
        EditorActions.doneCompile(json);
        if (json && json.synctex_uri) {
          synctexManager.loadSynctexObject(json.synctex_uri);
        }
        EditorActions.loadFiles();
        return json;
      })
      .catch((res) => {
        if (res.status !== 409) {
          // not conflict
          const json = JSON.parse(res.text);
          // multiple compile
          if (
            json.title ===
              'このプロジェクトは現在コンパイル処理中です。コンパイルの重複実行は禁止されているため、お手数ですがしばらく経ってから再実行してください。' ||
            json.title ===
              'This project is currently in the compiling process. Duplicate compilation is not allowed, so please re-compile it after a while.'
          ) {
            EditorActions.multipleCompile();
          } else {
            EditorActions.doneCompile({
              exitCode: null,
              uri: null,
              jpgs: [],
              errors: ['Internal Server Error'],
            });
          }
        }
      });
  }

  static fetchCompileId = null;

  @RequireRead
  static fetchCompileResult() {
    store.dispatch({
      type: EditorConstants.COMPILE,
    });

    clearTimeout(EditorActions.fetchCompileId);
    EditorActions.fetchCompileId = setTimeout(() => {
      store.dispatch({
        type: EditorConstants.SHOW_PDF_VIEW_LOADING,
      });
    }, 5000);

    EditorWebAPIUtils.fetchCompileResult()
      .then((res) => {
        const json = JSON.parse(res.text);
        EditorActions.doneCompile(json);
        clearTimeout(EditorActions.fetchCompileId);
        if (json && json.synctex_uri) {
          synctexManager.loadSynctexObject(json.synctex_uri);
        }
      })
      .catch(() => {
        EditorActions.failedCompile();
        clearTimeout(EditorActions.fetchCompileId);
      });
  }

  @RequireEdit
  static killCompileProcess() {
    EditorWebAPIUtils.killCompileProcess();
  }

  @RequireEdit
  static searchTeXDocument(query: string) {
    store.dispatch({
      type: EditorConstants.SEARCH_TEX_DOCUMENT,
    });
    return EditorWebAPIUtils.searchTeXDocument(query)
      .then((res) => {
        const json = JSON.parse(res.text);
        store.dispatch({
          type: EditorConstants.DONE_SEARCH_TEX_DOCUMENT,
          json,
        });
      })
      .catch(() =>
        store.dispatch({
          type: EditorConstants.ERR_SEARCH_TEX_DOCUMENT,
        }),
      );
  }

  @RequireRead
  static doneCompile(json: any) {
    store.dispatch({
      type: EditorConstants.DONE_COMPILE,
      json,
    });
  }

  @RequireEdit
  static multipleCompile() {
    store.dispatch({
      type: EditorConstants.MULTIPLE_COMPILE,
    });
  }

  @RequireRead
  static failedCompile() {
    store.dispatch({
      type: EditorConstants.FAILED_COMPILE,
    });
  }

  @RequireEdit
  static startUploadSession() {
    store.dispatch({ type: EditorConstants.START_UPLOAD_SESSION });
  }

  @RequireEdit
  static upload(form: any, fileList: Array<CL2Types.UploadFile>) {
    store.dispatch({ type: EditorConstants.START_UPLOAD });
    return EditorWebAPIUtils.uploadFiles(form, fileList);
  }

  @RequireEdit
  static doneUpload(data: any) {
    store.dispatch({
      type: EditorConstants.DONE_UPLOAD,
      data,
    });
  }

  @RequireEdit
  static progressUpload(data: any) {
    store.dispatch({
      type: EditorConstants.PROGRESS_UPLOAD,
      data,
    });
  }

  @RequireEdit
  static finishUpload() {
    store.dispatch({
      type: EditorConstants.FINISH_UPLOAD,
    });
  }

  @RequireEdit
  static failedUpload(data: any) {
    store.dispatch({
      type: EditorConstants.FAIL_UPLOAD,
      data,
    });
  }

  @RequireEdit
  static finishUploadSession() {
    store.dispatch({ type: EditorConstants.FINISH_UPLOAD_SESSION });
  }

  @RequireEdit
  static deleteFile(id: number) {
    store.dispatch({
      type: EditorConstants.REQUEST_DELETE,
    });
    return EditorWebAPIUtils.deleteFile(id)
      .then((res) => {
        EditorActions.doneDelete();
        return res;
      })
      .catch(() => {
        store.dispatch({
          type: EditorConstants.FAILED_DELETE,
        });
      });
  }

  @RequireEdit
  static doneDelete() {
    store.dispatch({
      type: EditorConstants.DONE_DELETE,
    });
  }

  @RequireEdit
  static updateFile(id: number, params: any) {
    store.dispatch({
      type: EditorConstants.REQUEST_SAVE,
      params,
    });
    if (!params.hasOwnProperty('revision')) {
      params.revision = getFileById(store.getState().editor, id).revision;
    }
    return EditorWebAPIUtils.updateFile(id, params)
      .then((res) => {
        const json = JSON.parse(res.text);
        EditorActions.doneSave(id, json);
        return res;
      })
      .catch((res) => {
        if (
          res.status === 404 &&
          id === store.getState().editor.currentFileId
        ) {
          EditorActions.closeFile();
        }
        EditorActions.failedSave();
        throw res;
      });
  }

  @RequireEdit
  static doneSave(id, json) {
    store.dispatch({
      type: EditorConstants.DONE_SAVE,
      id,
      json,
    });
  }

  @RequireRead
  static closeFile() {
    store.dispatch({
      type: EditorConstants.CLOSE_FILE,
    });
  }

  @RequireEdit
  static failedSave() {
    store.dispatch({
      type: EditorConstants.FAILED_SAVE,
    });
  }

  @RequireEdit
  static updateProject(params: any) {
    store.dispatch({
      type: EditorConstants.LOAD_PROJECT_INFO,
    });
    return EditorWebAPIUtils.updateProject(params)
      .then((res) => {
        const json = JSON.parse(res.text);
        EditorActions.receiveProjectInfo(json);
        return res;
      })
      .catch((res) => {
        EditorActions.failedLoad();
        throw res;
      });
  }

  @RequireEdit
  static setCompileTarget(id: number) {
    return EditorWebAPIUtils.setCompileTarget(id);
  }

  @RequireRead
  static closeSnackbar() {
    store.dispatch({
      type: EditorConstants.CLOSE_SNACKBAR,
    });
  }

  @RequireRead
  static openRefreshDialog() {
    store.dispatch({
      type: EditorConstants.OPEN_REFRESH_DIALOG,
    });
  }

  @RequireRead
  static forceRefresh() {
    store.dispatch({
      type: EditorConstants.CLOSE_REFRESH_DIALOG,
    });
    document.location.reload();
  }

  @RequireRead
  static retryRequest(request, doneFunc, failFunc) {
    return EditorWebAPIUtils.retryRequest(request, doneFunc, failFunc);
  }

  @RequireRead
  static resetRetry() {
    store.dispatch({
      type: EditorConstants.RESET_RETRY,
    });
  }

  @RequireEdit
  static resolveAllFiles(source) {
    store.dispatch({
      type: EditorConstants.RESOLVE_CONFLICT,
    });
    return EditorWebAPIUtils.resolveAllFiles(source).then((res) => {
      const json = JSON.parse(res.text);
      EditorActions.resolveFinish(json);
      return res;
    });
  }

  @RequireEdit
  static resolveFinish(json: any) {
    store.dispatch({
      type: EditorConstants.RESOLVE_FINISH,
      json,
    });
  }

  @RequireEdit
  static closeConflictFileDialog() {
    store.dispatch({
      type: EditorConstants.CLOSE_CONFLICT_FILE_DIALOG,
    });
  }

  @RequireRead
  static closeErrorDialog() {
    store.dispatch({
      type: EditorConstants.CLOSE_ERROR_DIALOG,
    });
  }

  @RequireRead
  static synctexFromEditor(line: number, input, showPdfIndicator = false) {
    // synctex at client side
    try {
      const syncTexRecordForward = synctexManager.synctexForward(line, input);
      EditorActions.setPageOfPdfView(
        'synctex',
        syncTexRecordForward.page,
        syncTexRecordForward.x,
        syncTexRecordForward.y,
      );
      if (showPdfIndicator) {
        setTimeout(() => EditorActions.showSynctexPdfIndicator(), 100);
      }
    } catch (e) {}
    /*
    // synctex at server side
    EditorWebAPIUtils.synctexFromEditor(line, column, input).then((res) => {
      const res_json = JSON.parse(res.text);
      if (res_json.success_or_failure === 'success') {
        const page = Number(res_json.result.Page);
        console.log('get synctexfrom editor', res_json.result);
        if ((page > 0) && (page < 1000000)) {
          EditorActions.setPageOfPdfView('synctex', page);
        }
      }
      return res;
    });
    */
  }

  @RequireRead
  static showSynctexPdfIndicator() {
    store.dispatch({
      type: EditorConstants.SHOW_SYNCTEX_PDF_INDICATOR,
    });
  }

  @RequireRead
  static hideSynctexPdfIndicator() {
    store.dispatch({
      type: EditorConstants.HIDE_SYNCTEX_PDF_INDICATOR,
    });
  }

  @RequireRead
  static setPageOfPdfView(originator, page, x = null, y = null) {
    store.dispatch({
      type: EditorConstants.PDFVIEW_SETPAGE,
      json: {
        originator,
        page,
        x,
        y,
      },
    });
  }

  @RequireRead
  static async synctexFromPdf(
    page: number,
    x: number,
    y: number,
    textBeforeSelection: string,
    textAfterSelection: string,
    showEditorIndicator = false,
  ) {
    // synctex at client side
    let syncTexRecordBackward;
    try {
      syncTexRecordBackward = synctexManager.synctexBackward(page, x, y);
    } catch (e) {}
    if (syncTexRecordBackward) {
      await EditorActions.setEditorPostion(
        'synctex',
        syncTexRecordBackward.input,
        syncTexRecordBackward.line,
        syncTexRecordBackward.column,
        textBeforeSelection,
        textAfterSelection,
      );
      if (showEditorIndicator) {
        setTimeout(() => EditorActions.showSynctexEditorIndicator(), 100);
        setTimeout(() => EditorActions.hideSynctexEditorIndicator(), 2000);
      }
    }
  }

  @RequireRead
  static showSynctexEditorIndicator() {
    store.dispatch({
      type: EditorConstants.SHOW_SYNCTEX_EDITOR_INDICATOR,
    });
  }

  @RequireRead
  static hideSynctexEditorIndicator() {
    store.dispatch({
      type: EditorConstants.HIDE_SYNCTEX_EDITOR_INDICATOR,
    });
  }

  @RequireRead
  static async setEditorPostion(
    originator: string,
    fullPath: string,
    line: number,
    column: number,
    textBeforeSelection: string,
    textAfterSelection: string,
  ) {
    const file = getFileByFullPath(store.getState().editor, fullPath);
    const nextId = file?.id;
    if (nextId && nextId !== store.getState().editor.currentFileId) {
      await EditorActions.openFile(nextId);
    }
    store.dispatch({
      type: EditorConstants.SET_EDITOR_POSITION,
      json: {
        originator,
        line,
        column,
        textBeforeSelection,
        textAfterSelection,
      },
    });
  }

  @RequireRead
  static loadSynctexObject(url) {
    EditorWebAPIUtils.loadSynctexObject(url).then((res) => {
      synctexManager
        .parseSyncTexGz(res.body)
        .then(EditorActions.receiveSynctexObject);
      return res;
    });
  }

  @RequireRead
  static receiveSynctexObject(synctexObject) {
    store.dispatch({
      type: EditorConstants.RECEIVE_SYNCTEX_OBJECT,
      synctexObject,
    });
  }

  @RequireEdit
  static conflictFile(json, request, doneFunc, failFunc) {
    store.dispatch({
      type: EditorConstants.FILE_CONFLICT,
      json,
      suspendedRequest: {
        request,
        doneFunc,
        failFunc,
      },
    });
  }

  @RequireRead
  static onOffline() {
    store.dispatch({
      type: EditorConstants.NETWORK,
      status: 'offline',
    });
  }

  @RequireRead
  static onOnline() {
    store.dispatch({
      type: EditorConstants.NETWORK,
      status: 'online',
    });
  }

  @RequireRead
  static reconnect() {
    return EditorWebAPIUtils.reconnect()
      .then(() => {
        EditorActions.onOnline();
      })
      .catch(() => {});
  }

  @RequireRead
  static apiError(json: any) {
    store.dispatch({
      type: EditorConstants.API_ERROR,
      json,
    });
  }

  @RequireRead
  static showSnackbar(json: any) {
    store.dispatch({
      type: EditorConstants.SHOW_SNACKBAR,
      json,
    });
  }

  @RequireRead
  static changeTab(tabValue: number) {
    store.dispatch({
      type: EditorConstants.CHANGE_TAB,
      tabValue,
    });
  }

  @RequireRead
  static loadBibtexItems() {
    const state = store.getState().editor;
    if (!state || !state.files) {
      return;
    }

    try {
      const bibtexFiles = state.files.filter((file) =>
        file.name.match(/\.bib$/),
      );
      let bibtexItems: CL2Types.BibtexItems = [];
      const promises = bibtexFiles.map((file) =>
        EditorWebAPIUtils.openFile(file.id).then((res) => {
          const json = JSON.parse(res.text);
          const file: CL2Types.CurrentFile = json.material_file;
          try {
            const result = bibtexParser.parse(file.content);
            bibtexItems = bibtexItems.concat(
              result.content.map((item) => item['internalKey']),
            );
          } catch {
            // ignore bibtex syntax errors
          }
        }),
      );

      Promise.all(promises).then(() => {
        store.dispatch({
          type: EditorConstants.SET_BIBTEX_ITEMS,
          bibtexItems,
        });
      });
    } catch (e) {}
  }

  @RequireEdit
  static openCountDialog(open: boolean) {
    store.dispatch({
      type: EditorConstants.CHANGE_COUNT_DIALOG,
      open,
    });
  }

  @RequireEdit
  static openShareDialog(open: boolean) {
    store.dispatch({
      type: EditorConstants.CHANGE_SHARE_DIALOG,
      open,
    });
  }

  @RequireEdit
  static createShare() {
    store.dispatch({
      type: EditorConstants.REQUEST_SHARE,
    });
    EditorWebAPIUtils.createProjectShare()
      .then((res) => {
        store.dispatch({
          type: EditorConstants.SUCCESS_SHARE,
          json: JSON.parse(res.text),
        });
      })
      .catch((res) => {
        store.dispatch({
          type: EditorConstants.FAILURE_SHARE,
        });
      });
  }

  @RequireEdit
  static updateShare(token, data) {
    store.dispatch({
      type: EditorConstants.REQUEST_SHARE,
    });
    EditorWebAPIUtils.updateProjectShare(token, data)
      .then((res) => {
        store.dispatch({
          type: EditorConstants.SUCCESS_SHARE,
          json: JSON.parse(res.text),
        });
      })
      .catch((res) => {
        store.dispatch({
          type: EditorConstants.FAILURE_SHARE,
        });
      });
  }

  @RequireRead
  static setPdfViewLoading(visible: boolean) {
    store.dispatch({
      type: EditorConstants.SHOW_PDF_VIEW_LOADING,
      visible,
    });
  }

  @RequireEdit
  static openSelectTargetDialog(files: CL2Types.ProjectFile[]) {
    store.dispatch({
      type: EditorConstants.OPEN_SELECT_TARGET_DIALOG,
      files,
    });
  }

  @RequireEdit
  static closeSelectTargetDialog() {
    store.dispatch({
      type: EditorConstants.CLOSE_SELECT_TARGET_DIALOG,
    });
  }

  @RequireEdit
  static invalidateFileCache() {
    store.dispatch({
      type: EditorConstants.REQUEST_INVALIDATE_FILE_CACHE,
    });
    EditorWebAPIUtils.invalidateFileCache()
      .then(() => {
        store.dispatch({
          type: EditorConstants.SUCCESS_INVALIDATE_FILE_CACHE,
        });
      })
      .catch(() => {
        store.dispatch({
          type: EditorConstants.FAILURE_INVALIDATE_FILE_CACHE,
        });
      });
  }

  @RequireEdit
  static openInvalidateFileCacheDialog(open: boolean) {
    store.dispatch({
      type: EditorConstants.OPEN_INVALIDATE_FILE_CACHE_DIALOG,
      open,
    });
  }

  @RequireEdit
  static closeSuccessInvalidateFileCacheDialog() {
    store.dispatch({
      type: EditorConstants.CLOSE_SUCCESS_INVALIDATE_FILE_CACHE_DIALOG,
    });
  }

  @RequireRead
  static onPdfLoaded() {
    store.dispatch({
      type: EditorConstants.PDF_LOADED,
    });
  }

  @RequireRead
  static onPdfLoadStarted() {
    store.dispatch({
      type: EditorConstants.PDF_LOAD_STARTED,
    });
  }

  @RequireEdit
  static changeMoveDialog(payload) {
    store.dispatch({
      type: EditorConstants.CHANGE_MOVE_DIALOG,
      payload,
    });
  }
}

export default EditorActions;
