import * as React from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Button,
  TextField,
  Icon,
  Checkbox,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import {
  MuiThemeProvider,
  createTheme,
  withStyles,
} from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as Colors from '@material-ui/core/colors';
import * as _ from 'lodash';
import ProjectsActions from '../../actions/ProjectsActions';
import { Root } from '../../constants/CommonConstants';
import Loading from '../Loading';
import { t } from '../../i18n';
import SortableTableHeaderColumn from './SortableTableHeaderColumn';
import { SortOrder, DateColumn } from '../../CL2Types';
import * as CL2Types from '../../CL2Types';

const TableHeaderColumn = TableCell;

interface TableRowColumnProps {
  style: React.CSSProperties;
}
class TableRowColumn extends React.Component<TableRowColumnProps, {}> {
  static _theme = createTheme({
    overrides: {
      MuiTableCell: { body: { fontWeight: 'inherit' } },
    },
  });

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <MuiThemeProvider theme={TableRowColumn._theme}>
        <TableCell {...this.props} />
      </MuiThemeProvider>
    );
  }
}

const TableRowEx1 = withStyles({
  root: {
    height: '56px',
  },
})(TableRow);
const TableRowEx2 = withStyles({
  root: {
    height: '48px',
    '&:hover': { background: Colors.grey['100'] },
  },
})(TableRow);

const Menu_ = withStyles({
  paper: {
    boxShadow:
      '0px 1px 6px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.12)',
  },
})(Menu);

const PaginationEx = withStyles({
  root: {
    display: 'inline-block',
  },
})(Pagination);

const CheckboxEx = withStyles({})(Checkbox);

interface MenuProps {
  open: boolean;
  anchorEl: HTMLElement;
  onClick: (e) => void;
  onClose: (e) => void;
}
class MenuEx extends React.Component<MenuProps, {}> {
  static _theme = createTheme({
    overrides: {
      MuiListItem: {
        root: {
          '&$focusVisible': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
        },
        button: {
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
        },
      },
      MuiTouchRipple: {
        rippleVisible: {
          // this is the default opacity value of Material-UI v0's FocusRipple
          opacity: 0.16,
        },
      },
    },
  });

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <MuiThemeProvider theme={MenuEx._theme}>
        <Menu_ {...this.props} />
      </MuiThemeProvider>
    );
  }
}

interface TextFieldProps {
  autoFocus: boolean;
  name: string;
  value: string;
  onChange: (e) => void;
  fullWidth: boolean;
  onClick: (e) => void;
  onFocus: (e) => void;
  onKeyDown: (e) => void;
  style: React.CSSProperties;
  inputProps: { style: React.CSSProperties };
}
class TextFieldEx extends React.Component<TextFieldProps, {}> {
  static _theme = createTheme({
    overrides: {
      MuiInput: {
        underline: {
          '&:after': { borderBottom: `2px solid ${Colors.cyan['500']}` },
        },
      },
    },
  });

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <MuiThemeProvider theme={TextFieldEx._theme}>
        <TextField {...this.props} />
      </MuiThemeProvider>
    );
  }
}

interface Props {
  renderContextMenu: (
    project: CL2Types.Project,
    pos?: { x: number; y: number },
  ) => (e) => void;
  elems: CL2Types.Projects;
  totalPages: number;
  currentPage: number;
  sortKey: string;
  sortOrder: SortOrder;
  loading: boolean;
  selected: number[];
}
interface State {
  editingProjectId: number;
  descriptionInput: string;
  hoveringRow: number;
  selectedDateColumn: number; // enum?
  dateSelectMenu: {
    anchorEl: HTMLElement;
  };
  pollingIntervalCancellors: Array<CL2Types.Runnable>;
}
class ProjectsContainer extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      editingProjectId: null,
      descriptionInput: null,
      hoveringRow: null,
      selectedDateColumn: DateColumn.UPDATED_AT,
      dateSelectMenu: {
        anchorEl: null,
      },
      pollingIntervalCancellors: [],
    };
    this._onCellHover = this._onCellHover.bind(this);
    this._onCellHoverExit = this._onCellHoverExit.bind(this);
    this._handleOpenDateSelectMenu = this._handleOpenDateSelectMenu.bind(this);
    this._handleChangeDateSelectMenu =
      this._handleChangeDateSelectMenu.bind(this);
    this._handleChangePage = this._handleChangePage.bind(this);
  }

  /*
  getTableState() {
    return this.refs.table.state;
  }
*/

  _onCellHover(rowNumber) {
    this.setState({ hoveringRow: rowNumber });
  }

  _onCellHoverExit() {
    this.setState({ hoveringRow: null });
  }

  _onClickDoneButton(e) {
    e.stopPropagation();
    const description = this.state.descriptionInput;
    const project = _.find(
      this.props.elems,
      (p) => p.id === this.state.editingProjectId,
    );
    if (project.description !== description) {
      ProjectsActions.updateProject(this.state.editingProjectId, {
        description,
      });
    }
    this.setState({ editingProjectId: null, descriptionInput: null });
  }

  _onClickClearButton(e) {
    e.stopPropagation();
    this.setState({ editingProjectId: null, descriptionInput: null });
  }

  _onClickEditButton(e) {
    const projectId = this.props.elems[this.state.hoveringRow].id;
    if (this.state.editingProjectId === projectId) {
      return;
    }
    const project = _.find(this.props.elems, (p) => p.id === projectId);
    this.setState({
      editingProjectId: projectId,
      descriptionInput: project.description,
    });
  }

  _onFocusDescriptionField(e) {
    const input = e.target;
    input.selectionStart = input.selectionEnd = input.value.length;
  }

  _onKeyDownDescriptionField(e) {
    switch (e.keyCode) {
      case 13: // Enter
        this._onClickDoneButton(e);
        break;
      case 27: // Esc
        this._onClickClearButton(e);
        break;
    }
  }

  /*
  componentDidUpdate(prevProps, prevState) {
    if (this.refs.description) {
      this.refs.description.focus()
    }
  }
*/

  _sortByColumn(column, e) {
    ProjectsActions.clearSelected();
    if (this.props.sortKey === column) {
      // change sort order
      if (this.props.sortOrder === SortOrder.ASC) {
        ProjectsActions.loadProjectsWithSortOrder(SortOrder.DESC);
      } else {
        ProjectsActions.loadProjectsWithSortOrder(SortOrder.ASC);
      }
    } else {
      ProjectsActions.loadProjectsWithSort(column, SortOrder.ASC);
    }
  }

  _handleChangeDateSelectMenu(e, value) {
    ProjectsActions.clearSelected();
    switch (value) {
      case DateColumn.UPDATED_AT:
        ProjectsActions.loadProjectsWithSort('updated_at', SortOrder.ASC);
        this.setState({
          selectedDateColumn: DateColumn.UPDATED_AT,
          dateSelectMenu: {
            anchorEl: null,
          },
        });
        break;
      case DateColumn.CREATED_AT:
        ProjectsActions.loadProjectsWithSort('created_at', SortOrder.ASC);
        this.setState({
          selectedDateColumn: DateColumn.CREATED_AT,
          dateSelectMenu: {
            anchorEl: null,
          },
        });
        break;
    }
  }

  _handleOpenDateSelectMenu(e) {
    this.setState({
      dateSelectMenu: {
        anchorEl: e.currentTarget,
      },
    });
  }

  _handleChangePage(e, newPage) {
    ProjectsActions.clearSelected();
    ProjectsActions.loadProjectsWithPage(newPage);
  }

  renderPagination() {
    return (
      <div style={{ textAlign: 'center' }}>
        <PaginationEx
          count={this.props.totalPages}
          page={this.props.currentPage}
          onChange={this._handleChangePage}
          boundaryCount={2}
        />
      </div>
    );
  }

  _handleSelectedChange(id) {
    if (this.props.selected.includes(id)) {
      ProjectsActions.deselectProject(id);
    } else {
      ProjectsActions.selectProject(id);
    }
  }

  render() {
    const { elems } = this.props;

    while (this.state.pollingIntervalCancellors.length > 0) {
      this.state.pollingIntervalCancellors.pop().run();
    }

    const tableHeaderColumnStyle: React.CSSProperties = {
      boxSizing: 'border-box',
      padding: '0px 24px',
    };
    let tableRowColumnStyle: React.CSSProperties = {
      boxSizing: 'border-box',
      paddingRight: '24px',
      paddingLeft: '24px',
      paddingTop: 0,
      paddingBottom: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    };

    const tableHeaderTextStyle: React.CSSProperties = {
      fontSize: '12px',
      color: Colors.grey['500'],
    };
    const tableHeaderTextStyleNormal: React.CSSProperties = _.defaults(
      { fontWeight: 'normal' },
      tableHeaderTextStyle,
    );
    const tableRowTextStyle: React.CSSProperties = {
      fontSize: '13px',
      fontWeight: 300,
    };

    const dateSelectMenuItemLabelStyle: React.CSSProperties = {
      fontSize: '16px',
    };

    return (
      <div style={{ position: 'relative', minHeight: '100px' }}>
        {this.props.loading ? <Loading /> : <div />}
        {this.renderPagination()}
        <Table
          style={{ tableLayout: 'fixed', height: '100%', whiteSpace: 'nowrap' }}
        >
          <TableHead>
            <TableRowEx1>
              <TableHeaderColumn
                style={_.defaults(
                  { width: '48px', padding: '0px 0px 0px 12px' },
                  tableHeaderColumnStyle,
                )}
              >
                {/* <CheckboxEx color="primary" /> */}
              </TableHeaderColumn>
              <SortableTableHeaderColumn
                style={tableHeaderColumnStyle}
                selectedSort={this.props.sortKey === 'title'}
                currentSortOrder={this.props.sortOrder}
                onClick={(e) => this._sortByColumn('title', e)}
              >
                <span
                  style={
                    this.props.sortKey === 'title'
                      ? tableHeaderTextStyle
                      : tableHeaderTextStyleNormal
                  }
                >
                  {t('view:projects.project_title')}
                </span>
              </SortableTableHeaderColumn>
              <TableHeaderColumn
                style={_.assign({ width: '30%' }, tableHeaderColumnStyle)}
              >
                <span style={tableHeaderTextStyleNormal}>
                  {t('view:projects.description')}
                </span>
              </TableHeaderColumn>
              <TableHeaderColumn
                style={_.assign({ width: '10%' }, tableHeaderColumnStyle)}
              >
                <span style={tableHeaderTextStyleNormal}>
                  {t('view:projects.owner')}
                </span>
              </TableHeaderColumn>
              <TableHeaderColumn
                style={_.assign({ width: '10%' }, tableHeaderColumnStyle)}
              >
                <span style={tableHeaderTextStyleNormal}>
                  {t('view:projects.size')}
                </span>
              </TableHeaderColumn>
              <SortableTableHeaderColumn
                style={{
                  ...tableHeaderColumnStyle,
                  position: 'relative',
                  width: '15%',
                }}
                selectedSort={
                  this.props.sortKey ===
                  (this.state.selectedDateColumn ? 'created_at' : 'updated_at')
                }
                currentSortOrder={this.props.sortOrder}
                onClick={(e) =>
                  this._sortByColumn(
                    this.state.selectedDateColumn ? 'created_at' : 'updated_at',
                    e,
                  )
                }
              >
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    this._handleOpenDateSelectMenu(e);
                  }}
                >
                  <span
                    style={
                      this.props.sortKey === 'created_at' ||
                      this.props.sortKey === 'updated_at'
                        ? tableHeaderTextStyle
                        : tableHeaderTextStyleNormal
                    }
                  >
                    {this.state.selectedDateColumn
                      ? t('view:projects.created_date')
                      : t('view:projects.last_modified')}
                  </span>
                  <Icon
                    style={{
                      position: 'absolute',
                      top: '-9px',
                      color: 'rgba(0, 0, 0, 0.87)',
                      fontSize: '24px',
                    }}
                  >
                    arrow_drop_down
                  </Icon>
                  <span style={{ display: 'inline-block', width: '24px' }} />
                </span>
                <MenuEx
                  open={Boolean(this.state.dateSelectMenu.anchorEl)}
                  anchorEl={this.state.dateSelectMenu.anchorEl}
                  onClick={function (e) {
                    e.stopPropagation();
                  }}
                  onClose={(e) => {
                    e.stopPropagation();
                    this.setState({ dateSelectMenu: { anchorEl: null } });
                  }}
                >
                  <MenuItem
                    focusRipple
                    onClick={(e) => {
                      e.stopPropagation();
                      this._handleChangeDateSelectMenu(
                        e,
                        DateColumn.UPDATED_AT,
                      );
                    }}
                  >
                    <span style={dateSelectMenuItemLabelStyle}>
                      {t('view:projects.last_modified')}
                    </span>
                  </MenuItem>
                  <MenuItem
                    focusRipple
                    onClick={(e) => {
                      e.stopPropagation();
                      this._handleChangeDateSelectMenu(
                        e,
                        DateColumn.CREATED_AT,
                      );
                    }}
                  >
                    <span style={dateSelectMenuItemLabelStyle}>
                      {t('view:projects.created_date')}
                    </span>
                  </MenuItem>
                </MenuEx>
              </SortableTableHeaderColumn>
              <TableHeaderColumn
                style={_.assign({ width: '80px' }, tableHeaderColumnStyle)}
              >
                <span style={tableHeaderTextStyleNormal}>
                  {t('view:projects.sync')}
                </span>
              </TableHeaderColumn>
              <TableHeaderColumn
                style={_.defaults(
                  { width: '48px', padding: '0px' },
                  tableHeaderColumnStyle,
                )}
              />
            </TableRowEx1>
          </TableHead>
          <TableBody>
            {/* ref='table' */}
            {elems.map((project, rowNumber) => {
              const displayBorder = rowNumber < elems.length - 1;

              const editing = this.state.editingProjectId === project.id;
              const annotation = project.has_import_error
                ? t('view.api.remote_storage.import_error')
                : t('view.api.remote_storage.importing');

              if (!project.is_completed && !project.has_import_error) {
                const cancellor_token: CL2Types.Runnable = { run: null };
                ProjectsActions.startPollingDropboxImportStatus(
                  project.id,
                  3000,
                  cancellor_token,
                )
                  .then((msg: string) => {
                    _.delay(() => {
                      ProjectsActions.openSnackbar(
                        t(msg, { target: project.title }),
                      );
                      ProjectsActions.loadProjects();
                    }, 3000);
                  })
                  .catch((msg) => {
                    if (msg) {
                      ProjectsActions.openSnackbar(t(msg));
                    }
                  });
                this.state.pollingIntervalCancellors.push(cancellor_token);
              }

              //                selectable={ project.is_completed }>

              if (!displayBorder) {
                tableRowColumnStyle = _.defaults(
                  { borderBottom: 'unset' },
                  tableRowColumnStyle,
                );
              }

              return (
                <TableRowEx2
                  key={project.id}
                  onMouseEnter={() => this._onCellHover(rowNumber)}
                  onMouseLeave={this._onCellHoverExit}
                  onContextMenu={this.props.renderContextMenu(project)}
                >
                  <TableRowColumn
                    style={{
                      ...tableRowColumnStyle,
                      padding: '0px 0px 0px 12px',
                    }}
                  >
                    <CheckboxEx
                      checked={this.props.selected.includes(project.id)}
                      onChange={() => this._handleSelectedChange(project.id)}
                      value={project.id}
                      color="primary"
                    />
                  </TableRowColumn>
                  <TableRowColumn style={tableRowColumnStyle}>
                    {project.is_completed ? (
                      <a
                        href={`${Root}/projects/${project.id}/edit`}
                        style={tableRowTextStyle}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        title={project.title}
                      >
                        {project.title}
                      </a>
                    ) : (
                      <span
                        style={_.assign({ color: 'gray' }, tableRowTextStyle)}
                      >{`${project.title} (${annotation})`}</span>
                    )}
                    {project.shares.length > 0 && (
                      <Icon
                        title={t('view:projects.shared')}
                        style={{
                          marginLeft: '0.5em',
                          verticalAlign: 'text-bottom',
                        }}
                      >
                        people_alt
                      </Icon>
                    )}
                  </TableRowColumn>
                  <TableRowColumn
                    style={_.assign(
                      {
                        position: 'relative',
                        paddingRight: editing ? 24 : 72,
                      },
                      tableRowColumnStyle,
                    )}
                  >
                    {editing ? (
                      <div style={{ position: 'relative' }}>
                        {/* ref="description" */}
                        <TextFieldEx
                          autoFocus
                          name="project_description"
                          value={this.state.descriptionInput}
                          onChange={(e) =>
                            this.setState({ descriptionInput: e.target.value })
                          }
                          fullWidth
                          onClick={(e) => e.stopPropagation()}
                          onFocus={this._onFocusDescriptionField.bind(this)}
                          onKeyDown={this._onKeyDownDescriptionField.bind(this)}
                          style={{ paddingRight: '96px' }}
                          inputProps={{ style: { fontSize: 'small' } }}
                        />
                        <Button
                          onClick={this._onClickDoneButton.bind(this)}
                          style={{
                            verticalAlign: 'bottom',
                            position: 'absolute',
                            right: 48,
                          }}
                        >
                          <Icon style={{ color: Colors.green['500'] }}>
                            done
                          </Icon>
                        </Button>
                        <Button
                          onClick={this._onClickClearButton.bind(this)}
                          style={{
                            verticalAlign: 'bottom',
                            position: 'absolute',
                            right: 0,
                          }}
                        >
                          <Icon style={{ color: Colors.red['500'] }}>
                            clear
                          </Icon>
                        </Button>
                      </div>
                    ) : (
                      <div style={{ display: 'inline', height: '48px' }}>
                        <span style={tableRowTextStyle}>
                          {project.description}
                        </span>
                      </div>
                    )}
                    {!editing && this.state.hoveringRow === rowNumber && (
                      <Button
                        onClick={this._onClickEditButton.bind(this)}
                        style={{
                          position: 'absolute',
                          top: '5px',
                          right: '16px',
                        }}
                      >
                        <Icon>create</Icon>
                      </Button>
                    )}
                  </TableRowColumn>
                  <TableRowColumn style={tableRowColumnStyle}>
                    <span style={tableRowTextStyle}>{project.owner.name}</span>
                  </TableRowColumn>
                  <TableRowColumn style={tableRowColumnStyle}>
                    <span style={tableRowTextStyle}>{project.size}</span>
                  </TableRowColumn>
                  <TableRowColumn style={tableRowColumnStyle}>
                    <span style={tableRowTextStyle}>
                      {this.state.selectedDateColumn
                        ? project.created_at
                        : project.updated_at}
                    </span>
                  </TableRowColumn>
                  {project.sync_target !== '' ? (
                    <TableRowColumn style={tableRowColumnStyle}>
                      <span
                        className="fa fa-dropbox"
                        style={{ fontSize: '24px' }}
                      />
                    </TableRowColumn>
                  ) : (
                    <TableRowColumn style={tableRowColumnStyle} />
                  )}
                  <TableRowColumn
                    style={{
                      ...tableRowColumnStyle,
                      padding: '0px',
                    }}
                  >
                    {(project.is_completed || project.has_import_error) && (
                      <Button
                        className="project-list-menu-icon"
                        style={{ minWidth: '48px', width: '48px' }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          const tgt = e.nativeEvent.target;
                          if (
                            'getBoundingClientRect' in tgt &&
                            typeof (tgt as { getBoundingClientRect: any })
                              .getBoundingClientRect === 'function'
                          ) {
                            const rect = (
                              tgt as Element
                            ).getBoundingClientRect();
                            const pos = {
                              x: rect.left + rect.width / 2,
                              y: rect.top + rect.height / 2,
                            };
                            this.props.renderContextMenu(project, pos)(e);
                          }
                        }}
                      >
                        <Icon
                          style={{
                            position: 'relative',
                            top: '1px',
                            fontSize: '24px',
                          }}
                        >
                          more_vert
                        </Icon>
                      </Button>
                    )}
                  </TableRowColumn>
                </TableRowEx2>
              );
            })}
          </TableBody>
        </Table>
        {this.renderPagination()}
        <div style={{ height: '48px' }} />
      </div>
    );
  }
}

export default ProjectsContainer;
