import { BlockInfo, EditorView, lineNumbers } from '@codemirror/view';
import { foldGutter } from '@codemirror/language';
import { lintGutter } from 'codemirror/lint';
import { windowHandlers } from 'codemirror/view/windowHandlers';

let dragStartLine: BlockInfo | null = null;
let requestId: number | null = null;
let mouseY: number = -1;

const updateSelection = (view: EditorView) => () => {
  if (dragStartLine) {
    const line = view.lineBlockAtHeight(mouseY - view.documentTop);
    if (line.from > dragStartLine.from) {
      view.dispatch({
        selection: {
          anchor: dragStartLine.from,
          head: Math.min(line.to + 1, view.state.doc.length),
        },
      });
    } else {
      view.dispatch({
        selection: {
          anchor: Math.min(dragStartLine.to + 1, view.state.doc.length),
          head: line.from,
        },
      });
    }
    requestId = window.requestAnimationFrame(updateSelection(view));
  }
};

const clear = () => {
  dragStartLine = null;
  if (requestId) {
    window.cancelAnimationFrame(requestId);
    requestId = null;
  }
};

function mousedownHandler(view, line, event: MouseEvent) {
  if (event.button !== 0) {
    return false;
  }
  mouseY = event.clientY;
  if (event.shiftKey) {
    if (line.from > view.state.selection.main.anchor) {
      const anchor = Math.min(
        view.state.selection.main.anchor,
        view.state.selection.main.head,
      );
      view.dispatch({
        selection: {
          anchor,
          head: Math.min(line.to + 1, view.state.doc.length),
        },
      });
    } else {
      const anchor = Math.max(
        view.state.selection.main.anchor,
        view.state.selection.main.head,
      );
      view.dispatch({
        selection: {
          anchor,
          head: line.from,
        },
      });
    }
  } else {
    dragStartLine = line;
  }
  updateSelection(view)();
  return true;
}

function mousedownHandlerForGutters(
  view: EditorView,
  line: BlockInfo,
  event: MouseEvent,
) {
  if (!(event.target instanceof HTMLElement)) {
    return false;
  }
  if (event.target.classList.contains('cm-gutter')) {
    return mousedownHandler(view, line, event);
  }
  return false;
}

function mousemoveHandler(view: EditorView, event: MouseEvent) {
  if (event.buttons !== 1) {
    clear();
  }
  if (dragStartLine) {
    mouseY = event.clientY;
  }
}

function mouseupHandler(view: EditorView, event: MouseEvent) {
  if (dragStartLine) {
    clear();
    view.focus();
  }
}

export const editorGutters = () => [
  lintGutter({
    domEventHandlers: {
      mousedown: mousedownHandlerForGutters,
    },
  }),
  lineNumbers({
    domEventHandlers: {
      mousedown: mousedownHandler,
    },
  }),
  windowHandlers({
    mousemove: mousemoveHandler,
    mouseup: mouseupHandler,
  }),
  foldGutter({
    openText: '▾',
    closedText: '▸',
    domEventHandlers: {
      mousedown: mousedownHandlerForGutters,
    },
  }),
];
