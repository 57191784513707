import { parseSyncTexGz, syncTexJsForward, syncTexJsBackward } from './synctex';
import EditorWebAPIUtils from '../EditorWebAPIUtils';

export default class CLSynctexManager {
  private synctexObject;

  constructor() {
    this.synctexObject = null;
  }

  public loadSynctexObject(url: string) {
    EditorWebAPIUtils.loadSynctexObject(url)
      .then((res) => {
        parseSyncTexGz(res.body).then((obj) => {
          this.synctexObject = obj;
        });
      })
      .catch(() => {
        // no synctex
      });
  }

  public synctexForward(line, input) {
    if (this.synctexObject === null) {
      throw new Error('synctexObject is null');
    }
    return syncTexJsForward(line, input, this.synctexObject);
  }

  public synctexBackward(page, x, y) {
    if (this.synctexObject === null) {
      throw new Error('synctexObject is null');
    }
    return syncTexJsBackward(page, x, y, this.synctexObject);
  }

  public parseSyncTexGz(synctexGz) {
    return parseSyncTexGz(synctexGz);
  }
}
