import * as React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { t } from '../../i18n';
import Loading from '../Loading';

export function TemplatesDialog({ open, onClose, loaded, tags }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ style: { width: '75%', maxWidth: '576px' } }}
    >
      <DialogTitle
        key="title"
        disableTypography
        children={
          <h3
            style={{
              margin: 'unset',
              color: 'rgba(0, 0, 0, 0.87)',
              fontSize: '22px',
              fontWeight: 400,
              lineHeight: '32px',
            }}
          >
            {t('view:projects.load_template')}
          </h3>
        }
      />
      {!loaded ? (
        <DialogContent key="content_loading">
          <Loading />
        </DialogContent>
      ) : (
        <DialogContent key="content">
          <List>
            <ListItem button component="a" href="/templates">
              <ListItemText>{t('view:templates.all_templates')}</ListItemText>
            </ListItem>
            {tags.map((tag) => (
              <ListItem key={tag.path} button component="a" href={tag.path}>
                <ListItemText>{tag.label}</ListItemText>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      )}
    </Dialog>
  );
}
