import * as React from 'react';
// import { RefreshIndicator } from 'material-ui';
import { CircularProgress } from '@material-ui/core';

class Loading extends React.Component {
  render() {
    const style: React.CSSProperties = {
      position: 'absolute',
      height: '100%',
      width: '100%',
      zIndex: 9,
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,0.2)',
    };
    const pstyle: React.CSSProperties = {
      position: 'relative',
      margin: 'auto',
      display: 'block',
      textAlign: 'center',
      width: '40px',
      height: '40px',
      top: '50%',
      left: 0,
    };
    return (
      // <RefreshIndicator size={40} left={0} top={-20} status="loading" />
      <div style={style}>
        <div style={pstyle}>
          <CircularProgress />
        </div>
      </div>
    );
  }
}

export default Loading;
