function parseColor(color: string) {
  const m = color.match(/^#([0-9a-f]{6})$/i)[1];
  if (m) {
    return [
      parseInt(m.substring(0, 2), 16),
      parseInt(m.substring(2, 4), 16),
      parseInt(m.substring(4, 6), 16),
    ];
  }
  // eslint-disable-next-line no-console
  console.warn(`Wrong format: ${color}`);
  return [];
}

/**
 * Returns transparent color that becomes `color` after being layered on `baseColor`
 */
export const activeLineColor = (color: string, baseColor: string): string => {
  const colorRGB = parseColor(color);
  const baseColorRGB = parseColor(baseColor);
  const diff = colorRGB.map((c, i) => c - baseColorRGB[i]);
  const alpha = Math.max(...diff, 0x33);
  const lineColor =
    diff
      .map((c, i) => (c * 0xff) / alpha + baseColorRGB[i])
      .map((c) => `0${Math.floor(c).toString(16)}`.slice(-2))
      .join('') + `0${alpha.toString(16)}`.slice(-2);
  return `#${lineColor}`;
};
