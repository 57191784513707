import { Component, ReactElement } from 'react';
import * as PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';

interface Prop {
  message: string;
  children?: ReactElement;
}
interface State {
  open: boolean;
}
class PopUpFlash extends Component<Prop, State> {
  constructor(props) {
    super(props);
    this.state = { open: true };
  }

  render() {
    const { message, ...other } = this.props;
    return (
      <Snackbar
        message={<span style={{ fontSize: '14px' }}>{message}</span>}
        {...other}
        open={this.state.open}
        onClose={() => this.setState({ open: false })}
        autoHideDuration={3000}
      />
    );
  }

  componentDidMount() {}
}

export default PopUpFlash;
