import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TexCount } from 'react/CL2Types';
import { t } from '../../../i18n';

interface Props {
  open: boolean;
  onClose: (buttonClicked: boolean) => void;
  texcount?: TexCount;
}

const useStyles = makeStyles({
  button: {
    fontSize: '1em',
    marginRight: '.5em',
  },
});

function CountDialog(props: Props) {
  const classes = useStyles();

  const onClose = () => {
    props.onClose(true);
  };

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      PaperProps={{ style: { width: '75%', maxWidth: '576px' } }}
    >
      <DialogTitle
        disableTypography
        children={<h3>{t('view:editor.count_dialog.word_counts')}</h3>}
      />
      <DialogContent>
        {props.texcount ? (
          <ul>
            <li>
              {t('view:editor.count_dialog.total_words')}:{' '}
              {props.texcount.total_words}
              <ul>
                <li>
                  {t('view:editor.count_dialog.body_words')}:{' '}
                  {props.texcount.body_words}
                </li>
              </ul>
            </li>
            <li>
              {t('view:editor.count_dialog.total_chars')}:{' '}
              {props.texcount.total_chars}
              <ul>
                <li>
                  {t('view:editor.count_dialog.body_chars')}:{' '}
                  {props.texcount.body_chars}
                </li>
              </ul>
            </li>
          </ul>
        ) : (
          t('view:editor.count_dialog.not_ready')
        )}
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={onClose}
          color="primary"
          disableTouchRipple
        >
          {t('view:editor.done')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CountDialog;
