import { EditorView, ViewPlugin, ViewUpdate } from '@codemirror/view';
import { Facet } from '@codemirror/state';

type Handlers = { [event: string]: (view: EditorView, event: Event) => void };

const registeredHandlers = Facet.define<Required<Handlers>>();

const windowHandlerPlugin = ViewPlugin.fromClass(
  class {
    handlers: { [prop: string]: (event: Event) => void }[];

    constructor(readonly view: EditorView) {
      this.assignHandlers(view.state.facet(registeredHandlers));
    }

    update(update: ViewUpdate) {
      const prev = update.startState.facet(registeredHandlers);
      const current = update.state.facet(registeredHandlers);
      if (prev !== current) {
        this.removeHandlers();
        this.assignHandlers(current);
      }
    }

    destroy() {
      this.removeHandlers();
    }

    removeHandlers() {
      this.handlers.forEach((handlers) => {
        for (const prop in handlers) {
          window.removeEventListener(prop, handlers[prop]);
        }
      });
    }

    assignHandlers(handlersFacet) {
      this.handlers = [];
      handlersFacet.forEach((handlers) => {
        for (const prop in handlers) {
          const handler = (event: Event) => {
            handlers[prop](this.view, event);
          };
          window.addEventListener(prop, handler);
          this.handlers.push({ [prop]: handler });
        }
      });
    }
  },
);

export const windowHandlers = (handlers: Handlers) => [
  registeredHandlers.of(handlers),
  windowHandlerPlugin,
];
