import * as React from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  Icon,
  Box,
} from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import * as Colors from '@material-ui/core/colors';
import * as _ from 'lodash';
import Loading from '../../Loading';
import Pagination from '../../Pagination';
import { t } from '../../../i18n';
import * as CL2Types from '../../../CL2Types';

const TableRowColumn = TableCell;
const TableHeadColumn = TableCell;

interface Props {
  open: boolean;
  versions: Array<CL2Types.RestoreVersion>;
  currentVersion: CL2Types.CurrentVersion;
  fileName: string;
  waiting: boolean;
  loading: boolean;
  restoring: boolean;
  onRequestExec: (version_id: string) => void;
  onDownloadExec: (version_id: string) => void;
  onClose: () => void;
}
interface State {
  pagination: {
    selectedPage: number;
    numDisplayItems?: number;
  };
  messageDialog: {
    open: boolean;
  };
  confirmDialog: {
    open: boolean;
    last_modified?: string;
    size?: number;
  };
  selectedRow: number;
}

class FileRestoreDialog extends React.Component<Props, State> {
  static inactiveColor = Colors.grey['500'];

  static _mainDialogTheme = (outerTheme) => {
    const margin = 64;
    return createTheme(
      {
        overrides: {
          MuiDialog: {
            paper: { margin: `${String(margin)}px` },
            paperScrollPaper: {
              maxHeight: `calc(100% - ${String(2 * margin)}px)`,
            },
          },
          MuiButton: {
            textPrimary: { color: Colors.cyan['500'] },
            textSecondary: { color: Colors.pink.A200 },
          },
          MuiTable: {
            root: {
              tableLayout: 'fixed',
            },
          },
          MuiTableCell: {
            root: {
              padding: '0px 24px',
            },
            head: {
              fontSize: '12px',
              backgroundColor: outerTheme.palette.background.paper,
              color: FileRestoreDialog.inactiveColor,
              fontWeight: 'normal',
            },
            body: {
              fontSize: '13px',
              fontWeight: 300,
            },
          },
          MuiIconButton: {
            root: {
              padding: '12px 12px 11px',
            },
          },
          MuiCheckbox: {
            root: {
              '&$checked': {
                color: Colors.cyan['500'],
              },
            },
            checked: {},
          },
        },
      },
      outerTheme,
    );
  };

  private itemsBegin = 0;

  private numDisplayItems = 10;

  private numTotalPages: number;

  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        selectedPage: 1,
      },
      messageDialog: {
        open: false,
      },
      confirmDialog: {
        open: false,
        last_modified: '',
        size: 0,
      },
      selectedRow: -1,
    };
    this.numTotalPages =
      (this.props.versions.length - 1) / this.state.pagination.numDisplayItems +
      1;
  }

  render() {
    const buttonStyle: React.CSSProperties = { margin: 0, minWidth: '88px' };
    const buttonLabelStyle: React.CSSProperties = { fontSize: '14px' };
    const currentVersionStyle: React.CSSProperties = {
      color: FileRestoreDialog.inactiveColor,
    };
    const actions = [
      <Button
        key="cancel"
        children={
          <span style={buttonLabelStyle}>{t('view:editor.cancel')}</span>
        }
        color="secondary"
        disabled={this.props.restoring}
        onClick={this._onCloseDialog}
        style={buttonStyle}
      />,
      <Button
        key="restore"
        children={
          <span style={buttonLabelStyle}>{t('view:editor.restore')}</span>
        }
        color="primary"
        disabled={this.props.restoring}
        onClick={this.onConfirmRestore}
        style={buttonStyle}
      />,
    ];

    const messageActions = [
      <Button
        key="message_ok"
        children={
          <span style={buttonLabelStyle}>
            {t('view:editor.restore_dialog.ok')}
          </span>
        }
        color="secondary"
        onClick={this.onCloseMessageDialog}
        style={_.defaults({ position: 'relative', top: '1px' }, buttonStyle)}
      />,
    ];

    const confirmActions = [
      <Button
        key="confirm_cancel"
        children={
          <span style={buttonLabelStyle}>{t('view:editor.cancel')}</span>
        }
        color="secondary"
        onClick={this.onCloseConfirmDialog}
        style={_.defaults({ position: 'relative', top: '1px' }, buttonStyle)}
      />,
      <Button
        key="confirm_restore"
        children={
          <span style={buttonLabelStyle}>{t('view:editor.restore')}</span>
        }
        color="primary"
        onClick={this._onRestore}
        style={_.defaults({ position: 'relative', top: '1px' }, buttonStyle)}
      />,
    ];

    const dialogPaperProps: { style: React.CSSProperties } = {
      style: { width: '75%', maxWidth: '576px' },
    };
    const dialogTitleStyle: React.CSSProperties = { padding: '24px 24px 20px' };
    const dialogCautionStyle: React.CSSProperties = {
      marginLeft: '20px',
      marginBottom: '20px',
      fontSize: '16px',
      fontFamily: 'Roboto, sans-serif',
    };
    const dialogTitleLabelStyle: React.CSSProperties = {
      margin: 0,
      fontSize: '22px',
      fontWeight: 400,
      lineHeight: '32px',
    };
    const dialogContentSpanStyle: React.CSSProperties = {
      fontSize: '16px',
      fontFamily: 'Roboto, sans-serif',
    };
    const dialogActionsStyle: React.CSSProperties = { margin: '8px' };
    const checkboxStyle: React.CSSProperties = {
      position: 'relative',
      right: '12px',
    };

    const p = this.state.pagination;
    this.itemsBegin = (p.selectedPage - 1) * this.numDisplayItems;
    this.numTotalPages = Math.ceil(
      this.props.versions.length / this.numDisplayItems,
    );
    return (
      <MuiThemeProvider theme={FileRestoreDialog._mainDialogTheme}>
        <Dialog
          open={this.props.open}
          onClose={this._onCloseDialog}
          PaperProps={dialogPaperProps}
        >
          <DialogTitle
            key="title"
            disableTypography
            style={dialogTitleStyle}
            children={
              <h3 style={dialogTitleLabelStyle}>
                {t('view:editor.restore_dialog.file_restore')}
              </h3>
            }
          />
          <div style={dialogCautionStyle}>
            {t('view:editor.restore_dialog.recoverable_period')}
          </div>
          <div style={dialogCautionStyle}>
            {t('view:editor.restore_dialog.asterisk_version')}
          </div>

          {this.props.loading || this.props.restoring ? (
            <DialogContent key="content_loading">
              <Loading />
            </DialogContent>
          ) : (
            <DialogContent
              key="content"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <div style={{ overflow: 'auto', maxHeight: '362px' }}>
                <Table id="files_table_in_file_restore_dialog">
                  <TableHead>
                    <TableRow>
                      <TableHeadColumn style={{ width: '48px' }}>
                        <span />
                      </TableHeadColumn>
                      <TableHeadColumn style={{ width: '50%' }}>
                        <span>{t('view:editor.restore_dialog.save_date')}</span>
                      </TableHeadColumn>
                      <TableHeadColumn style={{ width: '25%' }}>
                        <span>{t('view:editor.restore_dialog.size')}</span>
                      </TableHeadColumn>
                      <TableHeadColumn style={{ width: '25%' }}>
                        <span>{t('view:editor.restore_dialog.download')}</span>
                      </TableHeadColumn>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ overflow: 'visible' }}>
                    {(() => {
                      let rowCounter = -1;
                      return this.props.versions
                        .filter(
                          (_, index) =>
                            this.itemsBegin <= index &&
                            index < this.itemsBegin + this.numDisplayItems,
                        )
                        .map((version) => {
                          rowCounter += 1;
                          const _rowCounter = rowCounter;
                          const v = this.props.versions[this.state.selectedRow];
                          const { version_id } = version;
                          const isSelected = !!(
                            v && v.version_id === version_id
                          );
                          const isCurrentVersion =
                            version_id === this.props.currentVersion.version_id;
                          return (
                            <TableRow
                              key={version_id}
                              hover
                              selected={isSelected}
                              style={{ overflow: 'visible' }}
                              onClick={
                                isCurrentVersion
                                  ? undefined
                                  : isSelected
                                    ? () => this.setState({ selectedRow: -1 })
                                    : () =>
                                        this.setState({
                                          selectedRow:
                                            this.itemsBegin + _rowCounter,
                                        })
                              }
                            >
                              <TableRowColumn>
                                <Checkbox
                                  disabled={isCurrentVersion}
                                  checked={isSelected}
                                  color="default"
                                  style={checkboxStyle}
                                />
                              </TableRowColumn>
                              <TableRowColumn>
                                <span>
                                  {isCurrentVersion && '*'}
                                  {version.last_modified}
                                </span>
                              </TableRowColumn>
                              <TableRowColumn>
                                <span>{version.size}</span>
                              </TableRowColumn>
                              <TableRowColumn>
                                <Button
                                  onClick={() => this._onDownload(version_id)}
                                  style={{
                                    minWidth: '88px',
                                    padding: '1px 16px 10px',
                                  }}
                                >
                                  <Icon>get_app</Icon>
                                </Button>
                              </TableRowColumn>
                            </TableRow>
                          );
                        });
                    })()}
                  </TableBody>
                </Table>
              </div>
              <Pagination
                onPrevious={this.onPreviousPage}
                onNext={this.onNextPage}
                selected={p.selectedPage}
                totalPages={this.numTotalPages}
              />
            </DialogContent>
          )}
          <DialogActions
            key="actions"
            children={actions}
            style={dialogActionsStyle}
          />
        </Dialog>
        <Dialog
          onClose={this.onCloseMessageDialog}
          open={this.state.messageDialog.open}
          PaperProps={dialogPaperProps}
        >
          <DialogTitle
            key="title"
            disableTypography
            style={dialogTitleStyle}
            children={
              <h3 style={dialogTitleLabelStyle}>{t('view:editor.restore')}</h3>
            }
          />
          <DialogContent>
            <Box
              component="span"
              color="text.secondary"
              style={dialogContentSpanStyle}
            >
              {t('view:editor.restore_dialog.select_version')}
            </Box>
          </DialogContent>
          <DialogActions
            key="actions"
            children={messageActions}
            style={dialogActionsStyle}
          />
        </Dialog>
        <Dialog
          onClose={this.onCloseConfirmDialog}
          open={this.state.confirmDialog.open}
          PaperProps={dialogPaperProps}
        >
          <DialogTitle
            key="title"
            disableTypography
            style={dialogTitleStyle}
            children={
              <h3 style={dialogTitleLabelStyle}>{t('view:editor.restore')}</h3>
            }
          />
          <DialogContent>
            <Box
              component="span"
              color="text.secondary"
              style={dialogContentSpanStyle}
            >
              {t('view:editor.restore_dialog.confirm_version', {
                target: this.props.fileName,
              })}
              <br />
              {`${t('view:editor.restore_dialog.last_modified')}: ${this.state.confirmDialog.last_modified} / ${t('view:editor.restore_dialog.size')}: ${this.state.confirmDialog.size}`}
            </Box>
          </DialogContent>
          <DialogActions
            key="actions"
            children={confirmActions}
            style={dialogActionsStyle}
          />
        </Dialog>
      </MuiThemeProvider>
    );
  }

  openMessage() {
    this.setState({ messageDialog: { open: true } });
  }

  private onCloseMessageDialog = () => {
    this.setState({ messageDialog: { open: false } });
  };

  private onConfirmRestore = () => {
    if (this.state.selectedRow < 0) {
      this.openMessage();
    } else {
      const v = this.props.versions[this.state.selectedRow];
      this.setState({
        confirmDialog: {
          open: true,
          last_modified: v.last_modified,
          size: v.size,
        },
      });
    }
  };

  private onCloseConfirmDialog = () => {
    this.setState({ confirmDialog: { open: false } });
  };

  private onPreviousPage = () => {
    if (this.state.pagination.selectedPage === 1) return;
    this.setState({
      pagination: { selectedPage: this.state.pagination.selectedPage - 1 },
    });
  };

  private onNextPage = () => {
    if (this.state.pagination.selectedPage >= this.numTotalPages) return;
    this.setState({
      pagination: { selectedPage: this.state.pagination.selectedPage + 1 },
    });
  };

  private _onCloseDialog = () => {
    this.setState({
      selectedRow: -1,
    });
    this.props.onClose();
  };

  private _onRestore = () => {
    this.onCloseConfirmDialog();
    const selectedVersion =
      this.props.versions[this.state.selectedRow].version_id;
    this.props.onRequestExec(selectedVersion);
    this.setState({
      selectedRow: -1,
    });
  };

  private _onDownload = (version) => {
    this.props.onDownloadExec(version);
  };
}

export default FileRestoreDialog;
