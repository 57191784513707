import * as React from 'react';

import { TextField } from '@material-ui/core';
import * as Colors from '@material-ui/core/colors';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import LoadingDialog from '../LoadingDialog';
import { t } from '../../i18n';

interface InputRef {
  value: string;
}

interface TextFieldProps {
  autoFocus: boolean;
  inputRef: (input: InputRef) => void;
  label: string;
  onKeyDown: (e) => void;
  helperText: string;
  error: boolean;
  onChange: (e) => void;
  type: 'text';
  defaultValue: string;
  style: React.CSSProperties;
}
class TextFieldEx extends React.Component<TextFieldProps, {}> {
  static _textFieldTheme = createTheme({
    overrides: {
      MuiFormLabel: {
        root: {
          fontSize: '16px',
          color: 'rgba(0, 0, 0, 0.3)',
          '&$focused': { color: Colors.cyan['500'] },
        },
      },
      MuiInput: {
        underline: {
          '&:before': {
            borderBottom: `1px solid ${Colors.grey['300']}`,
          },
          '&:after': {
            borderBottom: `2px solid ${Colors.cyan['500']}`,
          },
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: `1px solid ${Colors.grey['300']}`,
          },
        },
      },
      MuiInputBase: {
        root: { fontSize: '16px' },
        input: { padding: '9px 0 4px' },
      },
      MuiInputLabel: { formControl: { top: '1px' } },
      MuiFormHelperText: {
        root: {
          fontSize: '12px',
          marginTop: '7px',
          marginBottom: '11px',
        },
      },
    },
  });

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <MuiThemeProvider theme={TextFieldEx._textFieldTheme}>
        <TextField {...this.props} />
      </MuiThemeProvider>
    );
  }
}

interface Props {
  dialogRef: (dialog: ProjectNameDialog) => void;
  open: boolean;
  nameLabel: string;
  waiting: boolean;
  onRequestExec: () => void;
  onClose: (buttonClicked: boolean) => void;
  execLabel: string;
  title: string;
  defaultValue: string;
}
interface State {
  errorText: string;
  submittable: boolean;
}
class ProjectNameDialog extends React.Component<Props, State> {
  static defaultProps = {
    defaultValue: '',
  };

  private projectNameField: InputRef = null;

  constructor(props) {
    super(props);
    props.dialogRef(this);
    this.state = {
      errorText: '',
      submittable: false,
    };

    this._onRequestExec = this._onRequestExec.bind(this);
    this.onClose = this.onClose.bind(this);
    this._validateText = this._validateText.bind(this);
  }

  // 空文字列かどうか
  static _isEmpty(str) {
    return str.length === 0;
  }

  // 制御文字を含んでいるか?
  static _containsControls(str) {
    return /[\x00-\x1F\x7F]/.test(str);
  }

  // 使用できない文字が含まれているか
  static _containsSpecialCharacters(str) {
    return /["#$%&'*,\/:;<=>?\[\\\]^`{|}~]/.test(str);
  }

  // ドットではじまるか
  static _startsWithDot(str) {
    return /^\./.test(str);
  }

  // 空白で始まるか
  static _startsWithWhitespace(str) {
    return /^\s/.test(str);
  }

  // 空白で終わるか
  static _endsWithWhitespace(str) {
    return /\s$/.test(str);
  }

  _validateText(event) {
    // 入力されたテキストがvalidationを通るか, 通らなかったらそのエラーの説明をセットする
    // TODO: サーバーサイドのvalidationと異なる(Windows予約ファイル名の除外などがフロント側では実装されていない). 将来合わせるべき
    const text = event.target.value;
    if (ProjectNameDialog._isEmpty(text)) {
      this.setState({ errorText: '', submittable: false });
    } else if (ProjectNameDialog._containsControls(text)) {
      this.setState({
        errorText: t('view:editor.invalid_control_filename'),
        submittable: false,
      });
    } else if (ProjectNameDialog._containsSpecialCharacters(text)) {
      this.setState({
        errorText: t('view:editor.invalid_special_char_filename'),
        submittable: false,
      });
    } else if (ProjectNameDialog._startsWithDot(text)) {
      this.setState({
        errorText: t('view:editor.invalid_starts_with_dot'),
        submittable: false,
      });
    } else if (ProjectNameDialog._startsWithWhitespace(text)) {
      this.setState({
        errorText: t('view:editor.invalid_starts_with_space'),
        submittable: false,
      });
    } else if (ProjectNameDialog._endsWithWhitespace(text)) {
      this.setState({
        errorText: t('view:editor.invalid_ends_with_space'),
        submittable: false,
      });
    } else {
      this.setState({ errorText: '', submittable: true });
    }
  }

  getProjectName() {
    return this.projectNameField && this.projectNameField.value;
  }

  _onRequestExec() {
    if (this.state.submittable) {
      this.setState({ submittable: false });
      this.props.onRequestExec();
    }
  }

  onClose() {
    this.setState({ errorText: '', submittable: false });
    this.props.onClose(true);
  }

  render() {
    return (
      <LoadingDialog
        open={this.props.open}
        onClose={this.onClose}
        onRequestExec={this._onRequestExec}
        waiting={this.props.waiting}
        execLabel={this.props.execLabel}
        title={this.props.title}
        disabled={!this.state.submittable}
        PaperProps={{ style: { width: '75%', maxWidth: '576px' } }}
      >
        <TextFieldEx
          autoFocus
          inputRef={(input) => {
            this.projectNameField = input;
          }}
          label={this.props.nameLabel}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter' && ev.keyCode === 13) {
              ev.stopPropagation();
              this._onRequestExec();
            }
          }}
          helperText={this.state.errorText}
          error={this.state.errorText !== ''}
          onChange={this._validateText}
          type="text"
          defaultValue={this.props.defaultValue}
          style={{ width: '256px' }}
        />
      </LoadingDialog>
    );
  }
}

export default ProjectNameDialog;
