import { lintGutter as lint } from '@codemirror/lint';
import { BlockInfo, EditorView, gutter } from '@codemirror/view';
import { Extension, StateField } from '@codemirror/state';

export const lintGutter = (
  config: {
    domEventHandlers?: {
      [event: string]: (
        view: EditorView,
        line: BlockInfo,
        event: Event,
      ) => boolean;
    };
  } = {},
) => {
  const origin = lint() as Extension[];
  const lintGutterMarker = origin[1];
  if (!(lintGutterMarker instanceof StateField)) {
    // eslint-disable-next-line no-console
    console.warn('Failed to override lint gutter');
    return origin;
  }
  const lintGutterExtension = gutter({
    class: 'cm-gutter-lint',
    markers: (view) => view.state.field(lintGutterMarker),
    domEventHandlers: config.domEventHandlers,
  });

  return [...origin.slice(0, 2), lintGutterExtension, ...origin.slice(3)];
};
