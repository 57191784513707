enum ProjectsConstants {
  LOAD_PROJECTS = 'LOAD_PROJECTS',
  REQUEST_UPDATE_PROJECT = 'REQUEST_UPDATE_PROJECT',
  RECEIVE_LOAD_PROJECTS = 'RECEIVE_LOAD_PROJECTS',
  RECEIVE_UPDATE_PROJECT = 'RECEIVE_UPDATE_PROJECT',
  NARROW_PROJECTS = 'NARROW_PROJECTS',
  NARROW_TEMPLATES = 'NARROW_TEMPLATES',
  CLEAR_PROJECT_QUERIES = 'CLEAR_PROJECT_QUERIES',
  CLEAR_TEMPLATE_QUERIES = 'CLEAR_TEMPLATE_QUERIES',
  CREATE_PROJECT = 'CREATE_PROJECT',
  RECEIVE_CREATE_PROJECT = 'RECEIVE_CREATE_PROJECT',
  DELETE_PROJECT = 'DELETE_PROJECT',
  RECEIVE_DELETE_PROJECT = 'RECEIVE_DELETE_PROJECT',
  DELETE_PROJECTS = 'DELETE_PROJECTS',
  RECEIVE_DELETE_PROJECTS = 'RECEIVE_DELETE_PROJECTS',
  UNSYNC_PROJECT = 'UNSYNC_PROJECT',
  RECEIVE_UNSYNC_PROJECT = 'RECEIVE_UNSYNC_PROJECT',
  RECEIVE_TEMPLATE_TAGS = 'RECEIVE_TEMPLATE_TAGS',
  LOAD_IMPORT_LIST = 'LOAD_IMPORT_LIST',
  RECEIVE_IMPORT_LIST = 'RECEIVE_IMPORT_LIST',
  START_IMPORT = 'START_IMPORT',
  CANCEL_IMPORT = 'CANCEL_IMPORT',
  FINISH_IMPORT = 'FINISH_IMPORT',
  OPEN_SNACKBAR = 'OPEN_SNACKBAR',
  CLOSE_SNACKBAR = 'CLOSE_SNACKBAR',
  API_ERROR = 'API_ERROR',
  CLOSE_ERROR = 'CLOSE_ERROR',
  RECEIVE_PROJECT_NUM = 'RECEIVE_PROJECT_NUM',
  UPLOAD_ZIP = 'UPLOAD_ZIP',
  CLEAR_ZIP = 'CLEAR_ZIP',
  RELOAD_PROJECT = 'RELOAD_PROJECT',
  SELECT_PROJECT = 'SELECT_PROJECT',
  DESELECT_PROJECT = 'DESELECT_PROJECT',
  CLEAR_SELECTED = 'CLEAR_SELECTED',
}

export default ProjectsConstants;
