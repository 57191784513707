import { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Dialog, DialogContent } from '@material-ui/core';

interface Props {
  message: string | null;
}
interface State {
  open: boolean;
}

class Notification extends Component<Props, State> {
  static propTypes = {
    backgroundColor: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { open: this.props.message !== null };
  }

  render() {
    return (
      <Dialog
        open={this.state.open}
        style={{ zIndex: 1001 }}
        onClose={() => this.setState({ open: false })}
      >
        <DialogContent style={{ width: '400px' }}>
          <span
            style={{
              color: 'rgba(0, 0, 0, 0.6)',
              fontFamily: 'Roboto, sans-serif',
              fontSize: '16px',
            }}
          >
            {this.props.message}
          </span>
        </DialogContent>
      </Dialog>
    );
  }
}

export default Notification;
