import i18next, * as i18n from 'i18next';

i18n.init({
  // resGetPath: '/locales/translate.json',
  interpolation: {
    prefix: '%{',
    suffix: '}',
  },
  // useCookie: false,
  resources: window['translations'],
  lng: window['locale'],
});

function camelize(str: string): string {
  return str.replace(/(^.|[\_\-][a-z])/g, ($1) =>
    $1.toUpperCase().replace(/[_-]/, ' '),
  );
}

export function t(key: string, options?: any): string {
  if (typeof options === 'undefined') options = {};
  return i18n.t(
    key,
    $.extend({ defaultValue: camelize(key.split(/:|\./).pop()) }, options),
  );
}

export function init(callback) {}

export function getCodemirrorPhrases() {
  const phrases = {};
  // Return type is not really defined well so cast to correct type
  Object.keys(
    (
      i18next.getDataByLanguage(i18next.language)?.view?.editor as any as {
        [key: string]: string;
      }
    )?.codemirror || {},
  ).forEach((key) => (phrases[key] = t(`view:editor.codemirror.${key}`)));
  return phrases;
}
