import * as React from 'react';
import { IconButton } from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

interface IconButtonProps {
  disabled: boolean;
  key: string;
  onClick: () => void;
  children: JSX.Element;
  style: React.CSSProperties;
  disableRipple: boolean;
}
export default class IconButtonEx extends React.Component<IconButtonProps, {}> {
  static _theme = (outerTheme) =>
    createTheme(
      {
        overrides: {
          MuiIconButton: {
            root: {
              paddingTop: '9px',
              color: outerTheme.palette.text.primary,
              '&$disabled': {
                // color: 'rgba(0, 0, 0, 0.3)',
              },
            },
          },
        },
      },
      outerTheme,
    );

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <MuiThemeProvider theme={IconButtonEx._theme}>
        <IconButton {...this.props} />
      </MuiThemeProvider>
    );
  }
}
