import { useCallback, useRef } from 'react';
import {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  A11y,
} from 'swiper/modules';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { withErrorBoundaryFallback } from './error/withErrorBoundaryFallback';

export interface Props {
  promotions?: {
    link: string;
    image: string;
    alt: string;
    sponsored?: boolean;
  }[];
  size?: 'sm';
}

const useStyles = makeStyles<Theme, { size?: 'sm' }>({
  wrapper: ({ size }) => ({
    display: 'inline-block',
    maxWidth: size === 'sm' ? '388px' : '508px',
    width: '100%',
  }),
  swiper: {
    '& $navButton': {
      display: 'none',
    },
    '&:hover': {
      '& $navButton': {
        display: 'inline-flex',
      },
    },
    '--swiper-navigation-color': '#fff',
  },
  navButton: {
    position: 'absolute',
    top: '50%',
    width: 'var(--swiper-navigation-size)',
    height: 'var(--swiper-navigation-size)',
    marginTop: 'calc(0px - (var(--swiper-navigation-size) / 2))',
    zIndex: 10,
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'var(--swiper-navigation-color, var(--swiper-theme-color))',
    background: 'rgba(57, 77, 84, 0.8)',
    border: 'none',
    borderRadius: '50%',
  },
  navButtonNext: {
    right: 10,
    left: 'auto',
    '& $navButtonIcon': {
      marginRight: 'calc(0px - (var(--swiper-navigation-size) / 10))',
    },
  },
  navButtonPrev: {
    right: 'auto',
    left: 10,
    '& $navButtonIcon': {
      marginLeft: 'calc(0px - (var(--swiper-navigation-size) / 10))',
    },
  },
  navButtonIcon: {
    fontSize: 'calc(var(--swiper-navigation-size) / 1.75)',
    '&::before': {
      verticalAlign: 'middle',
    },
  },
  carouselItem: {
    padding: '4px',
    display: 'inline-block',
    verticalAlign: 'top',
  },
  carouselImage: ({ size }) => ({
    width: size === 'sm' ? '360px' : '480px',
    maxWidth: '100%',
    height: 'auto',
    aspectRatio: '640/203',
  }),
});

function CarouselItem({
  promotion,
  size,
}: {
  promotion: { link: string; image: string; alt: string };
  size?: 'sm';
}) {
  const classes = useStyles({ size });
  return (
    <a
      href={promotion.link}
      target="_blank"
      className={classes.carouselItem}
      rel="noreferrer"
    >
      <img
        src={promotion.image}
        className={classes.carouselImage}
        alt={promotion.alt}
      />
    </a>
  );
}

function NavigationButtons() {
  const swiper = useSwiper();
  const classes = useStyles({});
  const onClickPrev = useCallback(() => swiper.slidePrev(), [swiper]);
  const onClickNext = useCallback(() => swiper.slideNext(), [swiper]);

  return (
    <>
      <button
        type="button"
        aria-label="navigation previous button"
        className={`${classes.navButton} ${classes.navButtonPrev}`}
        onClick={onClickPrev}
      >
        <i className={`fa fa-chevron-left ${classes.navButtonIcon}`} />
      </button>
      <button
        type="button"
        aria-label="navigation next button"
        className={`${classes.navButton} ${classes.navButtonNext}`}
        onClick={onClickNext}
      >
        <i className={`fa fa-chevron-right ${classes.navButtonIcon}`} />
      </button>
    </>
  );
}

export const PromotionApp = withErrorBoundaryFallback(
  ({ promotions, size }: Props) => {
    const classes = useStyles({ size });

    if (!promotions) {
      return null;
    }

    const sponsoredPromos = promotions.filter((pr) => pr.sponsored);
    const normalPromos = promotions.filter((pr) => !pr.sponsored);

    const divRef = useRef(null);
    return (
      <div>
        {sponsoredPromos.map((pr) => (
          <CarouselItem promotion={pr} key={pr.link} size={size} />
        ))}
        <div className={classes.wrapper} ref={divRef}>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, Autoplay, A11y]}
            navigation={{ nextEl: null, prevEl: null }}
            pagination={{ clickable: true }}
            autoplay={{ delay: 5000 }}
            rewind
            className={classes.swiper}
          >
            {normalPromos.map((pr) => (
              <SwiperSlide key={pr.link}>
                <CarouselItem promotion={pr} size={size} />
              </SwiperSlide>
            ))}
            <NavigationButtons />
          </Swiper>
        </div>
      </div>
    );
  },
);
