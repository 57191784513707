import * as React from 'react';
import { Button, Dialog, TextField, Icon } from '@material-ui/core';
import * as Colors from '@material-ui/core/colors';
import {
  MuiThemeProvider,
  createTheme,
  withStyles,
} from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import * as _ from 'lodash';
import TopActions from '../../actions/TopActions';
import { CSRFToken } from '../../constants/CommonConstants';
import { t } from '../../i18n';

interface Props {
  open: boolean;
}

class SignInDialog extends React.Component<Props, {}> {
  private _formRef = React.createRef<HTMLFormElement>();

  constructor(props) {
    super(props);
  }

  render() {
    const specialShadow =
      '0px 1px 6px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.12)';
    const btnStyle: CSSProperties = {
      background: '#fff',
      display: 'inline-block',
      height: '36px',
      width: '280px',
      boxShadow: specialShadow,
      fontSize: '14px',
      paddingLeft: '24px',
    };
    const iconStyle: React.CSSProperties = {
      fontSize: '16px',
      position: 'absolute',
      top: '10px',
      left: '12px',
    };
    const labelStyle: React.CSSProperties = {
      position: 'relative',
      top: '2px',
    };
    const textStyle: React.CSSProperties = {
      position: 'relative',
      top: '2px',
      fontSize: '12px',
    };
    const Btn = withStyles({ label: { fontSize: '14px' } })(Button);
    const signinActions = [
      <Btn onClick={() => TopActions.closeSignInDialog()} key="signinCancel">
        {t('view:user.buttons.cancel')}
      </Btn>,
      <button
        type="submit"
        className="btn btn-primary btn-flat"
        key="signinSubmit"
        onClick={() => this._formRef.current.submit()}
      >
        <span style={{ fontFamily: 'Roboto,sans-serif' }}>
          {t('view:user.buttons.signin')}
        </span>
      </button>,
    ];
    const titleStyle: React.CSSProperties = {
      margin: '0px',
      padding: '24px 24px 20px',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: '22px',
      lineHeight: '32px',
      fontWeight: 400,
    };
    const formBoxStyle: React.CSSProperties = {
      fontSize: '16px',
      color: 'rgba(0, 0, 0, 0.6)',
      padding: '0px 24px 24px',
      boxSizing: 'border-box',
      overflowY: 'auto',
      borderTop: 'medium none',
      borderBottom: 'medium none',
      fontFamily: 'Roboto,sans-serif',
    };
    const inputBoxStyle: React.CSSProperties = { fontSize: '16px' };
    const ButtonEx = withStyles({ root: btnStyle })(Button);
    const TextFieldEx = withStyles({ root: { margin: '8px 0px' } })(TextField);
    const formTheme = createTheme({
      palette: {
        primary: {
          main: Colors.cyan['500'],
          dark: Colors.cyan['500'],
          light: Colors.cyan['500'],
        },
      },
    });
    return (
      <Dialog
        onClose={TopActions.closeSignInDialog}
        style={{ overflow: 'auto' }}
        open={this.props.open}
        PaperProps={{ style: { width: '70%', maxWidth: '700px' } }}
      >
        <h3 style={titleStyle}>{t('view:user.sign_in')}</h3>
        <div style={formBoxStyle}>
          <div style={{ textAlign: 'center' }}>
            <form action="/users/auth/facebook" method="post">
              <input
                type="hidden"
                name="authenticity_token"
                value={CSRFToken()}
              />
              <ButtonEx
                variant="contained"
                className="button_facebook icon_link_button oauth_button"
                key="signin_facebook"
                type="submit"
              >
                <span className="fa fa-facebook" style={iconStyle} />
                <span style={labelStyle}>
                  {t('view:user.sign_in_with_facebook')}
                </span>
              </ButtonEx>
            </form>
            <form action="/users/auth/google" method="post">
              <input
                type="hidden"
                name="authenticity_token"
                value={CSRFToken()}
              />
              <ButtonEx
                variant="contained"
                className="button_google icon_link_button oauth_button"
                key="signin_google"
                type="submit"
              >
                <span className="fa fa-google" style={iconStyle} />
                <span style={labelStyle}>
                  {t('view:user.sign_in_with_google')}
                </span>
              </ButtonEx>
            </form>
            <span style={textStyle}>
              <a href="/help#end_of_twitter_login" target="_blank">
                {t('view:user.stop_sign_in_with_twitter')}
              </a>
            </span>
          </div>
          <hr />
          <div>
            <h4>{t('view:user.sign_in_with_name_or_email')}</h4>
            <MuiThemeProvider theme={formTheme}>
              <form
                method="post"
                action="/users/sign_in"
                className="form-horizontal"
                ref={this._formRef}
              >
                <input
                  type="hidden"
                  name="authenticity_token"
                  value={CSRFToken()}
                />
                <TextFieldEx
                  type="text"
                  name="user[name_or_email]"
                  fullWidth
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter' && ev.keyCode === 13) {
                      ev.stopPropagation();
                      this._formRef.current.submit();
                    }
                  }}
                  placeholder={t(
                    'activerecord:attributes.user.username_or_email',
                  )}
                  InputProps={{ style: inputBoxStyle }}
                />
                <TextFieldEx
                  type="password"
                  name="user[password]"
                  fullWidth
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter' && ev.keyCode === 13) {
                      ev.stopPropagation();
                      this._formRef.current.submit();
                    }
                  }}
                  placeholder={t('activerecord:attributes.user.password')}
                  InputProps={{ style: inputBoxStyle }}
                />
              </form>
            </MuiThemeProvider>
          </div>
          <br />
          <a href="/users/password/new">
            {t('view:user.forgot_your_password?')}
          </a>
        </div>
        <div style={{ padding: '8px', textAlign: 'right' }}>
          {signinActions}
        </div>
      </Dialog>
    );
  }
}

export default SignInDialog;
