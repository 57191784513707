import { lazy } from 'react';
import { PdfjsBoundary } from '../PdfjsBoundary';

const PdfPreviewerComponent = lazy(() => import('./PdfPreviewer'));

export function PdfPreviewer(props) {
  return (
    <PdfjsBoundary>
      <PdfPreviewerComponent {...props} />
    </PdfjsBoundary>
  );
}
