import { EventEmitter } from 'events';
import * as _ from 'lodash';
import AppDispatcher from '../dispatcher/AppDispatcher';
import ProjectsConstants from '../constants/ProjectsConstants';
import { SortOrder } from '../CL2Types';

const CHANGE_EVENT = 'change';

const states = {
  query: '',
  sendQuery: '',
  projects: [],
  totalPages: 1,
  currentPage: 1,
  loading: false,
  importDialog: {
    open: false,
    loading: false,
    projects: [],
    error: '',
  },
  templatesDialog: {
    tags: [],
    loaded: false,
    query: '',
  },
  snackbar: {
    open: false,
    message: '',
  },
  error: {
    open: false,
    handle_kind: null,
    message: '',
  },
  selectTargetImportedProject: null,
  sortKey: 'updated_at',
  sortOrder: SortOrder.DESC,
  zip: {
    file: null,
  },
  selected: [],
};

class ProjectsStore extends EventEmitter {
  dispatchToken: string;

  constructor() {
    super();
    this.dispatchToken = AppDispatcher.register((action) => {
      switch (action.actionType) {
        case ProjectsConstants.LOAD_PROJECTS:
          states.loading = true;
          break;
        case ProjectsConstants.REQUEST_UPDATE_PROJECT: {
          const { projects } = states;
          const idx = _.findIndex(projects, (p) => p.id === action.id);
          if (action.params.title) {
            projects[idx].title = action.params.title;
          }
          if (action.params.description) {
            projects[idx].description = action.params.description;
          }
          states.projects = projects;
          break;
        }
        case ProjectsConstants.RECEIVE_LOAD_PROJECTS:
          states.projects = action.json.projects;
          states.totalPages = action.json.meta.total_pages;
          states.currentPage = action.json.meta.current_page;
          states.loading = false;
          states.query = action.json.meta.query;
          states.sendQuery = states.query;
          states.sortKey = action.json.meta.sort_key;
          states.sortOrder = action.json.meta.sort_order;
          break;
        case ProjectsConstants.NARROW_PROJECTS:
          states.query = action.query;
          break;
        case ProjectsConstants.NARROW_TEMPLATES:
          states.templatesDialog.query = action.templateDialog.query;
          break;
        case ProjectsConstants.CLEAR_PROJECT_QUERIES:
          states.query = '';
          break;
        case ProjectsConstants.CLEAR_TEMPLATE_QUERIES:
          states.templatesDialog.query = '';
          break;
        case ProjectsConstants.RECEIVE_UPDATE_PROJECT: {
          const { projects } = states;
          const idx = _.findIndex(
            projects,
            (p) => p.id === action.json.project.id,
          );
          projects[idx] = action.json.project;
          states.projects = projects;
          states.loading = false;
          break;
        }
        case ProjectsConstants.RECEIVE_TEMPLATE_TAGS:
          states.templatesDialog.loaded = true;
          states.templatesDialog.tags = action.json.template_tags;
          break;
        case ProjectsConstants.LOAD_IMPORT_LIST:
          states.importDialog.open = true;
          states.importDialog.loading = true;
          break;
        case ProjectsConstants.RECEIVE_IMPORT_LIST:
          if (action.json) {
            states.importDialog = {
              open: true,
              loading: false,
              projects: action.json.projects || [],
              error: action.json.message || '',
            };
          }
          break;
        case ProjectsConstants.START_IMPORT:
          states.importDialog.loading = true;
          break;
        case ProjectsConstants.CANCEL_IMPORT:
          states.importDialog = {
            open: false,
            loading: false,
            projects: [],
            error: '',
          };
          break;
        case ProjectsConstants.FINISH_IMPORT:
          states.importDialog = {
            open: false,
            loading: false,
            projects: [],
            error: '',
          };
          break;
        case ProjectsConstants.OPEN_SNACKBAR:
          states.snackbar = {
            open: true,
            message: action.message,
          };
          break;
        case ProjectsConstants.CLOSE_SNACKBAR:
          states.snackbar = {
            open: false,
            message: '',
          };
          break;
        case ProjectsConstants.API_ERROR:
          states.error = {
            open: true,
            handle_kind: action.json.handle_kind,
            message: action.json.message,
          };
          break;
        case ProjectsConstants.CLOSE_ERROR:
          states.error = {
            ...states.error,
            open: false,
          };
          break;
        case ProjectsConstants.DELETE_PROJECT:
          states.loading = true;
          break;
        case ProjectsConstants.DELETE_PROJECTS:
          states.loading = true;
          break;
        case ProjectsConstants.UNSYNC_PROJECT:
          states.loading = true;
          break;
        case ProjectsConstants.RECEIVE_UNSYNC_PROJECT:
          states.loading = false;
          break;
        case ProjectsConstants.RECEIVE_DELETE_PROJECT:
          states.loading = false;
          break;
        case ProjectsConstants.RECEIVE_DELETE_PROJECTS:
          states.loading = false;
          break;
        case ProjectsConstants.UPLOAD_ZIP:
          states.zip.file = action.file;
          break;
        case ProjectsConstants.CLEAR_ZIP:
          states.zip.file = null;
          break;
        case ProjectsConstants.RELOAD_PROJECT:
          states.loading = false;
          break;
        case ProjectsConstants.SELECT_PROJECT:
          states.selected.push(action.id);
          break;
        case ProjectsConstants.DESELECT_PROJECT:
          states.selected = states.selected.filter((id) => id !== action.id);
          break;
        case ProjectsConstants.CLEAR_SELECTED:
          states.selected = [];
          break;
      }
      this.emitChange();
    });
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  getStates() {
    return states;
  }
}

export default new ProjectsStore();
