import { Component } from 'react';
import { t } from '../../../i18n';
import FileNameDialog from './FileNameDialog';

import EditorActions from '../../../actions/EditorActions';
import Loading from '../../Loading';

interface Props {
  dialogRef: { dialog: FileDialog };
  open: boolean;
  isFolder: boolean;
  waiting: boolean;
  belonging: number; // id
  onClose: (buttonClicked: boolean) => void;
}
interface State {
  errorText: string;
  handled?: boolean;
}
class FileDialog extends Component<Props, State> {
  private newFileDialogRef = {
    dialog: null,
  };

  constructor(props) {
    super(props);
    props.dialogRef.dialog = this;
    this.state = {
      errorText: '',
    };
  }

  _handleNewFile() {
    const { dialog } = this.newFileDialogRef;
    if (!dialog) {
      return;
    }
    const title = dialog.getFileName();
    if (!title) {
      return;
    }

    const action = this.props.isFolder
      ? EditorActions.createFolder(title, this.props.belonging)
      : EditorActions.createFile(title, this.props.belonging);

    return action.then(
      () =>
        EditorActions.loadFiles().then(() => {
          this.props.onClose(true);
          this.setState({ handled: false });
        }),
      () =>
        EditorActions.loadFiles().then(() => {
          this.props.onClose(true);
          this.setState({ handled: false });
        }),
    );
  }

  render() {
    let nameLabel;
    let title;

    if (this.props.isFolder) {
      nameLabel = t('view:editor.folder_title');
      title = t('view:editor.create_folder');
    } else {
      nameLabel = t('view:editor.file_title');
      title = t('view:editor.create_file');
    }

    return (
      <FileNameDialog
        dialogRef={this.newFileDialogRef}
        open={this.props.open}
        nameLabel={nameLabel}
        waiting={this.props.waiting}
        onRequestExec={this._handleNewFile.bind(this)}
        onClose={this.props.onClose}
        execLabel={t('view:editor.create')}
        title={title}
        isFolder={this.props.isFolder}
      />
    );
  }
}

export default FileDialog;
