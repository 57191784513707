import * as React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { t } from '../../../i18n';

interface Props {
  open: boolean;
  onClose: () => void;
}

class KeyBindingsDialog extends React.Component<Props, {}> {
  constructor(props) {
    super(props);
  }

  private onCloseDialog = () => {
    this.props.onClose();
  };

  render() {
    const titleStyle: React.CSSProperties = {
      padding: '10px 20px',
      textAlign: 'center',
    };
    const attensionsStyle: React.CSSProperties = { margin: '10px 10px' };

    return (
      <Dialog open={this.props.open} onClose={this.onCloseDialog}>
        <DialogTitle
          key="title"
          disableTypography
          style={titleStyle}
          children={<h3>{t('view:editor:keybinding.title')}</h3>}
        />
        <DialogContent>
          <div style={attensionsStyle}>
            <div> {t('view:editor:keybinding.attention')}</div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default KeyBindingsDialog;
