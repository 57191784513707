import * as themes from './themes';
import { isDarkTheme } from './isDarkTheme';

const unsortedThemes = Object.entries(themes).map(([name, theme]) => ({
  name,
  extension: theme,
  dark: isDarkTheme(theme),
}));
const defaultIndex = unsortedThemes.findIndex(
  (t) => t.name === 'cloudlatexDefault',
);

export const themesList = [
  ...unsortedThemes.splice(defaultIndex, 1),
  ...unsortedThemes.filter((t) => !t.dark),
  ...unsortedThemes.filter((t) => t.dark),
];
