import AppDispatcher from '../dispatcher/AppDispatcher';
import UserWebAPIUtils from '../utils/UserWebAPIUtils';
import ProjectsWebAPIUtils from '../utils/ProjectsWebAPIUtils';

import UserConstants from '../constants/UserConstants';

const UserActions = {
  loadUserInfo() {
    return UserWebAPIUtils.loadUserInfo();
  },

  unlinkRemoteStorage(remoteStorage) {
    AppDispatcher.dispatch({
      actionType: UserConstants.UNLINK_DROPBOX,
    });
    return UserWebAPIUtils.unlinkRemoteStorage(remoteStorage).then(() => {
      UserWebAPIUtils.loadUserInfo().then(() => {
        ProjectsWebAPIUtils.loadProjects();
      });
    });
  },

  generateExtensionToken(name_or_email: string, password: string) {
    UserWebAPIUtils.generateExtensionToken(name_or_email, password)
      .then((params) => {
        AppDispatcher.dispatch({
          actionType: UserConstants.RECEIVE_EXTENSION_TOKEN,
          params,
        });
      })
      .catch((err) => {
        AppDispatcher.dispatch({
          actionType: UserConstants.FAILED_GENERATE_EXTENSION_TOKEN,
          json: err,
        });
      });
  },

  deleteExtensionTokens() {
    UserWebAPIUtils.deleteExtensionTokens()
      .then((params) => {
        AppDispatcher.dispatch({
          actionType: UserConstants.DELETED_EXTENSION_TOKEN,
        });
      })
      .catch((err) => {
        AppDispatcher.dispatch({
          actionType: UserConstants.FAILED_DELETE_EXTENSION_TOKEN,
        });
      });
  },
};

export default UserActions;
