export const findAutoWidthPane = (initialWidth) => {
  const initialAutoWidthColumns = [];
  initialWidth.forEach((width, index) => {
    if (width < 0) {
      initialAutoWidthColumns.push(index);
    }
  });
  return initialAutoWidthColumns;
};

export const sum = (array: number[]) =>
  array.reduce((prev, cur) => prev + cur, 0);

export const autoWidth = (widthArray, screenWidth) => {
  const autoWidthCellsSize = widthArray.filter((width) => width < 0).length;
  if (!autoWidthCellsSize) {
    return widthArray;
  }
  const used = sum(widthArray.filter((width) => width >= 0));
  const autoWidth = Math.max((screenWidth - used) / autoWidthCellsSize, 0);
  return widthArray.map((width) => (width >= 0 ? width : autoWidth));
};

export const expandOrShrinkToFitScreen = (
  widthArray,
  screenWidth,
  autoWidthPanes,
) => {
  let totalWidth = sum(widthArray);
  if (totalWidth !== screenWidth) {
    const gap = screenWidth - totalWidth;
    // Enable auto width only if expanding
    if (gap < 0) {
      autoWidthPanes.length = 0;
    }
    if (autoWidthPanes.length) {
      totalWidth = sum(
        widthArray.filter((_, index) => autoWidthPanes.includes(index)),
      );
    }
    let gapArray = new Array(widthArray.length).fill(0).map((width, index) => {
      const ratio = totalWidth ? widthArray[index] / totalWidth : 1;
      const adjustedGap = gap * ratio;
      return !autoWidthPanes.length || autoWidthPanes.includes(index)
        ? adjustedGap
        : 0;
    });
    const sortedArray = widthArray
      .map((width, index) => [width, index])
      .sort((a, b) => a[0] - b[0]);
    for (const [width, index] of sortedArray) {
      if (width + gapArray[index] < 0) {
        const overflow = width + gapArray[index];
        gapArray = gapArray.map((g, i) => {
          if (i === index) {
            return -width;
          }
          return g + overflow / (gapArray.length - 1);
        });
      } else {
        break;
      }
    }

    if (gapArray.filter((eachGap) => eachGap >= 1 || eachGap <= -1).length) {
      widthArray = widthArray.map((width, index) => width + gapArray[index]);
    }
  }
  return widthArray;
};

export const setWidthAndAdjustRight = (widthArray, index, width) => {
  if (index < 0 || index > widthArray.length - 2) {
    return;
  }
  return [
    ...widthArray.slice(0, index),
    width,
    widthArray[index + 1] - (width - widthArray[index]),
    ...widthArray.slice(index + 2),
  ];
};
