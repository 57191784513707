import { createRef, Component, lazy } from 'react';
import { Dialog, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { PdfPreviewer } from './PdfPreviewer';
import EditorActions from '../../../actions/EditorActions';
import {
  isImageFile,
  isPdfFile,
  isEPSFile,
  isSVGFile,
  isTiffFile,
} from '../../../utils/CommonUtils';
import { t } from '../../../i18n';
import * as CL2Types from '../../../CL2Types';

interface Props {
  file: CL2Types.PreviewFile;
  classes: ClassNameMap;
}

const styles = (theme) => ({
  paper: {
    background: theme.palette.background.preview,
  },
});

class PreviewDialog extends Component<Props, {}> {
  private thumbRef = createRef<HTMLImageElement>();

  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.file !== this.props.file;
  }

  render() {
    const open = this.props.file != null;
    const preview = this.renderPreview();
    //      className="image-thumbnail"
    //      autoScrollBodyContent={true}
    //      overlayStyle={{backgroundColor: "rgba(0, 0, 0, 0.3)"}}
    //      <div style={{display: "inline-block"}}>
    const dialog = (
      <Dialog
        open={open}
        onClose={EditorActions.closeBinaryFile}
        classes={{ paper: this.props.classes.paper }}
      >
        <div style={{ padding: '24px' }}>{preview}</div>
      </Dialog>
    );
    return dialog;
  }

  renderPreview() {
    if (this.props.file == null) return null;
    let content = null;
    if (isImageFile(this.props.file.mimetype)) {
      if (
        !isEPSFile(this.props.file.mimetype) &&
        !isSVGFile(this.props.file.mimetype) &&
        !isTiffFile(this.props.file.mimetype)
      ) {
        content = this.renderImage();
      } else {
        content = t('view:editor.preview_unavailable');
      }
    } else if (isPdfFile(this.props.file.mimetype)) {
      content = <PdfPreviewer uri={this.props.file.file_url} />;
    }
    return <div style={{ textAlign: 'center' }}>{content}</div>;
  }

  renderImage() {
    return (
      <img
        id="image_thumbnail_img"
        ref={this.thumbRef}
        src={this.props.file && this.props.file.thumbnail_url}
        onLoad={this._onLoad.bind(this)}
        onError={this._onLoad.bind(this)}
      />
    );
  }

  _onLoad() {
    const imgElem = this.thumbRef.current;
    imgElem && (imgElem.className += ' loaded');
    this.forceUpdate();
  }
}

export default withStyles(styles)(PreviewDialog);
