import * as request from 'superagent';
import ProjectsConstants from '../constants/ProjectsConstants';
import AppDispatcher from '../dispatcher/AppDispatcher';
import { APIRoot, CSRFToken } from '../constants/CommonConstants';
import ProjectsStore from '../stores/ProjectsStore';

const APIEndpoint = `${APIRoot}/templates`;

const TemplatesWebAPIUtils = {
  loadTemplateTags() {
    return new Promise<void>((resolve, reject) => {
      request.get(`${APIEndpoint}/tags`).end((error, res) => {
        if (res.status === 200) {
          const json = JSON.parse(res.text);
          resolve();
          AppDispatcher.dispatch({
            actionType: ProjectsConstants.RECEIVE_TEMPLATE_TAGS,
            json,
          });
        } else {
          reject();
        }
      });
    });
  },
};

export default TemplatesWebAPIUtils;
