import { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';

interface Props {
  rightNavRef: {
    rightNav: {
      toggle: () => void;
    };
  };
}
interface State {
  open: boolean;
}
class RightNav extends Component<Props, State> {
  constructor(props) {
    super(props);
    props.rightNavRef.rightNav = this;
    this.state = {
      open: false,
    };
  }

  /*
  shouldComponentUpdate(nextProps, nextState) {
    if(this.props != nextProps || this.state != nextState) return true;
    return false;
  }
*/
  render() {
    const { rightNavRef, ...rest } = this.props;

    return (
      <Drawer
        {...rest}
        anchor="right"
        open={this.state.open}
        onClose={(e) => this.setState({ open: false })}
        PaperProps={{ style: { width: '384px' } }}
      />
    );
  }

  toggle() {
    this.setState({ open: !this.state.open });
  }
}

export default RightNav;
