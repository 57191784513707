import * as React from 'react';
import { CircularProgress } from '@material-ui/core';
// let interval;
// interface State {
// intervalID: NodeJS.Timeout;
// }
class BlankLoading extends React.Component<
  {},
  { isExecuting: boolean; intervalID: NodeJS.Timeout; color: string }
> {
  constructor(props) {
    super(props);
    this.state = {
      isExecuting: true,
      intervalID: null,
      color: 'rgba(255,255,255,0)',
    };
  }

  componentDidMount() {
    this.setState({ isExecuting: true });
    const interval = setTimeout(() => {
      this.setState({ isExecuting: false });
      this.setState({ color: 'rgba(0,0,0,0.2)' });
    }, 1000);
    this.setState({ intervalID: interval });
  }

  componentWillUnmount() {
    clearTimeout(this.state.intervalID);
  }

  render() {
    const style: React.CSSProperties = {
      position: 'absolute',
      height: '100%',
      width: '100%',
      zIndex: 9,
      top: 0,
      left: 0,
      backgroundColor: this.state.color,
    };
    const pstyle: React.CSSProperties = {
      position: 'relative',
      margin: 'auto',
      display: 'block',
      textAlign: 'center',
      width: '40px',
      height: '40px',
      top: '50%',
      left: 0,
    };
    return (
      <div style={style}>
        <div style={pstyle}>
          {this.state.isExecuting ? null : <CircularProgress />}
        </div>
      </div>
    );
  }
}

export default BlankLoading;
