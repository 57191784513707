import { Component } from 'react';
import * as ReactDOM from 'react-dom';
import { Snackbar } from '@material-ui/core';
import * as _ from 'lodash';
import { Dictionary } from 'lodash';
import FileUploadDialog from './FileUploadDialog';
import EditorActions from '../../../actions/EditorActions';
import { relativePathFromUploadFile } from '../../../utils/CommonUtils';
import { t } from '../../../i18n';
import * as CL2Types from '../../../CL2Types';

interface Props {
  upload: CL2Types.UploadStatuses;
  projectFiles: Dictionary<CL2Types.ProjectFile[]>;
}
interface State {
  shown: boolean;
  files: Array<CL2Types.UploadFile>;
  snackMessage: string;
  snackShow: boolean;
}

class DragArea extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      shown: false,
      files: [],
      snackMessage: '',
      snackShow: false,
    };
    this._handleDragEnter = this._handleDragEnter.bind(this);
    this._validateFiles = this._validateFiles.bind(this);
  }

  componentDidMount() {
    window.addEventListener('dragenter', this._handleDragEnter);
    $(this.refs.fileupload).fileupload({
      autoUpload: false,
      drop: this._dropCallback.bind(this),
    });
  }

  componentWillUnmount() {
    window.removeEventListener('dragenter', this._handleDragEnter);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      ((nextState.shown || (nextProps.upload && nextProps.upload.opened)) &&
        this.props !== nextProps) ||
      this.state !== nextState ||
      this.props.upload.opened !== (nextProps.upload && nextProps.upload.opened)
    );
  }

  _handleDragEnter(e) {
    e.preventDefault();
    const dataTransfer =
      e.dataTransfer || (e.originalEvent && e.originalEvent.dataTransfer);
    if (dataTransfer && $.inArray('Files', dataTransfer.types) !== -1)
      this.setState({ shown: true });
  }

  _handleDragLeave(e) {
    e.preventDefault();
    this.setState({ shown: false });
  }

  _handleDragOver(e) {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
  }

  _dropCallback(e, data) {
    e.preventDefault();
    if (this.props.upload.uploading) {
      EditorActions.finishUploadSession();
      this.setState({ files: [] });
    }
    let { files } = this.state;
    files = files.concat(this._validateFiles(data.files));
    if (!this.props.upload.opened) EditorActions.startUploadSession();
    this.setState({
      shown: false,
      files,
    });
  }

  _handleDrop(e) {
    e.preventDefault();
    if (this.state.shown) this.setState({ shown: false });
  }

  onSelectFile(e) {
    let { files } = this.state;
    files = files.concat(this._validateFiles(e.target.files));
    this.setState({ files });
  }

  _validateFiles(files) {
    const projectFilenames: Array<string> = _.flatMap(
      this.props.projectFiles,
      (files) => files,
    ).map((file) => file.full_path);
    const inQueueFilenames = this.state.files.map(
      (file) => relativePathFromUploadFile(file) + file.name,
    );
    const inQueueFilesize = _.sum(this.state.files.map((file) => file.size));
    // FIXME: Use values in UserStore
    const is_unlimited_storage = window['user'].remain_storage === 'Infinity';
    let remainStorage = is_unlimited_storage
      ? 1
      : window['user'].remain_storage - inQueueFilesize;
    const res = [];
    const excluded = [];
    for (let i = 0; i < files.length; i += 1) {
      const file = files.item ? files.item(i) : files[i];
      const fullPath = relativePathFromUploadFile(file) + file.name;
      if (file.name && file.name !== '.latexmkrc' && file.name[0] === '.') {
        continue;
      }
      remainStorage -= file.size;
      if (!is_unlimited_storage && remainStorage < 0) {
        this.setState({
          snackMessage: t('view:api.file.error_upload_too_large'),
          snackShow: true,
        });
        return res;
      }
      if (_.includes(inQueueFilenames, fullPath)) {
        excluded.push(file.name);
      } else {
        if (_.includes(projectFilenames, fullPath)) file.overWritten = true;
        res.push(file);
      }
    }
    if (excluded.length > 0) {
      // TODO use i18n
      this.setState({
        snackMessage: `${excluded.join(', ')} already in the list.`,
        snackShow: true,
      });
    }
    return res;
  }

  onDialogClose() {
    this.setState({ files: [] });
    EditorActions.finishUploadSession();
  }

  onUpload() {
    EditorActions.upload(this.refs.fileupload, this.state.files);
  }

  onRemoveFiles(index) {
    const { files } = this.state;
    _.pullAt(files, [index]);
    this.setState({ files });
  }

  render() {
    const style = _.extend(
      { border: 'none', backgroundColor: 'rgba(120, 175, 230, 0.70)' },
      this.state.shown ? {} : { display: 'none' },
    );
    return ReactDOM.createPortal(
      <div>
        <div
          className="drag_drop_area"
          style={style}
          onDragLeave={this._handleDragLeave.bind(this)}
          onDragOver={this._handleDragOver.bind(this)}
          onDrop={this._handleDrop.bind(this)}
        >
          <p
            style={{
              color: 'white',
              textAlign: 'center',
              padding: '300px 300px',
              fontSize: '28px',
            }}
          >
            {t('view:editor.drag-and-drop')}
          </p>
        </div>
        <FileUploadDialog
          opened={this.props.upload.opened}
          files={this.state.files}
          upload={this.props.upload}
          onSelectFile={this.onSelectFile.bind(this)}
          onDialogClose={this.onDialogClose.bind(this)}
          onRemoveFiles={this.onRemoveFiles.bind(this)}
          onUpload={this.onUpload.bind(this)}
        />
        <Snackbar
          open={this.state.snackShow}
          autoHideDuration={3000}
          onClose={() => this.setState({ snackShow: false })}
          message={
            <span style={{ fontSize: '14px' }}>{this.state.snackMessage}</span>
          }
        />
        <form ref="fileupload" method="POST" encType="multipart/form-data">
          <input type="file" name="files[]" style={{ display: 'none' }} />
        </form>
      </div>,
      document.body,
    );
  }
}

export default DragArea;
