export function isTexFile(mimetype: string): boolean {
  // application/x-rtex はRtexのために便宜的に設けたmimetype
  return [
    'text/x-tex',
    'application/x-tex',
    'application/x-latex',
    'application/x-rtex',
    'application/x-bibtex',
    'application/x-classoptions',
  ].includes(mimetype);
}

export function isTextFile(mimetype: string): boolean {
  return (
    isTexFile(mimetype) ||
    mimetype.split('/')[0] === 'text' ||
    mimetype === 'application/x-python'
  );
}

export function isEPSFile(mimetype: string): boolean {
  return mimetype === 'application/postscript';
}

export function isSVGFile(mimetype: string): boolean {
  return mimetype === 'image/svg+xml';
}

export function isTiffFile(mimetype: string): boolean {
  return mimetype === 'image/tiff';
}

export function isImageFile(mimetype: string): boolean {
  return mimetype.split('/')[0] === 'image' || isEPSFile(mimetype);
}

export function isPdfFile(mimetype: string): boolean {
  return mimetype === 'application/pdf';
}

export function relativePathFromUploadFile(file): string {
  const delimiter = '/';
  /*
   * relativePath does not include the file name (ex: 'folder/')
   */
  if (file.relativePath) {
    return file.relativePath;
  }

  /*
   * webkitRelativePath includes the file name (ex: 'foder/hoge.md')
   * and so, remove the file name
   */
  if (file.webkitRelativePath) {
    const splited = file.webkitRelativePath.split(delimiter);
    splited.pop();
    return splited.join(delimiter) + delimiter;
  }

  return delimiter;
}
