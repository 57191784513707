import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Loading from 'react/components/Loading';
import { Alert } from '@material-ui/lab';
import { t } from '../../../i18n';

export function PdfjsBoundary({ children }) {
  return (
    <ErrorBoundary
      fallback={<Alert severity="error">{t('browser_alert:pdf_view')}</Alert>}
    >
      <React.Suspense
        fallback={
          <div>
            <Loading />
          </div>
        }
      >
        {children}
      </React.Suspense>
    </ErrorBoundary>
  );
}
