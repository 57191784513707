import { Component } from 'react';

/**
 * React.Componentの基底クラス.
 */
class AbstractComponent<P, S> extends Component<P, S> {
  constructor(props) {
    super(props);

    // 自身のメソッドについて「this」のバインドを行う
    Object.getOwnPropertyNames(this.constructor.prototype)
      .filter((prop) => typeof this[prop] === 'function')
      .forEach((method) => (this[method] = this[method].bind(this)));
  }
}

export default AbstractComponent;
