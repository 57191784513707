import * as React from 'react';
import { TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import { SortOrder } from '../../CL2Types';

const TableCellEx = withStyles({
  root: { '&:hover': { background: '#DDDDDD', cursor: 'pointer' } },
})(TableCell);

interface Props {
  style: React.CSSProperties;
  selectedSort: boolean;
  currentSortOrder: SortOrder;
  onClick: (e) => void;
}

class SortableTableHeaderColumn extends React.Component<Props, {}> {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      children,
      currentSortOrder,
      selectedSort,
      style: style_,
      ...props
    } = this.props;

    const style: React.CSSProperties = {};
    _.assign(style, style_, selectedSort ? { fontWeight: 'bold' } : {});
    const subStyle: React.CSSProperties = { position: 'relative' };
    if (selectedSort) {
      subStyle.top = '1px';
    }

    return (
      <TableCellEx style={style} {...props}>
        <span style={subStyle}>{children}</span>
        {selectedSort &&
          (currentSortOrder === SortOrder.ASC ? (
            <img
              src="images/materialui_icons/ic_arrow_upward_black_24px.svg"
              width="18"
            />
          ) : (
            <img
              src="images/materialui_icons/ic_arrow_downward_black_24px.svg"
              width="18"
            />
          ))}
      </TableCellEx>
    );
  }
}

export default SortableTableHeaderColumn;
