import { EditorView } from '@codemirror/view';
import { cobalt } from 'thememirror';
import { dracula } from '@uiw/codemirror-theme-dracula';
import { nord } from '@uiw/codemirror-theme-nord';
import { solarizedLight } from '@uiw/codemirror-theme-solarized';

// Example of custom themes
// export { sampleTheme } from './sampleTheme';
// ...and add other themes here.
export { cloudlatexDefault } from './cloudlatexDefault';
export { oneDark } from '@codemirror/theme-one-dark';
export { darcula } from './darcula';
export { blackboard } from './blackboard';
export { erlangDark } from './erlangDark';

// Super hacky something to make it at least usable.
// - change activeLine color into transparent
[cobalt, nord, solarizedLight].forEach((theme: Array<any>) => {
  if (typeof theme !== 'function') {
    const activeLineBackground = `${theme[0][1].value.rules.find((rule) => rule.indexOf('.cm-activeLine') > -1)?.match(/#[0-9a-fA-F]{6}/)[0]}33`;
    const selectionColor = theme[0][1].value.rules
      .find((rule) => rule.indexOf('.cm-selectionBackground') > -1)
      ?.match(/#[0-9a-fA-F]{6}/)[0];
    theme.unshift(
      EditorView.theme({
        '.cm-activeLine': {
          backgroundColor: activeLineBackground,
        },
        '&.cm-focused > .cm-scroller > .cm-selectionLayer .cm-selectionBackground, .cm-selectionBackground':
          {
            backgroundColor: selectionColor,
          },
      }),
    );
  }
});

export { cobalt, dracula, nord, solarizedLight };
