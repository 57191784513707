import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import { t } from '../../../i18n';

const useStyles = makeStyles((theme) => ({
  contentRoot: {
    whiteSpace: 'pre-wrap',
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
  disableButton?: boolean;
  invalidateFileCacheHandler: () => void;
  successOpen: boolean;
  onSuccessClose: () => void;
};

export function InvalidateFileCacheDialog({
  open,
  onClose,
  disableButton,
  invalidateFileCacheHandler,
  successOpen,
  onSuccessClose,
}: Props) {
  const classes = useStyles();

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle
          disableTypography
          children={<h3>{t('view:editor:invalidate_file_cache.title')}</h3>}
        />
        <DialogContent classes={{ root: classes.contentRoot }}>
          {t('view:editor:invalidate_file_cache.attention')}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={disableButton}
            variant="contained"
            onClick={invalidateFileCacheHandler}
          >
            {t('view:editor.invalidate_file_cache.action')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={successOpen} onClose={onSuccessClose}>
        <DialogContent classes={{ root: classes.contentRoot }}>
          {t('view:editor:success_invalidate_file_cache.attention')}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onSuccessClose}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
