import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  LinearProgress,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from '@material-ui/core';
import { createTheme, withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import * as Colors from '@material-ui/core/colors';
import * as _ from 'lodash';
import * as mime from 'mime-types';
import TooltipFontIcon from './TooltipFontIcon';
import EditorActions from '../../../actions/EditorActions';
import { t } from '../../../i18n';
import * as CL2Types from '../../../CL2Types';
import { relativePathFromUploadFile } from '../../../utils/CommonUtils';

const TableHeadColumn = TableCell;
const TableRowColumn = TableCell;

const LinearProgressEx = withStyles({
  barColorPrimary: {
    backgroundColor: Colors.cyan['500'],
  },
  colorPrimary: {
    backgroundColor: Colors.grey['400'],
  },
})(LinearProgress);

class TableWrapper extends React.Component<{ style: React.CSSProperties }, {}> {
  static _theme = (outerTheme) =>
    createTheme(
      {
        overrides: {
          MuiTableRow: {
            root: {
              '&$selected': {
                backgroundColor: Colors.grey['300'],
              },
            },
            head: {
              height: '56px',
            },
          },
          MuiTableCell: {
            root: {
              padding: '0px 24px',
              '&:first-child': {
                padding: 0,
              },
            },
            head: {
              fontSize: '12px',
              backgroundColor: outerTheme.palette.background.paper,
              color: outerTheme.palette.text.secondary,
              fontWeight: 'normal',
            },
            body: {
              fontSize: '13px',
              fontWeight: 300,
            },
          },
          MuiCheckbox: {
            root: {
              paddingBottom: '11px',
              position: 'relative',
              left: '12px',
              bottom: '0.5px',
              '&$checked': {
                color: Colors.cyan['500'],
              },
            },
          },
        },
      },
      outerTheme,
    );

  constructor(props) {
    super(props);
  }

  render() {
    const { children, ...other } = this.props;
    return (
      <div {...other}>
        <ThemeProvider theme={TableWrapper._theme}>{children}</ThemeProvider>
      </div>
    );
  }
}

interface Props {
  opened: boolean;
  files: Array<CL2Types.UploadFile>;
  upload: CL2Types.UploadStatuses;
  onSelectFile: (e) => void;
  onDialogClose: () => void;
  onRemoveFiles: (idx: number) => void;
  onUpload: () => void;
}
interface State {
  started?: boolean;
}
class FileUploadDialog extends React.Component<Props, State> {
  static _buttonTheme = (outerTheme) =>
    createTheme(
      {
        overrides: {
          MuiButton: {
            root: {
              padding: '0px',
              borderRadius: '2px',
            },
            contained: {
              backgroundColor: 'transparent',
              boxShadow:
                'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.4)',
              },
              '&:active': {
                boxShadow:
                  'rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px',
              },
            },
            label: {
              height: '36px',
              color: 'white',
            },
          },
        },
      },
      outerTheme,
    );

  private upload_btn_enabled: boolean;

  private fileSelector = React.createRef<HTMLInputElement>();

  private folderSelector = React.createRef<HTMLInputElement>();

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const u = this.props.upload;
    const finished = u.uploaded > 0 && u.uploaded === this.props.files.length;
    const uploadLabel = finished
      ? t('view:editor.done')
      : u.uploading
        ? t('view:editor.uploading')
        : t('view:editor.upload');
    const actionsButtonWrapperStyle: React.CSSProperties = {
      display: 'inline-block',
      borderRadius: '2px',
    };
    const actionsButtonStyle: React.CSSProperties = {
      margin: '0px',
      minWidth: '88px',
    };
    const actionsButtonLabelStyle: React.CSSProperties = { fontSize: '14px' };
    const buttonIconStyle: React.CSSProperties = {
      position: 'relative',
      top: '1px',
      left: '8px',
      fontSize: '24px',
    };
    const buttonStringStyle: React.CSSProperties = {
      position: 'relative',
      top: '1px',
      fontSize: '14px',
      paddingLeft: '16px',
      paddingRight: '16px',
    };
    this.upload_btn_enabled = !u.uploading || finished;
    const actions = [
      <Button
        key="cancel"
        children={
          <span
            style={_.defaults(
              { color: Colors.pink.A200 },
              actionsButtonLabelStyle,
            )}
          >
            {t('view:editor.cancel')}
          </span>
        }
        disabled={u.uploading}
        onClick={this.props.onDialogClose}
        style={actionsButtonStyle}
      />,
      <Button
        key="upload"
        children={
          <span
            style={_.defaults(
              { color: Colors.cyan['500'] },
              actionsButtonLabelStyle,
            )}
          >
            {uploadLabel}
          </span>
        }
        disabled={!this.upload_btn_enabled}
        onClick={this._onUpload}
        style={actionsButtonStyle}
      />,
    ];
    if (u.uploading || finished) actions.shift();
    return (
      <Dialog
        open={this.props.opened}
        onClose={this._closeDialog}
        onKeyPress={(event) => {
          if (event.key === 'Enter' && this.upload_btn_enabled) {
            this._onUpload();
          }
        }}
        PaperProps={{ style: { width: '75%', maxWidth: '576px' } }}
      >
        <DialogTitle
          key="title"
          disableTypography
          style={{ padding: '0px' }}
          children={
            <h3
              style={{
                fontSize: '22px',
                fontWeight: 400,
                margin: '24px 24px 20px',
                lineHeight: '32px',
              }}
            >
              {t('view:editor.file_upload')}
            </h3>
          }
        />
        <DialogContent
          key="content"
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0 24px 24px',
          }}
        >
          {finished || u.uploading ? (
            ''
          ) : (
            <div style={{ flex: 'none' }}>
              <ThemeProvider theme={FileUploadDialog._buttonTheme}>
                <span
                  style={_.defaults(
                    { backgroundColor: Colors.blue.A200 },
                    actionsButtonWrapperStyle,
                  )}
                >
                  <Button variant="contained" onClick={this._openFileDialog}>
                    <Icon style={buttonIconStyle}>add_circle_outline</Icon>
                    <span style={buttonStringStyle}>
                      {t('view:editor.add_files')}
                    </span>
                  </Button>
                </span>
                <span style={{ display: 'inline-block', width: '20px' }} />
                <span
                  style={_.defaults(
                    { backgroundColor: Colors.pink.A200 },
                    actionsButtonWrapperStyle,
                  )}
                >
                  <Button variant="contained" onClick={this._openFolderDialog}>
                    <Icon style={buttonIconStyle}>add_circle_outline</Icon>
                    <span style={buttonStringStyle}>
                      {t('view:editor.add_folder')}
                    </span>
                  </Button>
                </span>
                <span style={{ display: 'inline-block', width: '20px' }} />
              </ThemeProvider>
              <input
                ref={this.fileSelector}
                type="file"
                style={{ display: 'none' }}
                onChange={this._onSelectFile}
              />
              <input
                ref={this.folderSelector}
                type="file"
                style={{ display: 'none' }}
                onChange={this._onSelectFile}
              />
            </div>
          )}
          <TableWrapper style={{ flex: 'auto', overflowY: 'auto' }}>
            <Table className="files_table" style={{ tableLayout: 'fixed' }}>
              <TableHead>
                <TableRow>
                  <TableHeadColumn style={{ width: '48px', zIndex: 1 }} />
                  <TableHeadColumn style={{ width: '33%', zIndex: 1 }}>
                    {t('view:editor.file_name')}
                  </TableHeadColumn>
                  <TableHeadColumn style={{ width: '27%' }}>
                    {t('view:editor.file_path')}
                  </TableHeadColumn>
                  <TableHeadColumn style={{ width: '20%' }}>
                    {t('view:editor.file_type')}
                  </TableHeadColumn>
                  <TableHeadColumn style={{ width: '20%' }}>
                    {t('view:editor.file_size')}
                  </TableHeadColumn>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.files.map((file, idx, arr) => {
                  const progress = _.find(
                    u.progress,
                    (prg) => prg.files[0].name === file.name,
                  );
                  const failed = _.some(
                    u.fail,
                    (f) => f.files[0].name === file.name,
                  );
                  const iconName = failed ? 'close' : 'check';
                  const iconColor = failed
                    ? Colors.red['500']
                    : Colors.green['500'];
                  const iconStyle: React.CSSProperties = {
                    fontSize: '16px',
                    paddingTop: '2px',
                    marginRight: '5px',
                  };
                  const tableRowSmallPadding: React.CSSProperties = {
                    paddingLeft: '2px',
                    paddingRight: '2px',
                  };
                  const tableRowColumnDeleteButtonStyle: React.CSSProperties = {
                    width: '48px',
                  };
                  const tableRowColumnFileNameStyle: React.CSSProperties = {
                    width: '33%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    ...tableRowSmallPadding,
                  };
                  const tableRowColumnRelativePathStyle: React.CSSProperties = {
                    width: '27%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    ...tableRowSmallPadding,
                  };
                  const tableRowColumnMimeStyle: React.CSSProperties = {
                    width: '20%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    ...tableRowSmallPadding,
                  };
                  const tableRowColumnSizeStyle: React.CSSProperties = {
                    width: '20%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  };
                  const relativePathStyle: React.CSSProperties = {
                    color: 'rgb(127, 127, 127)',
                    paddingLeft: '4px',
                    fontSize: '11px',
                  };
                  if (idx === arr.length - 1) {
                    tableRowColumnDeleteButtonStyle.borderBottom = '0px';
                    tableRowColumnFileNameStyle.borderBottom = '0px';
                    tableRowColumnRelativePathStyle.borderBottom = '0px';
                    tableRowColumnMimeStyle.borderBottom = '0px';
                    tableRowColumnSizeStyle.borderBottom = '0px';
                  }
                  const relativePath = relativePathFromUploadFile(file);
                  return (
                    <TableRow
                      key={file.name}
                      style={{ overflow: 'visible' }}
                      hover
                    >
                      <TableRowColumn style={tableRowColumnDeleteButtonStyle}>
                        {!finished && !u.uploading && (
                          <Button
                            onClick={() => this._onRemoveFile(idx)}
                            style={{ minWidth: '100%', width: '100%' }}
                          >
                            <Icon>clear</Icon>
                          </Button>
                        )}
                      </TableRowColumn>
                      <TableRowColumn style={tableRowColumnFileNameStyle}>
                        <span>
                          {finished ? (
                            <TooltipFontIcon
                              mdiIconName={iconName}
                              color={iconColor}
                              style={iconStyle}
                              tooltip={failed ? 'error' : 'success'}
                            />
                          ) : (
                            ''
                          )}
                          {!finished && file.overWritten ? (
                            <TooltipFontIcon
                              mdiIconName="info"
                              color={Colors.deepOrange['500']}
                              style={iconStyle}
                              tooltip={t('view:editor.overwritten')}
                            />
                          ) : (
                            ''
                          )}
                        </span>
                        <span title={file.name}>{file.name}</span>
                        {!finished && progress !== undefined ? (
                          <LinearProgressEx
                            variant="determinate"
                            value={(progress.loaded * 100) / progress.total}
                          />
                        ) : (
                          ''
                        )}
                      </TableRowColumn>
                      <TableRowColumn style={tableRowColumnRelativePathStyle}>
                        <span style={relativePathStyle} title={relativePath}>
                          {relativePath}
                        </span>
                      </TableRowColumn>
                      <TableRowColumn style={tableRowColumnMimeStyle}>
                        {mime.lookup(file.name)}
                      </TableRowColumn>
                      <TableRowColumn style={tableRowColumnSizeStyle}>
                        {file.size}
                      </TableRowColumn>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableWrapper>
        </DialogContent>
        <DialogActions key="actions" children={actions} />
      </Dialog>
    );
  }

  private _openFileDialog = () => {
    this.fileSelector.current.click();
  };

  private _openFolderDialog = () => {
    this.folderSelector.current['directory'] = true;
    this.folderSelector.current['webkitdirectory'] = true;
    this.folderSelector.current.click();
  };

  private _onRemoveFile = (idx: number) => {
    this.props.onRemoveFiles(idx);
  };

  private _onUpload = () => {
    if (this.props.files.length === 0) return;
    if (this.props.upload.uploading) {
      // DONE
      this._closeDialog();
    } else {
      // UPLOAD
      this.props.onUpload();
      this.setState({ started: true });
    }
  };

  private _closeDialog = () => {
    const u = this.props.upload;
    if (
      !u.uploading ||
      (u.uploaded > 0 && u.uploaded === this.props.files.length)
    ) {
      EditorActions.loadFiles();
      this.props.onDialogClose();
    }
  };

  private _onSelectFile = (e) => {
    this.props.onSelectFile(e);
  };
}

export default FileUploadDialog;
