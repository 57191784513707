import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { ComponentType } from 'react';
import * as Sentry from '@sentry/react';
import { t } from '../../i18n';

const useStyles = makeStyles({
  fallback: {
    margin: 16,
    padding: 16,
  },
});

function Fallback() {
  const styles = useStyles();
  return (
    <Paper variant="outlined" className={styles.fallback}>
      <div>{t('view:error.something_went_wrong')}</div>
    </Paper>
  );
}

export function withErrorBoundaryFallback<Props extends Object>(
  Component: ComponentType<Props>,
) {
  return function withErrorBoundary(props) {
    return (
      <Sentry.ErrorBoundary fallback={Fallback}>
        <Component {...props} />
      </Sentry.ErrorBoundary>
    );
  };
}
