import * as React from 'react';
import * as request from 'superagent';
import { TagsInput } from './TagsInput';
import { CSRFToken } from '../constants/CommonConstants';

function reducer(state, action) {
  switch (action.type) {
    case 'SUCCESS_LOAD_TAGS':
      return { ...state, options: action.payload };
    case 'SET_VALUE':
      return {
        ...state,
        value: action.payload.filter(
          (tag, i) => action.payload.findIndex((t) => t.id === tag.id) === i,
        ),
      };
    default:
      return state;
  }
}

export function TemplateTagsInput({ value, endpoint }) {
  const [state, dispatch] = React.useReducer(reducer, {
    options: [],
    value,
  });

  React.useEffect(() => {
    (async () => {
      dispatch({ type: 'REQUEST_LOAD_TAGS' });
      try {
        const response = await request.get(endpoint);
        const json = response.body;
        dispatch({ type: 'SUCCESS_LOAD_TAGS', payload: json.template_tags });
      } catch (e) {
        dispatch({ type: 'FAILURE_LOAD_TAGS' });
      }
    })();
  }, []);

  const createNew = React.useCallback(
    async (label) => {
      dispatch({ type: 'REQUEST_CREATE_TAG' });
      try {
        const response = await request
          .post(endpoint)
          .set('X-CSRF-Token', CSRFToken())
          .send({ template_tag: { label } });
        const json = response.body;
        dispatch({ type: 'SUCCESS_CREATE_TAG', payload: json });
        return json;
      } catch (e) {
        dispatch({ type: 'FAILURE_CREATE_TAG' });
      }
    },
    [state],
  );

  const onChange = React.useCallback(async (event, value) => {
    const newValue = (
      await Promise.all(
        value.map(async (option) => {
          if (typeof option === 'string') {
            const newOption = await createNew(option);
            return newOption;
          }
          return option;
        }),
      )
    ).filter((option) => option);
    dispatch({ type: 'SET_VALUE', payload: newValue });
  }, []);

  return (
    <TagsInput
      name="template[template_tag_ids]"
      onChange={onChange}
      value={state.value}
      options={state.options}
    />
  );
}
