import * as request from 'superagent';
import ProjectsConstants from '../constants/ProjectsConstants';
import AppDispatcher from '../dispatcher/AppDispatcher';
import { APIRoot, CSRFToken } from '../constants/CommonConstants';
import HandleKinds from '../constants/HandleKinds';

import * as CL2Types from '../CL2Types';
import ProjectsStore from '../stores/ProjectsStore';

const APIEndpoint = `${APIRoot}/projects`;

const createPromise = (
  request,
  doneFunc = (res) => {},
  failFunc = (res) => {},
) =>
  new Promise<any>((resolve, reject) => {
    request.end((err, res) => {
      if (!res || res.status !== 200) {
        const json = JSON.parse(res.text);
        if (json === null || json === undefined) {
          return reject(res);
        }
        if (json.handle_kind === HandleKinds.retry) {
          createPromise(request, doneFunc, failFunc);
        } else {
          if (json.handle_kind !== HandleKinds.nothing) {
            AppDispatcher.dispatch({
              actionType: ProjectsConstants.API_ERROR,
              json,
            });
          }
          failFunc(res);
          reject();
        }
      } else {
        doneFunc(res);
        resolve(res);
      }
    });
  });

const ProjectsWebAPIUtils = {
  loadProjects(
    currentPage: number = null,
    query: string = null,
    sortKey: string = null,
    sortOrder: CL2Types.SortOrder = null,
  ) {
    const states = ProjectsStore.getStates();
    const params = {
      current_page: states.currentPage,
      query: states.query,
      sort_key: states.sortKey,
      sort_order: states.sortOrder,
    };

    if (query !== null) {
      params.query = query.trim();
    }

    if (params.query !== states.sendQuery) {
      params.current_page = 1;
    } else if (currentPage !== null) {
      params.current_page = currentPage;
    }

    if (sortKey != null) {
      params.sort_key = sortKey;
    }

    if (sortOrder != null) {
      params.sort_order = sortOrder;
    }

    return createPromise(request.get(APIEndpoint).query(params), (res) => {
      const json = JSON.parse(res.text);
      AppDispatcher.dispatch({
        actionType: ProjectsConstants.RECEIVE_LOAD_PROJECTS,
        json,
      });
    });
  },

  createProject(
    title: string,
    description: string,
    template: string,
    file: CL2Types.UploadFile,
    compiler?: string,
  ) {
    let req = request
      .post(APIEndpoint)
      .set('X-CSRF-Token', CSRFToken())
      .attach('file', file)
      .field('project[title]', title)
      .field('project[description]', description)
      .field('template', template);

    if (compiler !== undefined) {
      req = req.field('project[compiler]', compiler);
    }

    return createPromise(req, (res) => {
      const json = JSON.parse(res.text);
      AppDispatcher.dispatch({
        actionType: ProjectsConstants.RECEIVE_CREATE_PROJECT,
        json,
      });
    });
  },

  deleteProject(id: number) {
    return createPromise(
      request.del(`${APIEndpoint}/${id}`).set('X-CSRF-Token', CSRFToken()),
      (res) => {
        const json = JSON.parse(res.text);
        AppDispatcher.dispatch({
          actionType: ProjectsConstants.RECEIVE_DELETE_PROJECT,
          json,
        });
      },
    );
  },

  deleteProjects(ids: number[]) {
    return createPromise(
      request.del(APIEndpoint).send({ ids }).set('X-CSRF-Token', CSRFToken()),
      (res) => {
        const json = JSON.parse(res.text);
        AppDispatcher.dispatch({
          actionType: ProjectsConstants.RECEIVE_DELETE_PROJECTS,
          json,
        });
      },
    );
  },

  unsyncProject(id: number) {
    return createPromise(
      request
        .post(`${APIEndpoint}/${id}/unsync`)
        .set('X-CSRF-Token', CSRFToken()),
      (res) => {
        const json = JSON.parse(res.text);
        AppDispatcher.dispatch({
          actionType: ProjectsConstants.RECEIVE_UNSYNC_PROJECT,
          json,
        });
      },
    );
  },

  updateProject(id: number, params: { title?: string; description?: string }) {
    return createPromise(
      request
        .put(`${APIEndpoint}/${id}`)
        .send(params)
        .set('X-CSRF-Token', CSRFToken()),
      (res) => {
        const json = JSON.parse(res.text);
        AppDispatcher.dispatch({
          actionType: ProjectsConstants.RECEIVE_UPDATE_PROJECT,
          json,
        });
      },
    );
  },
  createProjectFromTemplate(id: number) {
    return createPromise(
      request
        .post(`${APIEndpoint}/template/${id}`)
        .set('X-CSRF-Token', CSRFToken()),
      (res) => {
        const json = JSON.parse(res.text);
        AppDispatcher.dispatch({
          actionType: ProjectsConstants.RECEIVE_CREATE_PROJECT,
          json,
        });
      },
    );
  },
  getProjectNum() {
    return createPromise(request.get(`${APIEndpoint}/count`), (res) => {
      const json = JSON.parse(res.text);
      AppDispatcher.dispatch({
        actionType: ProjectsConstants.RECEIVE_PROJECT_NUM,
        json,
      });
    });
  },
  loadDropboxImportList() {
    return new Promise<void>((resolve, reject) => {
      request
        .get(`${APIRoot}/dropbox`)
        .set('X-CSRF-Token', CSRFToken())
        .end((err, res) => {
          const json = JSON.parse(res.text);
          if (res.status === 200) {
            resolve();
          } else {
            AppDispatcher.dispatch({
              actionType: ProjectsConstants.API_ERROR,
              status: res.status,
              json,
            });
            reject();
          }
          AppDispatcher.dispatch({
            actionType: ProjectsConstants.RECEIVE_IMPORT_LIST,
            json,
          });
        });
    });
  },
  startDropboxImport(title: string) {
    return new Promise<any>((resolve, reject) => {
      request
        .post(`${APIRoot}/dropbox/import`)
        .send({ title })
        .set('X-CSRF-Token', CSRFToken())
        .end((err, res) => {
          const json = JSON.parse(res.text);
          if (res.status === 200) {
            resolve(json.message);
          } else {
            reject(json.message);
          }
          AppDispatcher.dispatch({
            actionType: ProjectsConstants.FINISH_IMPORT,
            json,
          });
        });
    });
  },

  startPollingDropboxImportStatus(
    project_id: number,
    interval: number,
    cancellor_token: CL2Types.Runnable,
  ) {
    return new Promise((resolve, reject) => {
      const id = setInterval(() => {
        if (!project_id) return;
        request
          .get(`${APIRoot}/projects/${project_id}/import_status`)
          .set('X-CSRF-Token', CSRFToken())
          .end((err, res) => {
            const json = JSON.parse(res.text);
            if (res.status === 200) {
              if (json.import_status === 'completed') {
                cancellor_token.run = () => {};
                resolve('view.api.remote_storage.import_finished');
                clearInterval(id);
              } else if (json.import_status === 'import_error') {
                cancellor_token.run = () => {};
                resolve('view.api.remote_storage.import_failed');
                clearInterval(id);
              }
            } else {
              cancellor_token.run = () => {};
              AppDispatcher.dispatch({
                actionType: ProjectsConstants.API_ERROR,
                status: res.status,
                json,
              });
              reject();
              clearInterval(id);
            }
          });
      }, interval);
      cancellor_token.run = () => {
        clearInterval(id);
        reject();
      };
    });
  },

  copyProject(project_id: number) {
    AppDispatcher.dispatch({
      actionType: ProjectsConstants.LOAD_PROJECTS,
    });
    return createPromise(
      request
        .get(`${APIRoot}/projects/${project_id}/copy`)
        .set('X-CSRF-Token', CSRFToken()),
      undefined,
      (res) => {
        AppDispatcher.dispatch({
          actionType: ProjectsConstants.RELOAD_PROJECT,
        });
      },
    );
  },

  setCompileTarget(project_id: number, file_id: number) {
    return createPromise(
      request
        .put(`${APIRoot}/projects/${project_id}`)
        .set('X-CSRF-Token', CSRFToken())
        .send({
          project: { compile_target_file_id: file_id },
        }),
    );
  },
};

export default ProjectsWebAPIUtils;
