import { LinearProgress } from '@material-ui/core';
import * as Colors from '@material-ui/core/colors';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import { t } from '../../i18n';

import * as CL2Types from '../../CL2Types';

const useStyles = makeStyles({
  div: {
    margin: '10px 0px 20px 20px',
  },
  inline: { clear: 'both', display: 'inline-block' },
  linearProgress: {
    width: '500px',
    height: 20,
    marginLeft: 10,
    top: 5,
    backgroundColor: '#bdbdbd',
  },
});

function UsageContainer(
  props: CL2Types.UsageProps & { user: CL2Types.ProjectUser },
) {
  const classes = useStyles();

  const barStyle = { backgroundColor: '#29b6f6' };
  const styleEx = { bar: barStyle };
  const LinearProgressEx = withStyles(styleEx)(LinearProgress);
  let storageSize;
  let usageMB: string | JSX.Element = Math.ceil(
    props.storage_usage / 1024 / 1024,
  ).toString();
  let progressBar: JSX.Element = null;
  if (typeof props.storage_size === 'number') {
    const sizeMB = Math.ceil(props.storage_size / 1024 / 1024).toString();
    storageSize = `${sizeMB} MB`;
    if (props.storage_usage >= props.storage_size) {
      usageMB = (
        <span style={{ color: Colors.red['700'], fontWeight: 'bold' }}>
          {usageMB}
        </span>
      );
    }
    progressBar = (
      <LinearProgressEx
        className={`${classes.linearProgress} ${classes.inline}`}
        variant="determinate"
        value={(100 * props.storage_usage) / props.storage_size}
      />
    );
  } else {
    storageSize = t('view:projects.unlimited');
  }
  let projectNum: number | JSX.Element = props.project_num;
  if (
    typeof props.max_project_num === 'number' &&
    props.project_num >= props.max_project_num
  ) {
    projectNum = (
      <span style={{ color: Colors.red['700'], fontWeight: 'bold' }}>
        {props.project_num}
      </span>
    );
  }
  const maxProjectNum =
    typeof props.max_project_num === 'number'
      ? props.max_project_num
      : t('view:projects.unlimited');

  return (
    <div>
      <div className={classes.div}>
        <div className={classes.inline}>
          {t('view:projects.storage_usage')}
          {usageMB} MB / {storageSize}
        </div>
        {progressBar}
      </div>
      <div className={classes.div}>
        {t('view:projects.project_num')} {projectNum} / {maxProjectNum}
      </div>
    </div>
  );
}

export default UsageContainer;
