import {
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import _ from 'lodash';
import { useRef } from 'react';
import * as CL2Types from '../../../CL2Types';
import { t } from '../../../i18n';
import FileDialog from './FileDialog';

const BreadCrumbButton = withStyles({
  root: {
    textTransform: 'none',
  },
})(Button);

const useStyles = makeStyles({
  paper: {
    width: '75%',
    maxWidth: 576,
  },
});

export function FileMoveDialog({
  open,
  files,
  current,
  fileDialogOpen,
  selected,
  waiting,
  changeMoveDialog,
  handleMoveFile,
}: CL2Types.MoveDialog & {
  selected: CL2Types.ProjectFile;
  waiting: boolean;
  changeMoveDialog: (payload: Partial<CL2Types.MoveDialog>) => void;
  handleMoveFile: (from: number, to: number) => Promise<void>;
}) {
  const classes = useStyles();
  const flattenFiles = Object.values(files).flat();
  const breadcrumbs = [current];
  while (breadcrumbs.at(0)) {
    const parent = _.find(flattenFiles, { id: breadcrumbs.at(0).belonging_to });
    if (breadcrumbs.includes(parent)) {
      break;
    }
    breadcrumbs.unshift(parent);
  }
  const dialogRef = useRef({ dialog: null });

  return (
    <>
      <Dialog
        open={open}
        onClose={() => changeMoveDialog({ open: false })}
        classes={classes}
      >
        <DialogTitle>
          <h3>{t('view:editor.move_dialog.file_move')}</h3>
          <Breadcrumbs maxItems={4} itemsAfterCollapse={2}>
            {breadcrumbs
              .map((file) => [
                <BreadCrumbButton
                  onClick={() => changeMoveDialog({ current: file })}
                >
                  {file?.name || 'Workspace'}
                </BreadCrumbButton>,
                '/',
              ])
              .flat()
              .slice(0, -1)}
          </Breadcrumbs>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <List>
            {(files ?? {})[current?.id || 0]
              ?.filter((file) => file.is_folder)
              .map((file) => (
                <ListItem
                  button
                  onClick={() => changeMoveDialog({ current: file })}
                >
                  <ListItemIcon>
                    <span className="fa fa-folder-o" />
                  </ListItemIcon>
                  <ListItemText>{file.name}</ListItemText>
                </ListItem>
              ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => changeMoveDialog({ open: false })}
          >
            {t('view:editor.cancel')}
          </Button>
          <Button onClick={() => changeMoveDialog({ fileDialogOpen: true })}>
            {t('view:editor.create_folder')}
          </Button>
          <Button
            color="primary"
            onClick={() =>
              handleMoveFile(selected.id, current?.id ?? null).then(() =>
                changeMoveDialog({ open: false }),
              )
            }
          >
            {t('view:editor.move_dialog.do_move')}
          </Button>
        </DialogActions>
      </Dialog>
      <FileDialog
        dialogRef={dialogRef.current}
        open={fileDialogOpen}
        isFolder
        onClose={() => changeMoveDialog({ fileDialogOpen: false })}
        belonging={current?.id}
        waiting={waiting}
      />
    </>
  );
}
