import * as React from 'react';
import * as Colors from '@material-ui/core/colors';
import * as ColorManipulator from '@material-ui/core/styles/colorManipulator';
import qs from 'query-string';
import {
  AppBar,
  Button,
  Drawer,
  Icon,
  IconButton,
  Paper,
} from '@material-ui/core';
import { withStyles, Theme, alpha } from '@material-ui/core/styles';
import {
  CSSProperties,
  ClassNameMap,
  Styles,
} from '@material-ui/core/styles/withStyles';
import * as _ from 'lodash';
import CLLogo from '../CLLogo';
import TopActions from '../../actions/TopActions';
import TopStore from '../../stores/TopStore';
import { Root } from '../../constants/CommonConstants';
import { t } from '../../i18n';

const baseHeight = 48;

const transparentAttrs = {
  className: 'header_transparent',
  elevation: 0,
};
const solidAttrs = {
  className: 'header_solid',
  elevation: 1,
};

const MyPageBtn = (function () {
  const rootBaseSty = { fontSize: '14px', lineHeight: 1.7142857 };
  const rootSty = {
    backgroundColor: 'transparent',
    '&:hover': { backgroundColor: 'transparent!important' },
  };
  _.assign(rootSty, rootBaseSty);
  const labelSty: CSSProperties = { color: '#616161', textTransform: 'none' };
  return withStyles({ root: rootSty, label: labelSty })(Button);
})();

const { SignInBtn, SignUpBtn } = (function () {
  const accentColor = Colors.lightBlue['600'];
  const hoverColor = alpha(ColorManipulator.lighten(accentColor, 0.4), 0.15);
  const rootBaseSty = { fontSize: '14px', lineHeight: 1.7142857 };
  const rootSty = {
    backgroundColor: 'transparent',
    '&:hover': { backgroundColor: `${hoverColor}!important` },
  };
  _.assign(rootSty, rootBaseSty);
  const labelSty = { color: accentColor };
  const SignInBtn = withStyles({ root: rootBaseSty })(Button);
  const SignUpBtn = withStyles({ root: rootSty, label: labelSty })(Button);
  return { SignInBtn, SignUpBtn };
})();

const styles: Styles<Theme, {}> = (theme: Theme) => ({
  menuItem: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  menuSection: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    height: `${baseHeight}px`,
    display: 'grid',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  },
  sectionMobile: {
    display: 'inline-block',
    placeSelf: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  mobileMenuIcon: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  mobileDrawerMenu: {
    width: 200,
    maxWidth: '80vw',
  },
});

interface Props {
  classes: ClassNameMap;
}
interface State {
  headerAttributes: {
    className: string;
    elevation: number;
  };
  mobileMenuOpen: boolean;
}

class Header extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      headerAttributes: {
        className: '', // DUMMY
        elevation: 24, // DUMMY
      },
      mobileMenuOpen: false,
    };
    this._handleSigninDialog = this._handleSigninDialog.bind(this);
    this._toggleMobileMenu = this._toggleMobileMenu.bind(this);
  }

  render() {
    const classes = this.props.classes;

    const userButtons = window['user'] // FIXME
      ? [
          {
            key: 'projects',
            btn: (
              <MyPageBtn href={`${Root}/projects`}>
                {t('view:user.my_page')}
              </MyPageBtn>
            ),
          },
        ]
      : [
          {
            key: 'sign_in',
            btn: (
              <SignInBtn onClick={this._handleSigninDialog}>
                {t('view:user.sign_in')}
              </SignInBtn>
            ),
          },
          {
            key: 'sign_up',
            btn: (
              <SignUpBtn href={`${Root}/users/sign_up`}>
                {t('view:user.sign_up')}
              </SignUpBtn>
            ),
          },
        ];
    const padSty = { padding: '6px 0px' };
    const linkSty: React.CSSProperties = {
      display: 'inline-flex',
      padding: '6px 8px',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      textTransform: 'uppercase',
      lineHeight: 1.7142857,
    };
    const iconSty: React.CSSProperties = {
      verticalAlign: 'top',
      fontSize: '18px',
      position: 'relative',
      top: '3px',
    };
    const helpMenu = (
      <div style={padSty}>
        <a style={linkSty} href="/help">
          <span>
            <Icon style={iconSty}>help</Icon>
            <span>{t('view:header.help')}</span>
          </span>
        </a>
      </div>
    );
    const queryParam = qs.parse(location.search);
    const languageMenu = (
      <div className="dropdown" style={padSty}>
        <a
          className="dropdown-toggle"
          style={linkSty}
          data-toggle="dropdown"
          href="#"
        >
          <span>
            <Icon style={iconSty}>language</Icon>
            <span>{t('view:header.language.language')}</span>
            <span className="caret" />
          </span>
        </a>
        <ul className="dropdown-menu dropdown-menu-right">
          {['english', 'japanese'].map((language) => {
            const locale = language.slice(0, 2);
            const isSetLocale = window['locale'] === locale;
            const style = {};
            const newQueryParam = {
              ...queryParam,
              locale,
            };

            if (isSetLocale) {
              _.assign(style, {
                opacity: 0.5,
                cursor: 'default',
                pointerEvents: 'none',
                backgroundColor: 'transparent',
              });
            }

            return (
              <li key={language}>
                <a
                  href={`?${qs.stringify(newQueryParam)}`}
                  aria-disabled={isSetLocale}
                  aria-selected={isSetLocale}
                  style={style}
                >
                  {t(`view:header.language.${language}`)}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
    const userMenu = userButtons.map((item) => (
      <div key={item.key} style={padSty}>
        {item.btn}
      </div>
    ));
    const appBar = (
      <AppBar
        className="container"
        elevation={0}
        position="static"
        style={{
          flexFlow: 'row wrap',
          background: 'none',
          fontFamily: 'Roboto,sans-serif',
        }}
      >
        <div style={{ flexGrow: 0, height: `${baseHeight}px`, padding: '1px' }}>
          <CLLogo linkToTop />
        </div>
        <div className={classes.menuSection}>
          <div className={classes.sectionDesktop}>
            {helpMenu}
            {languageMenu}
            {userMenu}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-haspopup="true"
              onClick={this._toggleMobileMenu(true)}
              color="inherit"
              className={classes.mobileMenuIcon}
            >
              <Icon>menu</Icon>
            </IconButton>
            <Drawer
              anchor="right"
              open={this.state.mobileMenuOpen}
              onClose={this._toggleMobileMenu(false)}
            >
              <div className={classes.mobileDrawerMenu}>
                {helpMenu}
                {languageMenu}
                {userMenu}
              </div>
            </Drawer>
          </div>
        </div>
      </AppBar>
    );
    if (this.state.headerAttributes.elevation === 0) {
      return (
        <Paper
          className={this.state.headerAttributes.className}
          elevation={this.state.headerAttributes.elevation}
        >
          {appBar}
        </Paper>
      );
    }
    const specialShadow =
      '0px 1px 6px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.12)';
    return (
      <Paper
        className={this.state.headerAttributes.className}
        style={{ boxShadow: specialShadow }}
      >
        {appBar}
      </Paper>
    );
  }

  componentDidMount() {
    if ($('body.home.index').length) {
      window.addEventListener('scroll', this._onScroll.bind(this));
      this.setState({ headerAttributes: transparentAttrs });
    } else {
      this.setState({ headerAttributes: solidAttrs });
    }
  }

  componentWillUnMount() {
    if ($('body.home.index').length) {
      window.removeEventListener('scroll', this._onScroll.bind(this));
    }
  }

  _onScroll(ev) {
    const height =
      document.documentElement.scrollTop || document.body.scrollTop;
    const topContainerHeight = $('#top_container').outerHeight();
    this.setState({
      headerAttributes:
        height < topContainerHeight - baseHeight
          ? transparentAttrs
          : solidAttrs,
    });
  }

  _handleSigninDialog() {
    TopActions.openSignInDialog();
  }
  // _handleSignupDialog() {
  //   TopActions.openSignUpDialog();
  // }

  _toggleMobileMenu(open: boolean) {
    return () => this.setState({ mobileMenuOpen: open });
  }
}

export default withStyles(styles)(Header);
