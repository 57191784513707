import { useState, useCallback, useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import { Icon, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  scrollToTopButton: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    color: 'gray',
    background: 'white',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      background: 'white',
    },
  },
  arrowUpwardIcon: {
    fontSize: '4rem',
  },
});

export function ScrollToTopButton() {
  if (!window['top_page2']) return null;
  const [scrollPosition, setScrollPosition] = useState(0);
  const onScroll = useCallback(() => {
    setScrollPosition(window.scrollY);
  }, []);
  const onClick = useCallback(() => {
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    onScroll();
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);
  const classes = useStyles();
  return scrollPosition > 0
    ? ReactDOM.createPortal(
        <div>
          <IconButton
            className={classes.scrollToTopButton}
            onClick={onClick}
            aria-label="scroll to top"
          >
            <Icon className={classes.arrowUpwardIcon}>arrow_upward</Icon>
          </IconButton>
        </div>,
        document.body,
      )
    : null;
}
