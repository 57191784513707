// Based on codemirror-spell-checker https://github.com/sparksuite/codemirror-spell-checker
// Use strict mode (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Strict_mode)

import * as Typo from 'typo-js';
import {
  Decoration,
  DecorationSet,
  EditorView,
  PluginValue,
  ViewPlugin,
  ViewUpdate,
} from '@codemirror/view';
import { RangeSetBuilder } from '@codemirror/state';

const BUCKET_URL =
  'https://cloudlatex-public-resources.s3-ap-northeast-1.amazonaws.com';
const AFF_URL = `${BUCKET_URL}/spell_check_dict/en_US.aff`;
const DIC_URL = `${BUCKET_URL}/spell_check_dict/en_US.dic`;
const CUSTOM_DIC_URL = `${BUCKET_URL}/spell_check_dict/CL2_custom_whitelist.txt`;

class TrieTreeNode {
  private children: object;

  constructor() {
    this.children = {};
  }

  public insert(word: string) {
    if (word.length === 0) {
      this.children[''] = new TrieTreeNode();
      return;
    }
    const prefix = word[0];
    if (!(prefix in this.children)) {
      this.children[prefix] = new TrieTreeNode();
    }
    this.children[prefix].insert(word.slice(1));
  }

  public contains(word: string): boolean {
    if (word.length === 0) return '' in this.children;
    const prefix = word[0];
    if (prefix in this.children) {
      return this.children[prefix].contains(word.slice(1));
    }
    return false;
  }
}

class TrieTreeParser {
  private root: TrieTreeNode;

  private ignoreCase: boolean;

  constructor() {
    this.root = new TrieTreeNode();
    this.ignoreCase = true;
  }

  public loadDictionary(wordList: string) {
    const words = wordList.split('\n');
    words.forEach((word) => {
      if (this.ignoreCase) {
        word = word.toLowerCase();
      }
      this.root.insert(word);
    });
  }

  public contains(word: string) {
    if (this.ignoreCase) {
      word = word.toLowerCase();
    }
    return this.root.contains(word);
  }
}

export function spellCheckerPlugin(): ViewPlugin<PluginValue> {
  const token = /[^!?",.; \n]+/g;
  const word = /^[a-zA-Z]+$/;
  const deco = Decoration.mark({
    class: 'cm-spell-error',
  });

  return ViewPlugin.fromClass(
    class {
      decorations: DecorationSet;

      // Initialize data globally to reduce memory consumption
      private numLoaded: number = 0;

      private affLoading: boolean = false;

      private dicLoading: boolean = false;

      private customDictLoading: boolean = false;

      private affData: string = '';

      private dicData: string = '';

      private customWordList: string = '';

      private typo: Typo | undefined;

      // Modified from codemirror-spell-checker
      // Add custom dictionary
      private customDictTree: TrieTreeParser = null;

      private view: EditorView;

      constructor(view) {
        this.decorations = this.mkDeco(view);
        this.view = view;

        this.initialize();
      }

      public initialize = () => {
        // Initialize

        // Because some browsers don't support this functionality yet
        if (!String.prototype.includes) {
          String.prototype.includes = function () {
            return String.prototype.indexOf.apply(this, arguments) !== -1;
          };
        }

        // Load AFF/DIC data
        if (!this.affLoading) {
          this.affLoading = true;
          const xhrAff = new XMLHttpRequest();
          xhrAff.open('GET', AFF_URL, true);
          xhrAff.onload = () => {
            if (xhrAff.readyState === 4 && xhrAff.status === 200) {
              this.affData = xhrAff.responseText;
              this.numLoaded += 1;

              if (this.numLoaded === 2) {
                this.typo = new Typo('en_US', this.affData, this.dicData, {
                  platform: 'any',
                });
                this.refresh();
              }
            }
          };
          xhrAff.send(null);
        }

        if (!this.dicLoading) {
          this.dicLoading = true;
          const xhrDic = new XMLHttpRequest();
          xhrDic.open('GET', DIC_URL, true);
          xhrDic.onload = () => {
            if (xhrDic.readyState === 4 && xhrDic.status === 200) {
              this.dicData = xhrDic.responseText;
              this.numLoaded += 1;

              if (this.numLoaded === 2) {
                this.typo = new Typo('en_US', this.affData, this.dicData, {
                  platform: 'any',
                });
                this.refresh();
              }
            }
          };
          xhrDic.send(null);
        }

        if (!this.customDictLoading) {
          this.customDictLoading = true;

          const loadAndRefresh = (response: string) => {
            this.customDictTree = new TrieTreeParser();
            this.customDictTree.loadDictionary(response);
            this.refresh();
          };

          const xhrCustomDic = new XMLHttpRequest();
          xhrCustomDic.open('GET', CUSTOM_DIC_URL, true);
          xhrCustomDic.onload = () => {
            if (xhrCustomDic.readyState === 4 && xhrCustomDic.status === 200) {
              this.customWordList = xhrCustomDic.responseText;
              loadAndRefresh(this.customWordList);
            }
          };

          xhrCustomDic.send(null);
        }
      };

      update(update: ViewUpdate) {
        if (
          update.viewportChanged ||
          update.docChanged ||
          update.transactions.find((tr) =>
            tr.isUserEvent('spellChecker.refresh'),
          )
        ) {
          this.decorations = this.mkDeco(update.view);
        }
        this.view = update.view;
      }

      mkDeco(view: EditorView) {
        const rangeSetBuilder = new RangeSetBuilder<Decoration>();
        for (const { from, to } of view.visibleRanges) {
          const range = view.state.sliceDoc(from, to);
          let m;
          while ((m = token.exec(range))) {
            if (m[0].match(word)) {
              if (
                this.typo &&
                !this.typo.check(m[0]) &&
                (!this.customDictTree || !this.customDictTree.contains(m[0]))
              ) {
                rangeSetBuilder.add(
                  from + m.index,
                  from + m.index + m[0].length,
                  deco,
                );
              }
            }
          }
        }
        return rangeSetBuilder.finish();
      }

      refresh() {
        this.decorations = this.mkDeco(this.view);
        this.view.dispatch({
          userEvent: 'spellChecker.refresh',
        });
      }
    },
    {
      decorations: (v) => v.decorations,
      provide: (v) => [
        EditorView.theme({
          'span.cm-spell-error': {
            borderBottom: 'dotted 2px #87CEFA',
          },
        }),
      ],
    },
  );
}
