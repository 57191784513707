import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import * as Colors from '@material-ui/core/colors';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import ProjectsActions from '../../../actions/ProjectsActions';
import EditorActions from '../../../actions/EditorActions';
import Loading from '../../Loading';
import { t } from '../../../i18n';
import * as CL2Types from '../../../CL2Types';

const TableHeadColumn = TableCell;
const TableRowColumn = TableCell;

interface Props {
  projectId: number;
  files: Array<CL2Types.ProjectFile>;
  loading: boolean;
  onClose: () => void;
  open: boolean;
}
interface State {
  selectedRow: number;
}
class SelectTargetDialog extends React.Component<Props, State> {
  static _theme = (outerTheme) => {
    const margin = 64;
    return createTheme(
      {
        overrides: {
          MuiDialog: {
            paper: { margin: `${String(margin)}px` },
            paperScrollPaper: {
              maxHeight: `calc(100% - ${String(2 * margin)}px)`,
            },
          },
          MuiTableCell: {
            root: { padding: '0px 24px' },
            head: {
              fontSize: '12px',
              color: Colors.grey['500'],
              fontWeight: 'normal',
            },
            body: {
              fontSize: '13px',
              fontWeight: 300,
            },
          },
          MuiIconButton: {
            root: { padding: '12px 12px 11px' },
          },
          MuiRadio: {
            root: {
              color: outerTheme.palette.text.primary,
              '&$checked': { color: Colors.cyan['500'] },
            },
            checked: {},
          },
          MuiButton: {
            textPrimary: { color: Colors.cyan['500'] },
          },
        },
      },
      outerTheme,
    );
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedRow: 0,
    };
  }

  render() {
    const actions = [
      <Button
        key="import"
        children={
          <span style={{ fontSize: '14px' }}>
            {t('view:projects.set_target')}
          </span>
        }
        color="primary"
        onClick={this._onSelect}
      />,
    ];

    const checkboxStyle: React.CSSProperties = {
      position: 'relative',
      right: '12px',
    };

    return (
      <MuiThemeProvider theme={SelectTargetDialog._theme}>
        <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          PaperProps={{ style: { width: '75%', maxWidth: '576px' } }}
          onKeyDown={this._onKeyDown}
        >
          <DialogTitle
            key="title"
            disableTypography
            children={
              <h3
                style={{
                  margin: 'unset',
                  fontSize: '22px',
                  fontWeight: 400,
                  lineHeight: '32px',
                }}
              >
                {t('view:projects.select_target')}
              </h3>
            }
          />
          {this.props.loading ? (
            <DialogContent key="content_loading">
              <Loading />
            </DialogContent>
          ) : (
            <DialogContent key="content">
              <Table
                id="files_table_in_select_target_file_dialog"
                className="files_table"
                style={{ tableLayout: 'fixed' }}
              >
                <TableHead>
                  <TableRow>
                    <TableHeadColumn style={{ width: '48px', zIndex: 1 }} />
                    <TableHeadColumn style={{ width: '100%' }}>
                      <span>{t('view:projects.file_title')}</span>
                    </TableHeadColumn>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(() => {
                    let counter = -1;
                    return this.props.files.map((tex_file) => {
                      counter += 1;
                      const isSelectedRow = counter === this.state.selectedRow;
                      return (
                        <TableRow
                          key={tex_file.id}
                          hover
                          selected={isSelectedRow}
                          onClick={(
                            (row) => (e) =>
                              this._onRowSelection(row)
                          )(counter)}
                        >
                          <TableRowColumn>
                            <Radio
                              checked={isSelectedRow}
                              color="default"
                              style={checkboxStyle}
                            />
                          </TableRowColumn>
                          <TableRowColumn>
                            <span>{tex_file.full_path}</span>
                          </TableRowColumn>
                        </TableRow>
                      );
                    });
                  })()}
                </TableBody>
              </Table>
            </DialogContent>
          )}
          <DialogActions
            key="actions"
            children={actions}
            style={{ margin: '8px' }}
          />
        </Dialog>
      </MuiThemeProvider>
    );
  }

  private _onKeyDown = (e) => {
    if (e.key === 'Enter') {
      this._onSelect();
    }
  };

  private _onSelect = () => {
    if (this.state.selectedRow < 0) return;
    const row = this.state.selectedRow;
    ProjectsActions.setCompileTarget(
      this.props.projectId,
      this.props.files[row].id,
    ).then(() => {
      this.props.onClose();
      EditorActions.loadProjectInfo();
    });
  };

  private _onRowSelection = (row) => {
    if (row === this.state.selectedRow) {
      row = -1;
    }
    this.setState({
      selectedRow: row,
    });
  };
}

export default SelectTargetDialog;
