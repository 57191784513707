import {
  defaultHighlightStyle,
  HighlightStyle,
  syntaxHighlighting,
} from '@codemirror/language';
import { tags } from '@lezer/highlight';
import { EditorView } from '@codemirror/view';

const light = syntaxHighlighting(defaultHighlightStyle);

export const cloudlatexDefault = [
  EditorView.theme({
    '&': {
      color: '#555',
    },
  }),
  syntaxHighlighting(
    HighlightStyle.define([
      { tag: tags.bracket, color: '#ffac00' },
      { tag: tags.tagName, color: '#c41a16' },
    ]),
  ),
  light,
];
