import { Component } from 'react';
import Header from './Header';
import SignInDialog from './SignInDialog';
import TopStore from '../../stores/TopStore';
import { ScrollToTopButton } from './ScrollToTopButton';
import { withErrorBoundaryFallback } from '../error/withErrorBoundaryFallback';
import { withRootTheme } from '../withRootTheme';

interface State {
  signupOpen: boolean;
  signinOpen: boolean;
}
class HomeApp extends Component<{}, State> {
  constructor(props) {
    super(props);
    this.state = {
      signupOpen: false,
      signinOpen: false,
    };
    this._onChange = this._onChange.bind(this);
  }

  componentDidMount() {
    TopStore.addChangeListener(this._onChange);
  }

  componentWillUnMount() {
    TopStore.removeChangeListener(this._onChange);
  }

  render() {
    return (
      <div>
        <Header />
        <div id="dialog_container">
          <SignInDialog open={this.state.signinOpen} />
        </div>
        <ScrollToTopButton />
      </div>
    );
  }

  _onChange() {
    this.setState(TopStore.getStates());
  }
}

export default withErrorBoundaryFallback(withRootTheme(HomeApp));
