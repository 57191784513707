import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles({
  input: {
    fontSize: 14,
  },
  option: {
    fontSize: 14,
  },
  tag: {
    fontSize: 12,
  },
});

// TODO: ユーザーのプロジェクトのタグの実装時にその実装に合わせてリファクタリングする
export function TagsInput({ name, value, options, onChange }) {
  const classes = useStyles();

  return (
    <Autocomplete
      multiple
      classes={classes}
      options={options}
      value={value}
      freeSolo
      filterSelectedOptions
      getOptionSelected={(option, value) => option.id === value.id}
      disableClearable
      onChange={onChange}
      size="small"
      renderTags={(value: { label: string; id: number }[], getTagProps) =>
        value.map((option, index) => (
          <React.Fragment key={option.id}>
            <Chip
              size="small"
              variant="outlined"
              label={option.label}
              {...getTagProps({ index })}
            />
            <input type="hidden" value={option.id} name={`${name}[]`} />
          </React.Fragment>
        ))
      }
      renderInput={(params) => <TextField variant="outlined" {...params} />}
      getOptionLabel={(option) => option.label}
    />
  );
}
