enum EditorConstants {
  LOAD_PROJECT_INFO = 'LOAD_PROJECT_INFO',
  RECEIVE_PROJECT_INFO = 'RECEIVE_PROJECT_INFO',
  RECEIVE_USER_INFO = 'RECEIVE_USER_INFO',
  EXPORT_PROJECT = 'EXPORT_PROJECT',
  RECEIVE_EXPORT_PROJECT = 'RECEIVE_EXPORT_PROJECT',
  LOAD_FILES = 'LOAD_FILES',
  RECEIVE_LOAD_FILES = 'RECEIVE_LOAD_FILES',
  OPEN_FILE_DIALOG = 'OPEN_FILE_DIALOG',
  CLOSE_FILE_DIALOG = 'CLOSE_FILE_DIALOG',
  // OPEN_CONTEXT_MENU = 'OPEN_CONTEXT_MENU',
  // CLOSE_CONTEXT_MENU = 'CLOSE_CONTEXT_MENU',
  COMPILE = 'COMPILE',
  SEARCH_TEX_DOCUMENT = 'SEARCH_TEX_DOCUMENT',
  REQUEST_SAVE = 'REQUEST_SAVE',
  DONE_SAVE = 'DONE_SAVE',
  FAILED_SAVE = 'FAILED_SAVE',
  DONE_COMPILE = 'DONE_COMPILE',
  FAILED_COMPILE = 'FAILED_COMPILE',
  DONE_SEARCH_TEX_DOCUMENT = 'DONE_SEARCH_TEX_DOCUMENT',
  ERR_SEARCH_TEX_DOCUMENT = 'ERR_SEARCH_TEX_DOCUMENT',
  REQUEST_DELETE = 'REQUEST_DELETE',
  DONE_DELETE = 'DONE_DELETE',
  FAILED_DELETE = 'FAILED_DELETE',
  REQUEST_CREATE = 'REQUEST_CREATE',
  DONE_CREATE = 'DONE_CREATE',
  FAILED_CREATE = 'FAILED_CREATE',
  COMPILE_WAITING = 'COMPILE_WAITING',
  LOAD_FILE_VERSIONS = 'LOAD_FILE_VERSIONS',
  RECEIVE_LOAD_FILE_VERSIONS = 'RECEIVE_LOAD_FILE_VERSIONS',
  RESTORE_FILE = 'RESTORE_FILE',
  RECEIVE_RESTORE_FILE = 'RECEIVE_RESTORE_FILE',
  SEND_OPEN_FILE = 'SEND_OPEN_FILE',
  OPEN_FILE = 'OPEN_FILE',
  CLOSE_FILE = 'CLOSE_FILE',
  FETCH_FILE = 'FETCH_FILE',
  OPEN_BINARY_FILE = 'OPEN_BINARY_FILE',
  CLOSE_BINARY_FILE = 'CLOSE_BINARY_FILE',
  START_UPLOAD_SESSION = 'START_UPLOAD_SESSION',
  START_UPLOAD = 'START_UPLOAD',
  PROGRESS_UPLOAD = 'PROGRESS_UPLOAD',
  FAIL_UPLOAD = 'FAIL_UPLOAD',
  DONE_UPLOAD = 'DONE_UPLOAD',
  FINISH_UPLOAD = 'FINISH_UPLOAD',
  FINISH_UPLOAD_SESSION = 'FINISH_UPLOAD_SESSION',
  CLOSE_SNACKBAR = 'CLOSE_SNACKBAR',
  FAILED_LOAD = 'FAILED_LOAD',
  OPEN_REFRESH_DIALOG = 'OPEN_REFRESH_DIALOG',
  CLOSE_REFRESH_DIALOG = 'CLOSE_REFRESH_DIALOG',
  // OPEN_CONFLICT_FILE_DIALOG = 'OPEN_CONFLICT_FILE_DIALOG',
  CLOSE_CONFLICT_FILE_DIALOG = 'CLOSE_CONFLICT_FILE_DIALOG',
  API_ERROR = 'API_ERROR',
  FILE_CONFLICT = 'FILE_CONFLICT',
  RESOLVE_CONFLICT = 'RESOLVE_CONFLICT',
  RESOLVE_FINISH = 'RESOLVE_FINISH',
  RESET_RETRY = 'RESET_RETRY',
  SHOW_SNACKBAR = 'SHOW_SNACKBAR',
  CLOSE_ERROR_DIALOG = 'CLOSE_ERROR_DIALOG',
  MULTIPLE_COMPILE = 'MULTIPLE_COMPILE',
  PDFVIEW_SETPAGE = 'PDFVIEW_SETPAGE',
  NETWORK = 'NETWORK',
  RECEIVE_SYNCTEX_OBJECT = 'RECEIVE_SYNCTEX_OBJECT',
  SHOW_SYNCTEX_PDF_INDICATOR = 'SHOW_SYNCTEX_PDF_INDICATOR',
  HIDE_SYNCTEX_PDF_INDICATOR = 'HIDE_SYNCTEX_PDF_INDICATOR',
  SHOW_SYNCTEX_EDITOR_INDICATOR = 'SHOW_SYNCTEX_EDITOR_INDICATOR',
  HIDE_SYNCTEX_EDITOR_INDICATOR = 'HIDE_SYNCTEX_EDITOR_INDICATOR',
  SET_EDITOR_POSITION = 'SET_EDITOR_POSITION',
  CHANGE_TAB = 'CHANGE_TAB',
  SET_BIBTEX_ITEMS = 'SET_BIBTEX_ITEMS',
  CHANGE_COUNT_DIALOG = 'CHANGE_COUNT_DIALOG',
  CHANGE_SHARE_DIALOG = 'CHANGE_SHARE_DIALOG',
  REQUEST_SHARE = 'REQUEST_SHARE',
  SUCCESS_SHARE = 'SUCCESS_SHARE',
  FAILURE_SHARE = 'FAILURE_SHARE',
  SHOW_PDF_VIEW_LOADING = 'SHOW_PDF_VIEW_LOADING',
  OPEN_SELECT_TARGET_DIALOG = 'OPEN_SELECT_TARGET_DIALOG',
  CLOSE_SELECT_TARGET_DIALOG = 'CLOSE_SELECT_TARGET_DIALOG',
  OPEN_INVALIDATE_FILE_CACHE_DIALOG = 'OPEN_INVALIDATE_FILE_CACHE_DIALOG',
  REQUEST_INVALIDATE_FILE_CACHE = 'REQUEST_INVALIDATE_FILE_CACHE',
  SUCCESS_INVALIDATE_FILE_CACHE = 'SUCCESS_INVALIDATE_FILE_CACHE',
  FAILURE_INVALIDATE_FILE_CACHE = 'FAILURE_INVALIDATE_FILE_CACHE',
  CLOSE_SUCCESS_INVALIDATE_FILE_CACHE_DIALOG = 'CLOSE_SUCCESS_INVALIDATE_FILE_CACHE_DIALOG',
  PDF_LOADED = 'PDF_LOADED',
  PDF_LOAD_STARTED = 'PDF_LOAD_STARTED',
  CHANGE_MOVE_DIALOG = 'CHANGE_MOVE_DIALOG',
  OVERWRITE = 'OVERWRITE',
}

export enum FileSource {
  S3 = 0,
  DROPBOX,
}

export enum CompileMode {
  LATEX = 0,
  PANDOC = 1,
  RTEX = 2,
}

export const TeXLiveVersion = 2023;

export default EditorConstants;
