import './index.scss';

document.addEventListener('DOMContentLoaded', () => {
  if (
    document.body.classList.contains('comfy-cms-content') ||
    document.body.classList.contains('comfy-admin-cms-pages')
  ) {
    import('./katex').then((module) => {
      const article = document.querySelector('article');
      if (article) {
        module.renderMathInElement(document.querySelector('article'));
      }
    });
  }

  const sideMenu = document.querySelector('#side_menu');
  if (sideMenu) {
    document.querySelectorAll('.toggle-side-menu').forEach((elem) => {
      elem.addEventListener('click', () => {
        sideMenu.classList.toggle('expanded');
      });
    });
  }
});

export default {};
