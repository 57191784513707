import * as React from 'react';
import { Icon, Tooltip } from '@material-ui/core';
import * as _ from 'lodash';

interface Props {
  mdiIconName: string;
  color: string;
  style: React.CSSProperties;
  tooltip: 'error' | 'success' | string;
}
class TooltipFontIcon extends React.Component<Props, {}> {
  constructor(props) {
    super(props);
  }

  render() {
    const { tooltip, mdiIconName, color, ...other_ } = this.props;
    const other = other_ || { style: null };
    const iconStyle: React.CSSProperties = other.style
      ? _.clone(other.style)
      : {};
    iconStyle.color = color;
    other.style = iconStyle;
    //      placement='top-right'
    return (
      <Tooltip
        title={
          <span
            style={{
              fontFamily: 'Roboto, sans-serif',
              fontSize: '10px',
              fontWeight: 'normal',
              lineHeight: 1.4,
            }}
          >
            {tooltip}
          </span>
        }
        placement="top-start"
      >
        <Icon {...other}>{mdiIconName}</Icon>
      </Tooltip>
    );
  }
}

export default TooltipFontIcon;
