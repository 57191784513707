import { Component } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as Colors from '@material-ui/core/colors';
import Loading from './Loading';
import { t } from '../i18n';

interface Props {
  open: boolean;
  onClose: (e) => void;
  onRequestExec: (e) => void;
  waiting: boolean;
  execLabel: string;
  title: string;
  disabled: boolean;
  PaperProps: object;
}

const ButtonEx = withStyles({
  textPrimary: {
    color: Colors.cyan['500'],
    '&:hover': {
      backgroundColor: 'rgba(153, 153, 153, 0.2)',
    },
  },
  textSecondary: {
    color: Colors.pink.A200,
    '&:hover': {
      backgroundColor: 'rgba(153, 153, 153, 0.2)',
    },
  },
  focusVisible: {
    backgroundColor: 'rgba(153, 153, 153, 0.2)',
  },
})(Button);

class LoadingDialog extends Component<Props, {}> {
  constructor(props) {
    super(props);
  }

  _onRequestExec(e: Event) {
    this.props.onRequestExec(e);
  }

  render() {
    const {
      children,
      disabled,
      execLabel,
      onClose,
      onRequestExec,
      title,
      waiting,
      ...other
    } = this.props;

    const buttonStyle = {
      fontSize: '14px',
      fontWeight: 500,
      minWidth: '88px',
      margin: '0px',
    };
    const rippleProps = { style: { color: '#999999' } };

    const actions = [
      <ButtonEx
        key="cancel"
        onClick={onClose}
        color="secondary"
        style={buttonStyle}
        TouchRippleProps={rippleProps}
        disableTouchRipple
      >
        {t('view:editor.cancel')}
      </ButtonEx>,
      <ButtonEx
        color="primary"
        style={buttonStyle}
        TouchRippleProps={rippleProps}
        disableTouchRipple
        key="exec"
        onClick={onRequestExec}
        disabled={disabled}
      >
        {execLabel}
      </ButtonEx>,
    ];

    const content = waiting ? [children, <Loading key="0" />] : children;

    return (
      <Dialog onClose={onClose} {...other}>
        <DialogTitle
          key="title"
          disableTypography
          children={
            <h3
              style={{
                margin: '0px',
                fontSize: '22px',
                fontWeight: 400,
                lineHeight: '32px',
              }}
            >
              {title}
            </h3>
          }
        />
        <DialogContent
          key="content"
          style={{ padding: '16px 24px 32px' }}
          children={content}
        />
        <DialogActions
          key="actions"
          style={{ margin: '8px' }}
          children={actions}
        />
      </Dialog>
    );
  }
}

export default LoadingDialog;
