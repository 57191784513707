import parse from 'html-react-parser';
import * as React from 'react';

interface Props {
  className: string;
  style: React.CSSProperties;
  content: string;
}

class CMSContainer extends React.Component<Props, {}> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={this.props.className} style={this.props.style}>
        {parse(this.props.content)}
      </div>
    );
  }
}

export default CMSContainer;
