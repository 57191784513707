import AppDispatcher from '../dispatcher/AppDispatcher';
import ProjectsWebAPIUtils from '../utils/ProjectsWebAPIUtils';
import UserWebAPIUtils from '../utils/UserWebAPIUtils';
import TemplatesWebAPIUtils from '../utils/TemplatesWebAPIUtils';

import ProjectsConstants from '../constants/ProjectsConstants';
import { t } from '../i18n';

import * as CL2Types from '../CL2Types';

const ProjectsActions = {
  loadProjects(
    currentPage: number = null,
    query: string = null,
    sortKey: string = null,
    sortOrder: CL2Types.SortOrder = null,
  ) {
    AppDispatcher.dispatch({
      actionType: ProjectsConstants.LOAD_PROJECTS,
    });
    return Promise.all([
      ProjectsWebAPIUtils.loadProjects(currentPage, query, sortKey, sortOrder),
      UserWebAPIUtils.loadUserUsage(),
    ]);
  },

  loadProjectsWithPage(currentPage: number) {
    return this.loadProjects(currentPage);
  },

  loadProjectsWithQuery(query: string) {
    return this.loadProjects(null, query);
  },

  loadProjectsWithSort(sortKey: string, sortOrder: CL2Types.SortOrder) {
    return this.loadProjects(null, null, sortKey, sortOrder);
  },

  loadProjectsWithSortOrder(sortOrder: CL2Types.SortOrder) {
    return this.loadProjects(null, null, null, sortOrder);
  },

  createProject(
    title: string,
    description: string,
    template: string,
    file: CL2Types.UploadFile,
    compiler?: string,
  ) {
    AppDispatcher.dispatch({
      actionType: ProjectsConstants.CREATE_PROJECT,
    });
    return ProjectsWebAPIUtils.createProject(
      title,
      description,
      template,
      file,
      compiler,
    );
  },
  deleteProject(id: number) {
    AppDispatcher.dispatch({
      actionType: ProjectsConstants.DELETE_PROJECT,
    });
    return ProjectsWebAPIUtils.deleteProject(id);
  },
  deleteProjects(ids: number[]) {
    AppDispatcher.dispatch({
      actionType: ProjectsConstants.DELETE_PROJECTS,
    });
    return ProjectsWebAPIUtils.deleteProjects(ids);
  },
  unsyncProject(id: number) {
    AppDispatcher.dispatch({
      actionType: ProjectsConstants.UNSYNC_PROJECT,
    });
    return ProjectsWebAPIUtils.unsyncProject(id);
  },
  updateProject(id: number, params: { title?: string; description?: string }) {
    AppDispatcher.dispatch({
      actionType: ProjectsConstants.REQUEST_UPDATE_PROJECT,
      id,
      params,
    });
    return ProjectsWebAPIUtils.updateProject(id, params);
  },
  loadTemplateTags() {
    return TemplatesWebAPIUtils.loadTemplateTags();
  },
  createProjectFromTemplate(id: number) {
    AppDispatcher.dispatch({
      actionType: ProjectsConstants.CREATE_PROJECT,
    });
    return ProjectsWebAPIUtils.createProjectFromTemplate(id);
  },
  /*
  loadImportList() {
    AppDispatcher.dispatch({
      actionType: ProjectsConstants.LOAD_IMPORT_LIST
    });
    return ProjectsWebAPIUtils.loadImportList();
  },
*/
  loadDropboxImportList() {
    return ProjectsWebAPIUtils.loadDropboxImportList();
  },

  startDropboxImport(title: string) {
    AppDispatcher.dispatch({
      actionType: ProjectsConstants.START_IMPORT,
    });
    return ProjectsWebAPIUtils.startDropboxImport(title)
      .then((value: any) => {
        AppDispatcher.dispatch({
          actionType: ProjectsConstants.OPEN_SNACKBAR,
          message: t('view:api.remote_storage.import_requested', {
            remote_name: 'Dropbox',
          }),
        });
        return value;
      })
      .catch((error: any) => {
        AppDispatcher.dispatch({
          actionType: ProjectsConstants.OPEN_SNACKBAR,
          message: t('view:api.remote_storage.error_fail_import_request', {
            remote_name: 'Dropbox',
          }),
        });
      });
  },

  cancelDropboxImport() {
    AppDispatcher.dispatch({
      actionType: ProjectsConstants.CANCEL_IMPORT,
    });
  },

  startPollingDropboxImportStatus(
    project_id: number,
    interval: number,
    cancellor_token: CL2Types.Runnable,
  ) {
    return ProjectsWebAPIUtils.startPollingDropboxImportStatus(
      project_id,
      interval,
      cancellor_token,
    );
  },

  cancelImport() {
    AppDispatcher.dispatch({
      actionType: ProjectsConstants.CANCEL_IMPORT,
    });
  },

  openSnackbar(message: string) {
    AppDispatcher.dispatch({
      actionType: ProjectsConstants.OPEN_SNACKBAR,
      message,
    });
  },

  closeSnackbar() {
    AppDispatcher.dispatch({
      actionType: ProjectsConstants.CLOSE_SNACKBAR,
    });
  },

  copyProject(project_id: number) {
    return ProjectsWebAPIUtils.copyProject(project_id);
  },

  setCompileTarget(project_id: number, file_id: number) {
    return ProjectsWebAPIUtils.setCompileTarget(project_id, file_id);
  },

  uploadZip(file: CL2Types.UploadFile) {
    AppDispatcher.dispatch({
      actionType: ProjectsConstants.UPLOAD_ZIP,
      file,
    });
  },

  clearZip() {
    AppDispatcher.dispatch({
      actionType: ProjectsConstants.CLEAR_ZIP,
    });
  },

  closeError() {
    AppDispatcher.dispatch({
      actionType: ProjectsConstants.CLOSE_ERROR,
    });
  },

  selectProject(id: number) {
    AppDispatcher.dispatch({
      actionType: ProjectsConstants.SELECT_PROJECT,
      id,
    });
  },

  deselectProject(id: number) {
    AppDispatcher.dispatch({
      actionType: ProjectsConstants.DESELECT_PROJECT,
      id,
    });
  },

  clearSelected() {
    AppDispatcher.dispatch({
      actionType: ProjectsConstants.CLEAR_SELECTED,
    });
  },
};

export default ProjectsActions;
