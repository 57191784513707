import * as React from 'react';
import { useSelector } from 'react-redux';
import { editorStore } from '../../../../store';
import { EditorProject } from '../../../../CL2Types';

export function RequireEdit({ children, ...rest }) {
  const permission = useSelector<
    ReturnType<typeof editorStore.getState>,
    EditorProject['permission']
  >((state) => state.editor.project?.permission);
  return permission?.edit
    ? React.Children.map(children, (child) => React.cloneElement(child, rest)) // MaterialUI Tab needs to be passed props
    : null;
}
