import * as React from 'react';
import {
  Box,
  Icon,
  IconButton,
  List,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  Paper,
  styled,
  Tooltip,
  Typography,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import * as Colors from '@material-ui/core/colors';
import { withStyles, alpha } from '@material-ui/core/styles';
import * as _ from 'lodash';
import { Dictionary } from 'lodash';
import { t } from '../../../i18n';
import FileDialog from './FileDialog';
import FileNameDialog from './FileNameDialog';
import LoadingDialog from '../../LoadingDialog';
import FileRestoreDialog from './FileRestoreDialog';
import { isTextFile, isImageFile, isPdfFile } from '../../../utils/CommonUtils';
import FileListItem from './FileListItem';
import EditorActions from '../../../actions/EditorActions';
import ContextMenu from '../../ContextMenu';
import BlankLoading from '../../BlankLoading';
import PreviewDialog from './PreviewDialog';
import EditorWebAPIUtils from '../../../utils/EditorWebAPIUtils';
import * as CL2Types from '../../../CL2Types';
import { PaneSplitterContext } from './PaneSplitter';
import { APIRoot } from '../../../constants/CommonConstants';
import { RequireEdit } from './permission/RequireEdit';
import { FileMoveDialog } from './FileMoveDialog';

interface Props {
  project: CL2Types.EditorProject;
  currentFile: CL2Types.CurrentFile;
  previewFile: CL2Types.PreviewFile;
  restoreDialog: CL2Types.RestoreDialog;
  waiting: boolean;
  loading: boolean;
  files: Dictionary<CL2Types.ProjectFile[]>;
  onBeforeOpenFile: () => void;
  moveDialog: CL2Types.MoveDialog;
  changeMoveDialog: (payload: Partial<CL2Types.MoveDialog>) => void;
}
interface State {
  selected: CL2Types.ProjectFile;
  fileDialog: {
    open: boolean;
    isFolder?: boolean;
  };
  renameDialog: {
    open: boolean;
  };
  deleteDialog: {
    open: boolean;
    submittable: boolean;
  };
  restoreDialog: {
    open: boolean;
  };
  addMenu: {
    anchorEl: HTMLElement;
  };
  folderContextMenuProps: {
    pos: { x: number; y: number };
    anchorEl: HTMLElement;
  };
  fileContextMenuProps: {
    pos: { x: number; y: number };
    anchorEl: HTMLElement;
  };
}

const ui_unit = '36px';

const IconButtonExInFileList = withStyles((theme) => ({
  root: {
    color: theme.palette.text.iconButton,
    width: ui_unit,
    height: '100%',
    padding: '8px',
    '&:hover': {
      background: 'none',
    },
  },
}))(IconButton);

const FileListItemIcon = withStyles((theme) => ({
  root: {
    color: theme.palette.text.icon,
    padding: '8px 0px 8px 12px',
    width: '16px',
  },
}))(ListItemIcon);

const FileListContainer = withStyles((theme) => ({
  root: {
    background: 'transparent',
    position: 'absolute',
    top: ui_unit,
    bottom: '0px',
    left: '0px',
    right: '0px',
    overflow: 'auto',
  },
}))(Paper);

const ToolbarDiv = styled('div')(({ theme }) => ({
  height: ui_unit,
  backgroundColor: theme.palette.background.toolbar,
  color: theme.palette.text.toolbar,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

const FolderList = withStyles({
  padding: {
    '.file-list-item-container:last-child > * > &': {
      paddingBottom: 'unset',
    },
  },
})(List);

class FileList extends React.Component<Props, State> {
  private newFileDialogRef = { dialog: null };

  private renameDialogRef = { dialog: null };

  private _addFilesMenuAction: () => void = null;

  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      fileDialog: {
        open: false,
        isFolder: false,
      },
      renameDialog: {
        open: false,
      },
      deleteDialog: {
        open: false,
        submittable: true,
      },
      restoreDialog: {
        open: false,
      },
      addMenu: {
        anchorEl: null,
      },
      folderContextMenuProps: {
        pos: null,
        anchorEl: null,
      },
      fileContextMenuProps: {
        pos: null,
        anchorEl: null,
      },
    };
  }

  componentDidMount() {
    const fileListElement: Element =
      document.getElementById('file_list_content');
    const $jQueryfileListElement: JQuery<Element> = $(fileListElement);
    $jQueryfileListElement.droppable({
      addClasses: false,
      greedy: true,
      tolerance: 'pointer',
      drop: (e, ui) => {
        this._handleMoveFile(ui.draggable.data('move'), null, ui.draggable);
      },
    });
    this._child(0);
  }

  _openFolderContextMenu(folder, pos?) {
    return (e) => {
      e.preventDefault();
      this.setState({ selected: folder });
      this.setState({
        folderContextMenuProps: {
          pos: pos || { x: e.pageX, y: e.pageY },
          anchorEl: e.currentTarget,
        },
      });
    };
  }

  _openFileContextMenu(file, pos?) {
    return (e) => {
      e.preventDefault();
      this.setState({ selected: file });
      this.setState({
        fileContextMenuProps: {
          pos: pos || { x: e.pageX, y: e.pageY },
          anchorEl: e.currentTarget,
        },
      });
    };
  }

  _clearSelected = () => {
    this.setState({ selected: null });
  };

  /*
     Rewrite to material design icon when document icons get available.
   */
  _renderFile(file, depth) {
    const listItemStyle_File: React.CSSProperties = {
      padding: `0px 0px 0px ${String(18 * depth)}px`,
    };
    listItemStyle_File.fontWeight = 'lighter';
    let icon = 'fa fa-file-text-o';

    if (
      this.props.project &&
      this.props.project.compile_target_file_id === file.id
    ) {
      listItemStyle_File.fontWeight = 'bolder';
      icon = 'fa fa-file-text';
    }
    if (this.props.currentFile && this.props.currentFile.id === file.id) {
      listItemStyle_File.backgroundColor = alpha(Colors.lightBlue['200'], 0.5);
    }
    if (isImageFile(file.mimetype)) {
      icon = 'fa fa-file-image-o';
    } else if (!isTextFile(file.mimetype)) {
      icon = 'fa fa-file-o';
    }
    return (
      <FileListItem
        component="li"
        key={file.id}
        style={listItemStyle_File}
        onContextMenu={
          this.props.project?.permission.edit
            ? this._openFileContextMenu(file)
            : null
        }
        onClick={this._handleOpenFile(file)}
        className="file-list-item"
        onDraggableStart={(e, ui) => {
          ui.helper.data('move', file.id);
        }}
        selected={this.state.selected?.id === file.id}
      >
        <FileListItemIcon>
          <span className={icon} />
        </FileListItemIcon>
        <ListItemText
          disableTypography
          title={file.name}
          style={{ overflow: 'hidden' }}
        >
          <span>{file.name}</span>
        </ListItemText>
        <RequireEdit>
          <IconButtonExInFileList
            children={
              <Icon
                className="file-list-menu-icon"
                style={{ fontWeight: 600, fontSize: '16px' }}
              >
                more_vert
              </Icon>
            }
            style={{ padding: '8px', width: '32px', height: '32px' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const tgt = $(e.nativeEvent.target);
              const ofs = tgt.offset();
              const pos = {
                x: ofs.left + tgt.outerWidth() / 2,
                y: ofs.top + tgt.outerHeight() / 2,
              };
              this._openFileContextMenu(file, pos)(e);
              return false;
            }}
          />
        </RequireEdit>
      </FileListItem>
    );
  }

  _renderFolder(folder, depth) {
    const listItemStyle_Folder = {
      padding: `0px 0px 0px ${String(18 * depth)}px`,
    };

    const listItemIconDefaultClass = 'fa';
    const listItemOpenedIconClass = `${listItemIconDefaultClass} fa-folder-open-o`;
    const listItemClosedIconClass = `${listItemIconDefaultClass} fa-folder-o`;

    const nestedItemOpenedStyle = {};
    const nestedItemClosedStyle = { display: 'none' };

    const nested_items = (this.props.files[folder.id] || []).map((file) =>
      file.is_folder
        ? this._renderFolder(file, depth + 1)
        : this._renderFile(file, depth + 1),
    );
    const folderClickHandler = (ev, isOpen) => {
      // Todo use setState()
      folder.is_open = isOpen;
      this.forceUpdate();

      // redux stateの書き換え無しでAPI投げるだけなので直接EditorWebAPIUtilsから呼ぶ
      EditorWebAPIUtils.updateFile(folder.id, {
        revision: folder.revision,
        is_open: isOpen,
      });
    };
    return (
      <FileListItem
        isFolder
        isOpen={!!folder.is_open}
        component="li"
        key={folder.id}
        style={listItemStyle_Folder}
        onContextMenu={
          this.props.project?.permission.edit
            ? this._openFolderContextMenu(folder)
            : null
        }
        onClick={folderClickHandler}
        className="file-list-item"
        ContainerProps={{ className: 'file-list-item-container' }}
        onDraggableStart={(e, ui) => {
          ui.helper.data('move', folder.id);
        }}
        onDroppableDrop={(e, ui) => {
          this._handleMoveFile(
            ui.draggable.data('move'),
            folder.id,
            ui.draggable,
          );
        }}
        selected={this.state.selected?.id === folder.id}
      >
        <FileListItemIcon>
          <span
            className={
              folder.is_open ? listItemOpenedIconClass : listItemClosedIconClass
            }
          />
        </FileListItemIcon>
        <ListItemText
          disableTypography
          title={folder.name}
          style={{ overflow: 'hidden' }}
        >
          <span>{folder.name}</span>
        </ListItemText>
        <RequireEdit>
          <IconButtonExInFileList
            children={
              <Icon
                className="file-list-menu-icon"
                style={{ fontWeight: 600, fontSize: '16px' }}
              >
                more_vert
              </Icon>
            }
            style={{ padding: '8px', width: '32px', height: '32px' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const tgt = $(e.nativeEvent.target);
              const ofs = tgt.offset();
              const pos = {
                x: ofs.left + tgt.outerWidth() / 2,
                y: ofs.top + tgt.outerHeight() / 2,
              };
              this._openFolderContextMenu(folder, pos)(e);
              return false;
            }}
          />
        </RequireEdit>
        <ListItemSecondaryAction
          style={{ position: 'static', transform: 'none' }}
          children={
            <FolderList
              style={
                folder.is_open ? nestedItemOpenedStyle : nestedItemClosedStyle
              }
            >
              {nested_items}
            </FolderList>
          }
        />
      </FileListItem>
    );
  }

  private _folderContextMenuOnItemSelectedHandler = (e, item) => {
    switch (item.key) {
      case 'new file':
        this.setState({ fileDialog: { open: true, isFolder: false } });
        break;
      case 'new folder':
        this.setState({ fileDialog: { open: true, isFolder: true } });
        break;
      case 'delete':
        this._openDeleteDialog();
        break;
      case 'rename':
        this._openRenameDialog();
        break;
      case 'move':
        this.openMoveDialog();
        break;
      case 'copy_name_to_clipboard':
        this._copy_string(this.state.selected.full_path);
        break;
    }
  };

  private _fileContextMenuOnItemSelectedHandler = (e, item) => {
    switch (item.key) {
      case 'download':
        this._handleDownloadFile();
        break;
      case 'delete':
        this._openDeleteDialog();
        break;
      case 'rename':
        this._openRenameDialog();
        break;
      case 'move':
        this.openMoveDialog();
        break;
      case 'copy_name_to_clipboard':
        this._copy_string(this.state.selected.full_path);
        break;
      case 'target':
        EditorActions.setCompileTarget(this.state.selected.id).then(() =>
          EditorActions.loadProjectInfo(),
        );
        this.setState({ selected: null });
        break;
      case 'restore':
        this._openRestoreDialog();
        break;
    }
  };

  _openDeleteDialog() {
    this.setState({ deleteDialog: { open: true, submittable: true } });
  }

  _openRenameDialog() {
    this.setState({ renameDialog: { open: true } });
  }

  _openRestoreDialog() {
    EditorActions.loadFileVersions(this.state.selected.id);
    this.setState({ restoreDialog: { open: true } });
  }

  _copy_string(str) {
    const copyWrapper = document.createElement('div');
    copyWrapper.appendChild(document.createElement('pre')).textContent = str;

    const s = copyWrapper.style;
    s.position = 'fixed';
    s.left = '-100%';

    document.body.appendChild(copyWrapper);
    document.getSelection().selectAllChildren(copyWrapper);

    document.execCommand('copy');

    document.body.removeChild(copyWrapper);
    this.setState({ selected: null });
  }

  render() {
    const fileListRootStyle: React.CSSProperties = {
      height: '100%',
      position: 'relative',
    };
    const addFilesMenuItemStyle = { fontSize: '16px', fontWeight: 300 };
    const folderMenuItems = [
      { primaryText: t('view:editor.new_file'), key: 'new file' },
      { primaryText: t('view:editor.new_folder'), key: 'new folder' },
      { primaryText: t('view:editor.rename'), key: 'rename' },
      { primaryText: t('view:editor.move'), key: 'move' },
      {
        primaryText: t('view:editor.copy_name_to_clipboard'),
        key: 'copy_name_to_clipboard',
      },
      { primaryText: t('view:editor.delete'), key: 'delete', color: 'red' },
    ];

    const targetDisabled = this.state.selected
      ? !(
          /\.(tex|ltx|Rtex|md)$/i.test(this.state.selected.name) &&
          this.state.selected.belonging_to == null
        )
      : true;

    const deleteDisabled =
      this.state.selected &&
      this.state.selected.id === this.props.project.compile_target_file_id;

    const textDelete = deleteDisabled
      ? t('view:editor.delete_disabled')
      : t('view:editor.delete');

    const fileMenuItems = [
      {
        primaryText: t('view:editor.set_target'),
        key: 'target',
        disabled: targetDisabled,
      },
      { primaryText: t('view:editor.rename'), key: 'rename' },
      { primaryText: t('view:editor.move'), key: 'move' },
      {
        primaryText: t('view:editor.copy_name_to_clipboard'),
        key: 'copy_name_to_clipboard',
      },
      { primaryText: t('view:editor.download'), key: 'download' },
      { primaryText: t('view:editor.restore'), key: 'restore', color: 'blue' },
      {
        primaryText: textDelete,
        key: 'delete',
        disabled: deleteDisabled,
        color: 'red',
      },
    ];
    {
      const f = function (item) {
        if (!item.style) {
          item.style = {};
        }
        _.defaults(item.style, {
          fontSize: '15px',
          fontWeight: 300,
          padding: '0px 24px',
          minHeight: '32px',
        });
      };
      folderMenuItems.forEach(f);
      fileMenuItems.forEach(f);
    }
    const selectedName = this.state.selected ? this.state.selected.name : '';
    return (
      <div style={fileListRootStyle} id="file_list_root">
        {this.props.loading ? <BlankLoading /> : null}
        <FileDialog
          dialogRef={this.newFileDialogRef}
          open={this.state.fileDialog.open}
          isFolder={this.state.fileDialog.isFolder}
          onClose={this._handleFileDialogClose}
          belonging={
            this.state.selected && this.state.selected.is_folder
              ? this.state.selected.id
              : null
          }
          waiting={this.props.waiting}
          key="file_dialog"
        />
        <LoadingDialog
          open={this.state.deleteDialog.open}
          onClose={this._onDismissDeleteDialog}
          onRequestExec={this._handleDeleteFile}
          waiting={this.props.waiting}
          execLabel={t('view:editor.delete')}
          title={t('view:editor.delete_file')}
          disabled={!this.state.deleteDialog.submittable}
          PaperProps={{ style: { width: '75%', maxWidth: '576px' } }}
        >
          <Box
            component="span"
            color="text.secondary"
            style={{ fontSize: '16px', fontFamily: 'Roboto, sans-serif' }}
          >
            {t('view:editor.delete_confirm')}
            <br />
            <b>{t('view:editor.delete_confirm_bold')}</b>
            <br />
            <br />
            <ul>
              <li>{selectedName}</li>
            </ul>
          </Box>
        </LoadingDialog>
        <FileNameDialog
          dialogRef={this.renameDialogRef}
          open={this.state.renameDialog.open}
          nameLabel={t('view:editor.file_title')}
          waiting={this.props.waiting}
          onRequestExec={this._handleRenameFile}
          onClose={this._onDismissRenameDialog}
          execLabel={t('view:editor.rename')}
          title={t('view:editor.rename_file')}
          isFolder={this.state.selected && this.state.selected.is_folder}
          defaultValue={this.state.selected && this.state.selected.name}
        />
        <FileRestoreDialog
          open={this.state.restoreDialog.open}
          versions={this.props.restoreDialog.versions}
          currentVersion={this.props.restoreDialog.current_version}
          fileName={this.state.selected && this.state.selected.name}
          waiting={this.props.waiting}
          loading={this.props.restoreDialog.loading}
          restoring={this.props.restoreDialog.restoring}
          onRequestExec={this._handleRestoreFile}
          onDownloadExec={this._handleDownloadFile}
          onClose={this._onDismissRestoreDialog}
        />
        <FileMoveDialog
          {...this.props.moveDialog}
          selected={this.state.selected}
          files={this.props.files}
          changeMoveDialog={this.props.changeMoveDialog}
          handleMoveFile={this._handleMoveFile}
          waiting={this.props.waiting}
        />
        <div>
          <ContextMenu
            {...this.state.folderContextMenuProps}
            menuItems={folderMenuItems}
            onItemSelected={this._folderContextMenuOnItemSelectedHandler}
            onClose={(ev, reason, callback) => {
              this.setState({
                folderContextMenuProps: {
                  pos: null,
                  anchorEl: null,
                },
              });
              callback && callback();
            }}
            onCancel={this._clearSelected}
          />
          <ContextMenu
            {...this.state.fileContextMenuProps}
            menuItems={fileMenuItems}
            onItemSelected={this._fileContextMenuOnItemSelectedHandler}
            onClose={(ev, reason, callback) => {
              this.setState({
                fileContextMenuProps: {
                  pos: null,
                  anchorEl: null,
                },
              });
              callback && callback();
            }}
            onCancel={this._clearSelected}
          />
        </div>
        <ToolbarDiv id="file_list_toolbar">
          <span
            style={{
              display: 'inline-block',
              height: '100%',
              verticalAlign: 'middle',
            }}
          />
          <span
            className="button-wrapper"
            style={{ display: 'inline-block', height: '100%' }}
          >
            <RequireEdit>
              <Tooltip
                title={
                  <span style={{ fontSize: '14px' }}>
                    {t('view:editor.add_files')}
                  </span>
                }
                placement="bottom-end"
              >
                <IconButtonExInFileList
                  children={
                    <Icon style={{ fontSize: '20px', height: '18px' }}>
                      add
                    </Icon>
                  }
                  disableRipple
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({ addMenu: { anchorEl: e.currentTarget } });
                    return false;
                  }}
                  id="add_files_menu_button_icon"
                />
              </Tooltip>
            </RequireEdit>
            <Menu
              id="add_files_menu"
              anchorEl={this.state.addMenu.anchorEl}
              open={Boolean(this.state.addMenu.anchorEl)}
              onClose={() => this.setState({ addMenu: { anchorEl: null } })}
              TransitionProps={{
                onExited: () => {
                  const tmp = this._addFilesMenuAction;
                  if (tmp) {
                    delete this._addFilesMenuAction;
                    tmp();
                  }
                },
              }}
              disableAutoFocusItem
            >
              <MenuItem
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ selected: null });
                  this._addFilesMenuAction = () => this._handleNewFile(false);
                  this.setState({ addMenu: { anchorEl: null } });
                }}
              >
                <span style={addFilesMenuItemStyle}>
                  {t('view:editor.new_file')}
                </span>
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ selected: null });
                  this._addFilesMenuAction = () => this._handleNewFile(true);
                  this.setState({ addMenu: { anchorEl: null } });
                }}
              >
                <span style={addFilesMenuItemStyle}>
                  {t('view:editor.new_folder')}
                </span>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  this._addFilesMenuAction = this._handleUpload;
                  this.setState({ addMenu: { anchorEl: null } });
                }}
              >
                <span style={addFilesMenuItemStyle}>
                  {t('view:editor.upload')}
                </span>
              </MenuItem>
            </Menu>
            <span
              style={{
                backgroundColor: 'rgba(0,0,0, .175)',
                width: 1,
                display: 'inline-block',
                marginRight: '10px',
                height: '100%',
                verticalAlign: 'middle',
              }}
            />
          </span>
          <Typography
            style={{
              lineHeight: ui_unit,
              fontSize: '18px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: 300,
              display: 'inline-block',
              verticalAlign: 'middle',
            }}
            id="project_title"
            component="span"
            title={this.props.project && this.props.project.title}
          >
            {this.props.project && this.props.project.title}
          </Typography>
          <PaneSplitterContext.Consumer>
            {(value) => value.rightButton}
          </PaneSplitterContext.Consumer>
        </ToolbarDiv>
        <FileListContainer id="file_list_container" elevation={0}>
          {this.props.waiting && !this.props.loading ? <BlankLoading /> : null}
          <List
            id="file_list_content"
            style={{ fontFamily: 'Roboto, sans-serif', fontSize: '16px' }}
          >
            {this.props.files[0].map((file) =>
              file.is_folder
                ? this._renderFolder(file, 0)
                : this._renderFile(file, 0),
            )}
          </List>
        </FileListContainer>
        <PreviewDialog file={this.props.previewFile} />
      </div>
    );
  }

  private _childOpen(arr_index1: number, arr_index2: number) {
    try {
      let index: number;
      if (arr_index1 !== null && arr_index2 !== null) {
        // texファイルがルートではない場合
        index = this.props.files[arr_index1][arr_index2].id;
      } else {
        // ルートの場合
        index = 0;
      }
      for (let i = 0; this.props.files[index].length > 0; i += 1) {
        if (
          this.props.files[index][i].is_new === true &&
          this.props.files[index][i].is_folder === false &&
          isTextFile(this.props.files[index][i].mimetype) === true
        ) {
          // is_newがtrueのtexファイルを探す
          this.props.onBeforeOpenFile();
          EditorActions.updateFile(this.props.files[index][i].id, {
            is_new: false,
          }); // is_newをfalseに
          EditorActions.openFile(this.props.files[index][i].id); // ファイルを開く
          if (index !== 0) {
            // もしルートのファイルでなければ親フォルダを開く
            this.props.files[arr_index1][arr_index2].is_open = true;
            EditorActions.updateFile(this.props.files[index][i].belonging_to, {
              is_open: true,
            });
          }
          return;
        }
        if (this.props.files[index][i].is_folder === true) {
          // もしフォルダが存在したら,フォルダの中にis_newがtrueのtexファイルがないかを探す．
          this._childOpen(index, i);
        }
      }
    } catch (e) {}
  }

  private _child(index: number) {
    try {
      for (let i = 0; this.props.files[index].length > 0; i += 1) {
        EditorActions.updateFile(this.props.files[index][i].id, {
          is_new: false,
        });
        if (this.props.files[index][i].is_folder === true) {
          this._child(this.props.files[index][i].id);
        }
      }
    } catch (e) {}
  }

  _handleUpload() {
    EditorActions.startUploadSession();
  }

  _handleNewFile(is_folder) {
    this.setState({
      selected: null,
      fileDialog: { open: true, isFolder: is_folder },
    });
  }

  _handleOpenFile(file) {
    return (e) => {
      if (e.nativeEvent.button === 2) return;
      if (isTextFile(file.mimetype)) this._openTextFile(file);
      else if (isImageFile(file.mimetype) || isPdfFile(file.mimetype))
        this._openBinaryFile(file);
    };
  }

  _openTextFile(file) {
    const prev = this.props.currentFile;
    if (prev && prev.id === file.id) return;
    this.props.onBeforeOpenFile();
    EditorActions.openFile(file.id);
  }

  _openBinaryFile(file) {
    EditorActions.openBinaryFile(file.id);
  }

  private _handleDeleteFile = () => {
    this.setState({
      deleteDialog: { ...this.state.deleteDialog, submittable: false },
    });
    EditorActions.deleteFile(this.state.selected.id).then(() =>
      EditorActions.loadFiles().then(() => {
        this.setState({ deleteDialog: { open: false, submittable: true } });
        if (
          this.state.selected.id === this.props.project.compile_target_file_id
        ) {
          return EditorActions.loadProjectInfo();
        }
      }),
    );
  };

  _handleMoveFile = (from: number, to: number, draggable?) => {
    const fromFile = Object.values(this.props.files)
      .flat()
      .find((file) => file.id === from);
    if (
      this.props.project.compile_target_file_id === from ||
      fromFile?.belonging_to === to ||
      this.props.waiting
    ) {
      draggable?.animate({ top: 0 });
      return;
    }
    return EditorActions.updateFile(from, { belonging_to: to }).then(
      () => {
        EditorActions.loadFiles();
      },
      () => {
        draggable?.animate({ top: 0 });
      },
    );
  };

  private _handleRenameFile = () => {
    EditorActions.updateFile(this.state.selected.id, {
      name: this.renameDialogRef.dialog.getFileName(),
    }).then(
      () =>
        EditorActions.loadFiles().then(() => {
          this.setState({ selected: null, renameDialog: { open: false } });
        }),
      () => {
        this.setState({ selected: null, renameDialog: { open: false } });
      },
    );
  };

  private _handleRestoreFile = (version_id) => {
    const file_id = this.state.selected.id;
    EditorActions.restoreFile(file_id, version_id).then(
      (res) => {
        if (this.props.currentFile && file_id === this.props.currentFile.id) {
          this.props.onBeforeOpenFile();
          EditorActions.openFile(file_id);
        }
        return EditorActions.loadFiles().then(() => {
          this.setState({ selected: null, restoreDialog: { open: false } });
        });
      },
      () => {
        this.setState({ selected: null, restoreDialog: { open: false } });
      },
    );
  };

  private _handleDownloadFile = (version_id = null) => {
    const file_id = this.state.selected.id;
    let path = `${APIRoot + window.location.pathname.replace('/edit', '')}/files/${file_id}/download/`;

    if (version_id) {
      path += version_id;
    }

    window.location.assign(path);

    this.setState({ selected: null });
  };

  private _handleFileDialogClose = (buttonClicked) => {
    if (!buttonClicked) {
      let { dialog } = this.newFileDialogRef;
      if (!dialog) {
        return;
      }
      dialog = dialog.newFileDialogRef.dialog;
      if (!dialog) {
        return;
      }
      if (dialog.getFileName() !== '') {
        return;
      }
    }
    this.setState({ selected: null, fileDialog: { open: false } });
  };

  private _onDismissDeleteDialog = (buttonClicked) => {
    const state = this.state.deleteDialog;
    state.open = false;
    this.setState({ selected: null, deleteDialog: state });
  };

  private _onDismissRenameDialog = (buttonClicked) => {
    if (
      buttonClicked ||
      (this.renameDialogRef.dialog &&
        this.renameDialogRef.dialog.getFileName()) ===
        (this.state.selected && this.state.selected.name)
    ) {
      this.setState({ selected: null, renameDialog: { open: false } });
    }
  };

  private _onDismissRestoreDialog = () => {
    this.setState({ selected: null, restoreDialog: { open: false } });
  };

  private openMoveDialog = () => {
    this.props.changeMoveDialog({
      open: true,
      current: Object.values(this.props.files)
        .flat()
        .find((file) => file.id === this.state.selected.belonging_to),
    });
  };
}

export default FileList;
