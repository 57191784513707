import AppDispatcher from '../dispatcher/AppDispatcher';
import TopTypes from '../TopTypes';

const TopActions = {
  openSignInDialog() {
    AppDispatcher.dispatch({
      actionType: TopTypes.OPEN_SIGNIN_DIALOG,
    });
  },
  openSignUpDialog() {
    AppDispatcher.dispatch({
      actionType: TopTypes.OPEN_SIGNUP_DIALOG,
    });
  },
  closeSignInDialog() {
    AppDispatcher.dispatch({
      actionType: TopTypes.CLOSE_SIGNIN_DIALOG,
    });
  },
  closeSignUpDialog() {
    AppDispatcher.dispatch({
      actionType: TopTypes.CLOSE_SIGNUP_DIALOG,
    });
  },
};

export default TopActions;
